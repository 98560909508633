var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.mask && _vm.visible
      ? _c("div", {
          staticClass: "mask-layer",
          on: { click: _vm.handleMaskClick },
        })
      : _vm._e(),
    _vm.visible
      ? _c(
          "div",
          {
            directives: [
              {
                name: "dialogDrag",
                rawName: "v-dialogDrag:[dialogDrag]",
                arg: _vm.dialogDrag,
              },
            ],
            staticClass: "customer-dialog",
            style: _vm.dialogStyles,
            attrs: { id: "customer-dialog" },
          },
          [
            _c("div", { staticClass: "dialog-container" }, [
              _c(
                "div",
                {
                  staticClass: "dialog-header",
                  style: {
                    "--dialog-center": `${_vm.center ? "center" : "left"}`,
                  },
                  attrs: { id: "dialog-header" },
                },
                [
                  !_vm.headerHide
                    ? _vm._t("header", function () {
                        return [
                          _c("span", { staticClass: "dialog__title" }, [
                            _vm._v(_vm._s(_vm.title)),
                          ]),
                        ]
                      })
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "dialog__header-close",
                      on: { click: _vm.handleClose },
                    },
                    [
                      _c("i", {
                        staticClass: "el-dialog__close el-icon el-icon-close",
                      }),
                    ]
                  ),
                ],
                2
              ),
              _c("div", { staticClass: "dialog-body" }, [_vm._t("default")], 2),
              !_vm.footerHide
                ? _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      style: {
                        "--dialog-center": `${_vm.center ? "center" : "right"}`,
                      },
                    },
                    [
                      _vm._t("footer", function () {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.handleClose },
                            },
                            [_vm._v(_vm._s(_vm.cancelText))]
                          ),
                          !_vm.submitHide
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    show: "",
                                    loading: _vm.loading,
                                    size: "small",
                                  },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v(_vm._s(_vm.saveText))]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }