/*
 * @Description:元数据
 * @Author: Ran junlin
 * @Date: 2024-08-12 14:28:52
 * @LastEditTime: 2024-08-14 11:14:09
 * @LastEditors: Ran junlin
 */
import http from '@/http';

// 可用元数据
export const metadataNormal = async data => {
  const url = http.buildServiceUrl('LOAN', `metadata/normal`);
  const res = await http.post(url, data);
  return res?.data || [];
};

// 分页
export function getList(data) {
  const url = http.buildServiceUrl('LOAN', `metadata/all`);
  return http.post(url, data).then(res => (res.data ? { records: res.data } : []));
}
// 删除
export function del({ id }) {
  const url = http.buildServiceUrl('LOAN', `metadata/${id}`);
  return http.delete(url);
}

// 新增或修改
export function save({ id, ...data }) {
  const url = http.buildServiceUrl('LOAN', `metadata/${id}`);
  const url2 = http.buildServiceUrl('LOAN', `metadata`);
  if (id) {
    return http.put(url, data);
  }
  return http.post(url2, data);
}
