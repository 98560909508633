var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col" }, [
    _vm.isShowTittle
      ? _c("div", { staticClass: "flex flex-center justify-start gap-8" }, [
          _c("div", { staticClass: "left-icon" }),
          _c("span", { staticClass: "title-name" }, [
            _vm._v(_vm._s(_vm.titleName)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }