/*
 * @Author: lqb
 * @Date: 2022-12-15 17:02:05
 * @LastEditors: g05047
 * @LastEditTime: 2022-12-27 14:33:42
 * @Description: 公司管理相关
 */

import { httpRequest } from '@/http/httpOss';

// 地区树
export const getRegionRegion = async () => {
  const url = 'https://affairs-prod-1305504517.cos.ap-chengdu.myqcloud.com/doc/config/admin/city.json';
  return await httpRequest(url);
};
