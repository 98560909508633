<template>
  <el-dialog
    v-elDraggableDialog
    ref="dialogRef"
    :visible="visible"
    :title="titleName"
    v-bind="$attrs"
    v-on="$listeners"
    @close="close"
  >
    <slot></slot>
    <template v-if="hasFooter" #footer>
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="handlerSubmit" size="small">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    titleName: {
      type: String,
      default: ''
    }
  },
  methods: {
    close() {
      this.$emit('handlerClose');
      this.$emit('update:visible', false);
    },
    handlerSubmit() {
      this.$emit('handlerSubmit');
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 3px 20px 20px;
}
</style>
