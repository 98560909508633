export default {
  path: '/auditHall',
  name: 'AuditHall',
  meta: {
    // a54527a0-83c4-49a5-8323-3d468a6d6d13  f63113c7-8539-4423-b8a5-b19e9b2ff965
    role: 'a54527a0-83c4-49a5-8323-3d468a6d6d13',
    title: '任务大厅',
    icon: 'icon el-icon-tickets',
    hidden: false,
    tag: true,
  },
  component: () => import(/* webpackChunkName:"Customer" */ '@/layout/Main'),
  children: [
    {
      name: 'PreTrial',
      path: 'preTrial',
      meta: {
        // 4d06d812-3aee-423c-80d7-7f7528b951c4 aaaec5c5-0f62-4ab8-9aea-4c8956e70bf6
        role: '4d06d812-3aee-423c-80d7-7f7528b951c4',
        icon: 'icon',
        title: '预审大厅',
        hidden: false,
        tag: true,
      },
      component: () => import('@/views/auditHall/preTrial/index'),
    },
    {
      name: 'WaitingList',
      path: 'waitingList',
      meta: {
        // df8b2967-adb0-47da-b853-c02a770a1024  aaaec5c5-0f62-4ab8-9aea-4c8956e70bf6
        role: 'df8b2967-adb0-47da-b853-c02a770a1024',
        icon: 'icon',
        title: '录单大厅',
        hidden: false,
        tag: true,
      },
      component: () => import('@/views/auditHall/waitingList/index'),
    },
    {
      name: 'LetterIssued',
      path: 'letterIssued',
      meta: {
        // 837a6d98-b319-49de-a1de-f0c17dd78d91 temp1-1
        role: '837a6d98-b319-49de-a1de-f0c17dd78d91',
        icon: 'icon',
        title: '授信大厅',
        hidden: false,
        tag: true,
      },
      component: () => import('@/views/auditHall/letterIssued/index'),
    },
    {
      name: 'LetterSigned',
      path: 'letterSigned',
      meta: {
        // c8cf2669-8e10-4dfe-911e-2f4fb4c672c5  temp1-1
        role: 'c8cf2669-8e10-4dfe-911e-2f4fb4c672c5',
        icon: 'icon',
        title: '签约大厅',
        hidden: false,
        tag: true,
      },
      component: () => import('@/views/auditHall/letterSigned/index'),
    },
    {
      name: 'LetterMoney',
      path: 'letterMoney',
      meta: {
        // 54fa8562-e023-467f-b5e4-a306adaac689  temp1-1
        role: '54fa8562-e023-467f-b5e4-a306adaac689',
        icon: 'icon',
        title: '请款大厅',
        hidden: false,
        tag: true,
      },
      component: () => import('@/views/auditHall/letterMoney/index'),
    },
    {
      name: 'LetterLoan',
      path: 'letterLoan',
      meta: {
        //7b1ca166-cef0-40bd-b8c0-519bab433626  temp1-1
        role: '7b1ca166-cef0-40bd-b8c0-519bab433626',
        icon: 'icon',
        title: '放款大厅',
        hidden: false,
        tag: true,
      },
      component: () => import('@/views/auditHall/letterLoan/index'),
    },
		{
      path: 'incoming/detail',
      name: 'OrderIncomingDetail',
      meta: {
        role: '0e14a900-9336-422e-949f-4b9e1b431717',
        icon: 'icon',
        title: '进件订单详情',
        leftMenu: false,
        hidden: true,
        tag: true,
      },
      component: () => import(/* webpackChunkName:"OrderIncomingDetail" */ '@/views/order/incoming/detail/index'),
    },
		// {
    //   path: 'waitingList/inputForm',
    //   name: 'InputForm',
    //   meta: {
    //     role: '0cbdd1cc-f411-40bd-a1ec-ac578ddb98ce',
    //     icon: 'icon',
    //     title: '录单',
    //     leftMenu: false,
    //     hidden: true,
    //     tag: true,
    //   },
    //   component: () => import(/* webpackChunkName:"OrderIncomingDetail" */ '@/views/auditHall/waitingList/formInput'),
    // },
  ],
};
