<template>
  <el-dialog v-elDraggableDialog :title="title" width="700px" :visible.sync="visible" append-to-body>
    <el-form
      ref="dynamicForm"
      :model="formData"
      :rules="formRules"
      label-width="auto"
      class="dynamic-form"
      v-bind="$attrs"
      v-on="$listeners"
      v-loading="formLoading"
      @submit.native.prevent
      @submit="handleSubmit"
    >
      <el-row :gutter="20">
        <el-col :span="field.promptType === 'FILE_UPLOAD' ? 24 : span" v-for="(field, index) in fields" :key="index">
          <el-form-item :label="field.fieldLabel" :prop="field.fieldName" v-if="field.webShowStatus === 'SHOW'">
            <template v-if="field.promptType === 'INPUT' || field.promptType === 'TEXTAREA'">
              <el-input
                v-model="formData[field.fieldName]"
                :disabled="field.editStatus === 'UNABLE'"
                :maxLength="field.maxLength"
                :placeholder="'请输入' + field.fieldLabel"
                clearable
              />
            </template>

            <template v-if="field.promptType === 'DIGIT'">
              <el-input
                style="width: 100%"
                v-model="formData[field.fieldName]"
                :disabled="field.editStatus === 'UNABLE'"
                :placeholder="'请输入' + field.fieldLabel"
                clearable
                :min="1"
                :class="field.extra && field.extra.unit ? getExtraUnit(field.extra.unit) : ''"
                :maxLength="field.maxLength"
                v-amount="{ integerLength: field.maxLength, decimalLength: 0 }"
              />
            </template>

            <template v-if="field.promptType === 'CAR_SELECT'">
              <!-- <el-button @click="handleSelectCar(field)" type="text">请选择 </el-button>
              <span class="line_1" style="font-size: 12px">{{ vehicleModel }}</span> -->
              <el-select
                class="selectClass"
                :popper-append-to-body="false"
                @click.native.prevent="handleSelectCar(field)"
                v-model="vehicleModel"
                placeholder="请选择"
                :filterable="false"
                @clear="handleCarClear(field)"
                clearable
              ></el-select>
            </template>

            <template v-if="field.promptType === 'REGION_SELECT'">
              <el-cascader
                class="selectClass"
                popper-append-to-body
                v-model="formData[field.fieldName]"
                :placeholder="'请输入' + field.fieldLabel"
                :disabled="field.editStatus === 'UNABLE'"
                :options="regionTree"
                :props="{
                  label: 'regionName',
                  value: 'regionCode',
                  children: 'childList',
                  emitPath: true
                }"
                clearable
                filterable
              />
            </template>

            <!-- 二级 -->
            <template v-if="field.promptType === 'CITY_SELECT'">
              <el-cascader
                class="selectClass"
                popper-append-to-body
                v-model="formData[field.fieldName]"
                :placeholder="'请输入' + field.fieldLabel"
                :options="regionTreeArea"
                :disabled="field.editStatus === 'UNABLE'"
                :props="{
                  label: 'regionName',
                  value: 'regionCode',
                  children: 'childList',
                  emitPath: true
                }"
                clearable
                filterable
              />
            </template>

            <!-- 三级 -->
            <template v-if="field.promptType === 'CITY_CASCADER'">
              <el-cascader
                class="selectClass"
                popper-append-to-body
                v-model="formData[field.fieldName]"
                :placeholder="'请输入' + field.fieldLabel"
                :options="regionTree"
                :disabled="field.editStatus === 'UNABLE'"
                :props="{
                  label: 'regionName',
                  value: 'regionCode',
                  children: 'childList',
                  emitPath: true
                }"
                clearable
                filterable
              />
            </template>

            <template v-if="field.promptType === 'SELECT'">
              <el-select
                class="selectClass"
                :popper-append-to-body="isPopperAppendToBody"
                v-model="formData[field.fieldName]"
                placeholder="请选择"
                clearable
                filterable
                :disabled="field.editStatus === 'UNABLE'"
              >
                <el-option
                  v-for="{ label, value } in getOptionsByEnumCode(field.enumCode, field.metadataCode)"
                  :key="value"
                  :label="label"
                  :value="value"
                />
              </el-select>
            </template>

            <template v-if="field.promptType === 'DATE'">
              <el-date-picker
                class="selectClass"
                popper-append-to-body
                v-model="formData[field.fieldName]"
                :disabled="field.editStatus === 'UNABLE'"
                type="date"
                placeholder="选择日期"
                style="width: 100%"
                clearable
                value-format="yyyy-MM-dd"
              />
            </template>

            <template v-if="field.promptType === 'FILE_UPLOAD'">
              <Upload
                :number="99"
                :width="120"
                :height="120"
                fileType="ALL"
                :disable="false"
                :enableCropper="false"
                :multiple="true"
                :initUrl="formData[field.fieldName]"
                uploadTips="添加"
                @imgupload="handleImgs($event, field)"
              />
            </template>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="handleSubmit">提交</el-button>
    </div>

    <VehicleManage v-if="vehicleManageShow" v-model="vehicleManageShow" @selected="handleSelectedVehicle" />
  </el-dialog>
</template>

<script>
import { getFileType } from '@/utils/variable';
import { mapState } from 'vuex';
import { isEmail, isIdcard, isMobile, isSocialCreditCode } from '@/utils/validator';
import { UNITENUMS } from '@/config/allEnum';
// 创建校验映射表
const validationMap = {
  spouseIdNumber: {
    validator: isIdcard,
    message: '请输入有效的身份证号码'
  },
  spouseMobile: {
    validator: isMobile,
    message: '请输入有效的手机号码'
  },
  contactsPhone: {
    validator: isMobile,
    message: '请输入有效的手机号码'
  },
  mobile: {
    validator: isMobile,
    message: '请输入有效的手机号码'
  },
  unifiedSocialCreditCode: {
    validator: isSocialCreditCode,
    message: '请输入有效的社会信用代码'
  },
  email: {
    validator: isEmail,
    message: '请输入有效的邮箱地址'
  }
  // 你可以根据需要添加更多的字段和对应的校验规则
};
export default {
  components: {
    Upload: () => import('@/components/Upload'),
    VehicleManage: () => import('@/components/VehicleManage')
  },
  name: 'DynamicForm',
  props: {
    /**
     * 是否需要弹窗
     */
    isShowDialog: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    },
    /**
     * 一行显示多少个
     */
    span: {
      type: Number,
      default: 8
    },
    showSaveBtn: {
      type: Boolean,
      default: true
    },
    fields: {
      type: Array,
      required: true // 必须传入字段配置
    },
    initialFormData: {
      type: Object,
      default: () => ({}) // 初始化表单数据
    },
    rules: {
      type: Object,
      default: () => ({}) // 传入自定义的校验规则
    },
    formLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '修改'
    },
    // 下拉框是否插入到body中
    isPopperAppendToBody: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentFiled: '',
      vehicleModel: '',
      vehicleManageShow: false,
      formData: {}, // 动态表单数据
      formRules: {}, // 动态校验规则
      fileList: [] // 文件上传列表
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.initializeForm();
      }
    }
  },

  computed: {
    ...mapState(['AllEnums', 'regionTree', 'MetaDataAllEnums']),
    regionTreeArea() {
      let list = this.$deepClone(this.regionTree);
      list.forEach(item => {
        item.childList.forEach(item1 => {
          delete item1.childList;
        });
      });
      return list;
    },
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    // 获取 unit
    getExtraUnit(unit) {
      if (!unit) return '';
      return UNITENUMS[unit] ? UNITENUMS[unit] : '';
    },
    handleCarClear(field) {
      console.log(field);
      this.formData[field.fieldName] = null;
      console.log(this.formData[field.fieldName], 'this.formData[field.fieldName]');
    },

    handleSelectCar({ fieldName }) {
      this.currentFiled = fieldName;

      this.vehicleManageShow = true;
    },
    handleSelectedVehicle(data) {
      console.log(data, 'current info');
      const { modelFullName, modelName, brandName, brandId, seriesId, modelId, seriesName } = data;
      this.vehicleModel = modelFullName + '' + modelName;
      this.formData[this.currentFiled] = [];
      this.formData[this.currentFiled].push(brandId, seriesId, modelId);
      // brandName，brandCode，modelCode，modelName，seriesCode，seriesName
      this.$set(this.formData, 'brandName', brandName);
      this.$set(this.formData, 'brandCode', brandId);
      this.$set(this.formData, 'modelName', modelName);
      this.$set(this.formData, 'modelCode', modelId);
      this.$set(this.formData, 'seriesName', seriesName);
      this.$set(this.formData, 'seriesCode', seriesId);
      console.log(this.formData[this.currentFiled], 'this.formData[this.currentFiled]');
    },
    handleImgs(updatedList, field) {
      console.log(field);

      if (!updatedList.length) {
        this.formData[field.fieldName] = [];
        return;
      }
      // 更新对应分组的数据
      console.log(updatedList);
      this.formData[field.fieldName] = updatedList.map(item => {
        const { name, fileName, url, fileType } = item;
        return {
          name,
          fileDesc: name || fileName,
          fileName: name || fileName,
          fileUrl: url,
          fileType: fileType.includes('.') ? getFileType(fileType) : fileType ? fileType : ''
        };
      });
    },
    // 初始化表单结构和数据
    initializeForm() {
      this.formData = { ...this.initialFormData }; // 初始化formData
      this.vehicleModel = this.formData?.brandFullName || '';

      this.fields.forEach(field => {
        // 初始化为空
        if (!this.formData[field.fieldName]) {
          this.$set(this.formData, field.fieldName, '');
        }

        // 根据字段的必填状态设置校验规则
        if (field.webRequiredStatus === 'REQUIRED') {
          // 添加必填校验规则
          this.$set(this.formRules, field.fieldName, [
            { required: true, message: `请输入${field.fieldLabel}`, trigger: 'blur' },
            ...(this.rules[field.fieldName] || []) // 添加其他现有规则
          ]);
        } else if (validationMap[field.fieldName]) {
          // 仅添加自定义验证器
          this.$set(this.formRules, field.fieldName, [
            ...(this.formRules[field.fieldName] || []), // 保留已有的规则
            {
              validator: (rule, value, callback) => {
                // 只有当有值时才进行验证
                if (value !== '') {
                  const isValid = validationMap[field.fieldName].validator(value);
                  if (!isValid) {
                    callback(new Error(validationMap[field.fieldName].message));
                  } else {
                    callback();
                  }
                } else {
                  callback(); // 如果没有值，则不校验
                }
              },
              trigger: 'blur'
            }
          ]);
        }
      });
    },

    // 获取枚举选项
    getOptionsByEnumCode(enumCode, metadataCode) {
      let list = [];

      // 处理枚举数据
      if (enumCode) {
        const enumData = this.AllEnums[enumCode] || {};
        list = Object.keys(enumData).map(enumKey => ({
          label: enumData[enumKey], // 枚举的值作为 label
          value: enumKey // 枚举的键作为 value
        }));
      }
      // 处理元数据
      else if (metadataCode) {
        list = this.MetaDataAllEnums[metadataCode] || [];
        list = list.map(item => ({
          label: item.value, // 元数据的 value 作为 label
          value: item.key // 元数据的 key 作为 value
        }));
      }

      return list; // 返回统一处理的 { label, value } 数组
    },

    // 表单提交
    handleSubmit() {
      this.$refs.dynamicForm.validate(valid => {
        if (valid) {
          this.$emit('submit', this.formData); // 提交表单数据
          // this.close();
        } else {
          console.log('表单校验失败');
          return false;
        }
      });
    },

    handlePreview(file) {
      console.log('Preview:', file);
    },

    handleRemove(file, fileList) {
      console.log('Remove:', file, fileList);
    },
    close() {
      this.visible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.dynamic-form {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-y: auto;
}
.selectClass {
  /deep/.el-select-dropdown {
    position: absolute !important;
    top: 35px !important;
  }

  /deep/ .el-picker-panel,
  /deep/ .el-cascader__dropdown {
    position: absolute !important;
    z-index: 2000 !important; /* 提高层级，确保下拉框在页面上可见 */
  }
}
</style>
