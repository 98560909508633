/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-06 10:19:56
 * @LastEditTime: 2024-08-13 13:11:00
 * @LastEditors: Ran junlin
 */
// 应用配置
module.exports = {
  // 系统名称
  title: 'Hi～欢迎使用飞雁助贷系统',
  titleEn: 'Ding Master',
  slogan: '叮师傅',
  appSlogan: '叮师傅',
  copyright: 'Copyright © 2024 融盟科技(重庆)有限公司 版权所有',

  // 公司信息
  company: {
    name: '融盟科技(重庆)有限公司',
    nameShot: '融盟科技',
    nameEn: 'Chongqing RongMeng Data Network Technology Co., Ltd',
    nameEnShot: 'DingDang Data',
    phone: '400-6766-000',
    website: 'http://www.songcw.com/',
    address: '重庆市渝中区华盛路10号企业天地2号楼3401'
  },

  // 备案号
  record: '',

  // 系统版本号
  version: '1.0',

  // 缓存前缀
  cachePrefix: '__feiyan_loan__'
};
