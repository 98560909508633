var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c(
        "el-tag",
        _vm._g(_vm._b({}, "el-tag", _vm.$attrs, false), _vm.$listeners),
        [_vm._v(_vm._s(_vm.txtTitle || "-"))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }