
<template>
  <div class="w_100_per">
    <el-form v-bind="$attrs" ref="ruleFormRef" :model="formDate" :rules="newRules">
      <el-row v-bind="rowOptions">
        <template v-for="(item, index) in formColumn">
          <el-col :key="index + 'sds_rm'" v-if="!item.isNotShow" v-bind="item.col">
            <template v-if="item.slotName">
              <el-form-item :label="item.label" :prop="item.prop" v-bind="item.formItemProps">
                <slot :name="item.slotName" :item="item"></slot>
              </el-form-item>
            </template>
            <el-form-item v-else :label="item.label" :prop="item.prop" v-bind="item.formItemProps">
              <component
                :is="item.type"
                v-bind="item.componentsProps"
                v-on="item.event"
                v-model="formDate[item.prop]"
              />
            </el-form-item>
          </el-col>
        </template>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'CustomForm',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    rowOptions: {
      type: Object,
      default: () => {}
    },
    formColumn: {
      type: Array,
      default: () => [
        {
          formItemProps: {
            // el-form-item的属性
            labelWidth: '100px'
          },
          isNotShow: false, // 组件是否不显示
          event: {}, // 组件事件
          componentsProps: {}, // 组件的属性
          col: {}, // col的属性
          required: false, // 是否必填
          validator: () => {}, // 自定义校验方法
          slotName: '', // 插槽名字
          label: '', // form label
          type: 'input', // components name
          prop: '' // form prop
        }
      ]
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    formColumn: {
      handler(val) {
        if (val && val.length) {
          const deepCloneColumn = cloneDeep(val);
          deepCloneColumn.forEach(res => {
            // TODO 优化
            if (res.required && !res.validator) {
              this.$set(this.newRules, res.prop, {
                required: !!res.required,
                message: `${
                  res.componentsProps && res.componentsProps.placeholder ? res.componentsProps.placeholder : '请输入'
                }`,
                trigger: ['blur', 'change']
              });
            } else {
              if (!res.required && !res.validator) {
                this.$set(this.newRules, res.prop, {
                  required: !!res.required,
                  message: `${
                    res.componentsProps && res.componentsProps.placeholder ? res.componentsProps.placeholder : '请输入'
                  }`,
                  trigger: ['blur', 'change']
                });
              }
              if (
                res.required &&
                res.validator &&
                Object.prototype.toString.call(res.validator) == '[object Function]'
              ) {
                this.$set(this.newRules, res.prop, {
                  required: !!res.required,
                  validator: res.validator,
                  trigger: ['blur', 'change']
                });
              }
            }
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    formDate: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    },
    // 取消rule合并 TODO 是否需要合并rule
    newRules: {
      get() {
        return this.rules;
      },
      set(v) {
        this.$emit('update:rules', v);
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    getFormRef() {
      return this.$refs.ruleFormRef;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-row {
  display: flex;
  flex-wrap: wrap;
}
/deep/.el-input {
  max-width: 357px;
}
/deep/.el-input__inner {
  max-width: 357px;
}
/deep/.custom-select-w {
  max-width: 357px;
}
/deep/.el-form-item {
  margin-bottom: 13px;
}
/deep/.el-form--label-top .el-form-item__label {
  padding-bottom: 0px;
  color: #999999;
}
</style>
