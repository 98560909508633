export default {
  path: '/dev',
  name: 'dev',
  redirect: { name: 'devHome' },
  meta: {
    title: '开发测试',
    icon: 'icon el-icon-orange',
    n: false,
    tag: false
  },
  component: () => import(/* webpackChunkName:"routesHome" */ '@/layout/Main'),
  children: [
    // 设计规范
    {
      path: '',
      name: 'devHome',
      meta: {
        title: '设计规范',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"routes_system" */ '@/views/dev/Home')
    },

    // 弹窗用法
    {
      path: 'map-select',
      name: 'devMapSelect',
      meta: {
        title: '百度地图选点',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"routes_system" */ '@/views/dev/MapSelect')
    },

    // 表格筛选组件
    {
      path: 'filter-box',
      name: 'devFilterBox',
      meta: {
        title: '表格筛选组件',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"routes_system" */ '@/views/dev/FilterBox')
    },

    // 弹窗用法
    {
      path: 'modal',
      name: 'devModal',
      meta: {
        title: '弹窗用法',
        hidden: false,
        icon: 'icon',
        tag: true
      },
      component: () => import(/* webpackChunkName:"routes_system" */ '@/views/dev/Modal')
    },
    // 图片上传组件用法
    {
      path: 'upload-img',
      name: 'uploadImg',
      meta: {
        title: '图片上传组件',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"routesDev" */ '@/views/dev/Upload')
    },
    // 图片上传组件用法
    {
      path: 'editor',
      name: 'editor',
      meta: {
        title: '富文本编辑器',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"editor" */ '@/views/dev/Editor')
    },
    {
      path: 'RenderSelecte',
      name: 'RenderSelecte',
      meta: {
        title: 'RenderSelecte',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"editor" */ '@/views/dev/renderSelecte.vue')
    },
    //电子签名
    {
      path: 'signature',
      name: 'signature',
      meta: {
        title: '电子签名',
        icon: 'icon',
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"editor" */ '@/views/dev/eSignature.vue')
    }
  ]
};
