<!--
 * @Description: 
 * @Author: Ran junlin
 * @Date: 2023-08-14 15:16:48
 * @LastEditTime: 2023-08-16 15:13:35
 * @LastEditors: Ran junlin
-->
<script lang="jsx">
import { TableColumn } from 'element-ui';
export default {
  components: { TableColumn },
  name: 'Column',
  props: {
    ...TableColumn.props,
    customerProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    columnOptions() {
      const { customerProps } = this.$props;
      const options = {
        key: customerProps.field,
        label: customerProps.label,
        prop: customerProps.field,
        formatter: customerProps.render,
        type: customerProps.type,
        width: customerProps.width,
        minWidth: customerProps.minWidth,
        fixed: customerProps.fixed,
        align: 'left',
        showOverflowTooltip: true,
        ...customerProps
      };
      return options;
    }
  },
  methods: {},
  render() {
    return (
      <TableColumn
        {...{
          props: { ...this.$props, ...this.columnOptions }
        }}
      >
        {Object.keys(this.$slots).map(name => (
        <template slot={name}>{this.$slots[name]}</template>
        ))}
      </TableColumn>
    );
  }
};
</script>
<style lang="scss"></style>
