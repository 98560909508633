var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col flex-center justify-start" },
    [
      _c("el-input", {
        attrs: { size: "small" },
        model: {
          value: _vm.txtVal,
          callback: function ($$v) {
            _vm.txtVal = $$v
          },
          expression: "txtVal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }