import http from '@/http';

//loan全局枚举
export function getAllEnums() {
  const url = http.buildServiceUrl('LOAN', `common/allEnums`);
  return http.get('/loan/common/allEnums');
}

//loan全局枚举
export function getFlowAllEnums() {
  const url = http.buildServiceUrl('FLOW', 'common/allEnums');
  return http.get('/flow/common/allEnums');
}

//全局枚举 - 订单
export function getOdrAllEnums() {
  return http.get('/base/platform/enums/odr/all');
}
// 全局枚举 - 用户列表
export function getUserListEnums(data) {
  const url = http.buildServiceUrl('LOAN', `staffs/position`);
  return http.post(url, data);
}
// 全局枚举 - 所有启用的用户
export function getEnabledUserList(data) {
  const url = http.buildServiceUrl('CRAFT', `customer/crm_tenant/enabled`);
  return http.post(url, data);
}

// 上牌城市
export async function plateCityEnums() {
  const url = http.buildServiceUrl('CRAFT', `regionService/tree`);
  const res = await http.get(url);
  return res?.data || {};
}

// 获取组织架构接口
export const getAllOrgList = async data => {
  const url = http.buildServiceUrl('LOAN', `organizations/tree`);
  const res = await http.get(url, data);
  return res?.data || {};
};

// 获取单个枚举
export const getSingleEnumsData = async code => {
  const url = http.buildServiceUrl('CRAFT', `common/enums/${code}`);
  const res = await http.get(url);
  return res?.data || {};
};
// 通过节点编码查询子节点列表
export const getChildNodes = async code => {
  const url = http.buildServiceUrl('FLOW', `flow/task/find/childNodes?nodeCode=${code}`);
  const res = await http.get(url);
  return res?.data || {};
};
