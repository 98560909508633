<template>
  <div class="pos_rel">
    <!-- 富文本编辑器 -->
    <vue-ueditor-wrap
      :value="value"
      :destroy="true"
      @input="$emit('input', $event)"
      @ready="v => (ue = v)"
      :config="{
        ...myConfig,
        wordCount: true,
        wordCountMsg: `<span style='color:#999'>最多${maximumWords}个字符</span>`
      }"
      @beforeInit="addCustomButtom"
    ></vue-ueditor-wrap>

    <!-- 蒙层 -->
    <div v-show="disabled" class="pos_abs l_0 t_0 z_9999 w_100_per h_100_per bg_col_rgba_255_255_255_50_per"></div>

    <!--  -->
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap';
import myConfig from '@/static/js/ueDitorconfig';

import { uploadFile } from '@/api/upload';
import * as http from '@/http';

export default {
  mixins: [],
  components: {
    VueUeditorWrap
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },

    // 最大字数
    maximumWords: {
      type: Number,
      default: () => 1000
    },

    disabled: Boolean

    //
  },
  data() {
    return {
      myConfig,
      ue: null

      //
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 请求-上传附件
    openUploadDialog() {
      const inputFile = document.createElement('input');
      inputFile.type = 'file';
      inputFile.accept = 'image/*';
      inputFile.style.display = 'none';
      document.body.appendChild(inputFile);
      inputFile.click();
      inputFile.addEventListener('change', async () => {
        const file = inputFile.files[0];
        const url = http.buildServiceUrl('FILE', `file/upload`);
        const img = await uploadFile(file, url);
        const { storageAddress } = img;
        document.body.removeChild(inputFile);
        this.ue.execCommand('inserthtml', `<img src=${storageAddress}>`);
      });
    },

    addCustomButtom(editorId) {
      let that = this;
      window.UE.registerUI(
        'img-upload-btn',
        (editor, uiName) => {
          editor.registerCommand(uiName, {
            execCommand: function () {
              that.openUploadDialog('img');
            }
          });
          var btn = new window.UE.ui.Button({
            name: uiName,
            title: '图片上传',
            cssRules: "background: url('./static/UEditor/themes/default/images/icons.png') no-repeat -380px 0px",
            onclick: function () {
              editor.execCommand(uiName);
            }
          });
          editor.addListener('selectionchange', function () {
            var state = editor.queryCommandState(uiName);
            if (state === -1) {
              btn.setDisabled(true);
              btn.setChecked(false);
            } else {
              btn.setDisabled(false);
              btn.setChecked(state);
            }
          });
          return btn;
        },
        0,
        editorId
      );

      //
    }

    //
  }
};
</script>

<style lang="scss" scoped></style>
