<template>
	<div class="handle-copy" :title="tips">
		<div class="handle" v-clipboard="content" @success="handleSuccess" @error="handleError">
			<div class="expand">
				<slot />
			</div>
			<i class="theme-color iconfont icon-fuzhi"></i>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import VueClipboards from 'vue-clipboards';

Vue.use(VueClipboards);

// 复制到剪贴板
export default {
	name: 'Copy',
	props: {
		// 复制的内容
		content: {
			type: [String, Number],
			default: null
		},

		// 悬停提示+
		tips: {
			type: String,
			default: '复制到剪贴板'
		}
	},
	methods: {
		handleSuccess () {
			this.msg({
				type: 'success',
				content: '复制成功'
			});
		},

		handleError () {
			this.msg({
				type: 'error',
				content: '复制失败，请手动复制'
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import '../style/const.scss';

.handle-copy {
	display: inline-flex;

	.expand {
		flex: 1;
		overflow: hidden;
	}

	.handle {
		// align-items: center;
		// border-radius: 4px;
		// cursor: pointer;
		// display: flex;
		// flex-shrink: 0;
		// justify-content: center;
		// margin-left: $void;
		// height: 28px;

		i {
			color: $colorPrimary;
			display: block;
			font-size: 20px;
			line-height: 1;
		}
	}
}
</style>
