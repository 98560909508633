var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.view,
        title: _vm.title,
        width: "480px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.view = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-handle-wrap" },
        [
          _vm.isFormComponents
            ? _c(
                "IForm",
                _vm._b(
                  {
                    ref: "IForm",
                    model: {
                      value: _vm.form,
                      callback: function ($$v) {
                        _vm.form = $$v
                      },
                      expression: "form",
                    },
                  },
                  "IForm",
                  _vm.configItem,
                  false
                )
              )
            : _c(
                "el-form",
                {
                  ref: "diaHldForm",
                  staticClass: "form",
                  attrs: {
                    size: "small",
                    model: _vm.form,
                    rules: _vm.rules,
                    "label-position": "left",
                  },
                },
                _vm._l(_vm.formObject, function (item, key) {
                  return _c(
                    "el-form-item",
                    { key: key, attrs: { label: item.name, prop: key } },
                    [
                      item.readOnly && _vm.form[key]
                        ? _c("span", [_vm._v(_vm._s(_vm.form[key]))])
                        : item.type == "text"
                        ? _c(
                            "el-input",
                            _vm._b(
                              {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form[key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, key, $$v)
                                  },
                                  expression: "form[key]",
                                },
                              },
                              "el-input",
                              item.props || {},
                              false
                            )
                          )
                        : item.type == "select"
                        ? _c(
                            "el-select",
                            _vm._b(
                              {
                                attrs: { placeholder: "请选择", size: "small" },
                                on: {
                                  change: (v) => _vm.selcetChange(v, item),
                                },
                                model: {
                                  value: _vm.form[key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, key, $$v)
                                  },
                                  expression: "form[key]",
                                },
                              },
                              "el-select",
                              item.props || {},
                              false
                            ),
                            _vm._l(_vm.getOptions(item), function (op, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: op.value, value: op.key },
                              })
                            }),
                            1
                          )
                        : item.type == "textarea"
                        ? _c(
                            "el-input",
                            _vm._b(
                              {
                                attrs: {
                                  type: "textarea",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form[key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, key, $$v)
                                  },
                                  expression: "form[key]",
                                },
                              },
                              "el-input",
                              item.props || {},
                              false
                            )
                          )
                        : item.type == "radio"
                        ? _c(
                            "el-radio-group",
                            _vm._b(
                              {
                                model: {
                                  value: _vm.form[key],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, key, $$v)
                                  },
                                  expression: "form[key]",
                                },
                              },
                              "el-radio-group",
                              item.props || {},
                              false
                            ),
                            _vm._l(_vm.getOptions(item), function (op) {
                              return _c("el-radio", {
                                key: op.value,
                                attrs: { label: op.value, value: op.key },
                              })
                            }),
                            1
                          )
                        : item.type == "regiontree" &&
                          _vm.regionTreeWithoutDistrict
                        ? _c("el-cascader", {
                            attrs: {
                              props: {
                                label: "regionName",
                                value: "regionCode",
                                children: "childList",
                                checkStrictly: false,
                                emitPath: false,
                              },
                              options: _vm.regionTreeWithoutDistrict,
                              clearable: "",
                              filterable: "",
                            },
                            model: {
                              value: _vm.form[key],
                              callback: function ($$v) {
                                _vm.$set(_vm.form, key, $$v)
                              },
                              expression: "form[key]",
                            },
                          })
                        : item.type == "switch"
                        ? _c("el-switch", {
                            attrs: {
                              "active-value": "Y",
                              "inactive-value": "N",
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.form[key],
                              callback: function ($$v) {
                                _vm.$set(_vm.form, key, $$v)
                              },
                              expression: "form[key]",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.pending,
              expression: "pending",
            },
          ],
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }