const serviceMap = {
  CRAFT: {
    serverName: 'craft',
    serverVersion: 'v1'
  },
  LOAN: {
    serverName: 'loan',
    serverVersion: 'v1'
  },
  AFTER_LOAN: {
    serverName: 'afterloan',
    serverVersion: 'v1'
  },
  SIGN: {
    serverName: 'sign',
    serverVersion: 'v1'
  },
  FLOW: {
    serverName: 'flow',
    serverVersion: 'v1'
  },
  MESSAGE: {
    serverName: 'message',
    serverVersion: 'v1'
  },
  OAUTH: {
    serverName: 'oauth',
    serverVersion: 'v1'
  },
  FILE: {
    serverName: 'file',
    serverVersion: 'v1'
  }
};

export default serviceMap;
