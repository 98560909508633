var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "elDraggableDialog", rawName: "v-elDraggableDialog" },
      ],
      attrs: {
        title: _vm.title,
        width: "700px",
        visible: _vm.visible,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.formLoading,
                  expression: "formLoading",
                },
              ],
              ref: "dynamicForm",
              staticClass: "dynamic-form",
              attrs: {
                model: _vm.formData,
                rules: _vm.formRules,
                "label-width": "auto",
              },
              on: { submit: _vm.handleSubmit },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            "el-form",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.fields, function (field, index) {
              return _c(
                "el-col",
                {
                  key: index,
                  attrs: {
                    span: field.promptType === "FILE_UPLOAD" ? 24 : _vm.span,
                  },
                },
                [
                  field.webShowStatus === "SHOW"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: field.fieldLabel,
                            prop: field.fieldName,
                          },
                        },
                        [
                          field.promptType === "INPUT" ||
                          field.promptType === "TEXTAREA"
                            ? [
                                _c("el-input", {
                                  attrs: {
                                    disabled: field.editStatus === "UNABLE",
                                    maxLength: field.maxLength,
                                    placeholder: "请输入" + field.fieldLabel,
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData[field.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        field.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[field.fieldName]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "DIGIT"
                            ? [
                                _c("el-input", {
                                  directives: [
                                    {
                                      name: "amount",
                                      rawName: "v-amount",
                                      value: {
                                        integerLength: field.maxLength,
                                        decimalLength: 0,
                                      },
                                      expression:
                                        "{ integerLength: field.maxLength, decimalLength: 0 }",
                                    },
                                  ],
                                  class:
                                    field.extra && field.extra.unit
                                      ? _vm.getExtraUnit(field.extra.unit)
                                      : "",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled: field.editStatus === "UNABLE",
                                    placeholder: "请输入" + field.fieldLabel,
                                    clearable: "",
                                    min: 1,
                                    maxLength: field.maxLength,
                                  },
                                  model: {
                                    value: _vm.formData[field.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        field.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[field.fieldName]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "CAR_SELECT"
                            ? [
                                _c("el-select", {
                                  staticClass: "selectClass",
                                  attrs: {
                                    "popper-append-to-body": false,
                                    placeholder: "请选择",
                                    filterable: false,
                                    clearable: "",
                                  },
                                  on: {
                                    clear: function ($event) {
                                      return _vm.handleCarClear(field)
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleSelectCar(field)
                                    },
                                  },
                                  model: {
                                    value: _vm.vehicleModel,
                                    callback: function ($$v) {
                                      _vm.vehicleModel = $$v
                                    },
                                    expression: "vehicleModel",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "REGION_SELECT"
                            ? [
                                _c("el-cascader", {
                                  staticClass: "selectClass",
                                  attrs: {
                                    "popper-append-to-body": "",
                                    placeholder: "请输入" + field.fieldLabel,
                                    disabled: field.editStatus === "UNABLE",
                                    options: _vm.regionTree,
                                    props: {
                                      label: "regionName",
                                      value: "regionCode",
                                      children: "childList",
                                      emitPath: true,
                                    },
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.formData[field.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        field.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[field.fieldName]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "CITY_SELECT"
                            ? [
                                _c("el-cascader", {
                                  staticClass: "selectClass",
                                  attrs: {
                                    "popper-append-to-body": "",
                                    placeholder: "请输入" + field.fieldLabel,
                                    options: _vm.regionTreeArea,
                                    disabled: field.editStatus === "UNABLE",
                                    props: {
                                      label: "regionName",
                                      value: "regionCode",
                                      children: "childList",
                                      emitPath: true,
                                    },
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.formData[field.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        field.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[field.fieldName]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "CITY_CASCADER"
                            ? [
                                _c("el-cascader", {
                                  staticClass: "selectClass",
                                  attrs: {
                                    "popper-append-to-body": "",
                                    placeholder: "请输入" + field.fieldLabel,
                                    options: _vm.regionTree,
                                    disabled: field.editStatus === "UNABLE",
                                    props: {
                                      label: "regionName",
                                      value: "regionCode",
                                      children: "childList",
                                      emitPath: true,
                                    },
                                    clearable: "",
                                    filterable: "",
                                  },
                                  model: {
                                    value: _vm.formData[field.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        field.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[field.fieldName]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "SELECT"
                            ? [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "selectClass",
                                    attrs: {
                                      "popper-append-to-body":
                                        _vm.isPopperAppendToBody,
                                      placeholder: "请选择",
                                      clearable: "",
                                      filterable: "",
                                      disabled: field.editStatus === "UNABLE",
                                    },
                                    model: {
                                      value: _vm.formData[field.fieldName],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          field.fieldName,
                                          $$v
                                        )
                                      },
                                      expression: "formData[field.fieldName]",
                                    },
                                  },
                                  _vm._l(
                                    _vm.getOptionsByEnumCode(
                                      field.enumCode,
                                      field.metadataCode
                                    ),
                                    function ({ label, value }) {
                                      return _c("el-option", {
                                        key: value,
                                        attrs: { label: label, value: value },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]
                            : _vm._e(),
                          field.promptType === "DATE"
                            ? [
                                _c("el-date-picker", {
                                  staticClass: "selectClass",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "popper-append-to-body": "",
                                    disabled: field.editStatus === "UNABLE",
                                    type: "date",
                                    placeholder: "选择日期",
                                    clearable: "",
                                    "value-format": "yyyy-MM-dd",
                                  },
                                  model: {
                                    value: _vm.formData[field.fieldName],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        field.fieldName,
                                        $$v
                                      )
                                    },
                                    expression: "formData[field.fieldName]",
                                  },
                                }),
                              ]
                            : _vm._e(),
                          field.promptType === "FILE_UPLOAD"
                            ? [
                                _c("Upload", {
                                  attrs: {
                                    number: 99,
                                    width: 120,
                                    height: 120,
                                    fileType: "ALL",
                                    disable: false,
                                    enableCropper: false,
                                    multiple: true,
                                    initUrl: _vm.formData[field.fieldName],
                                    uploadTips: "添加",
                                  },
                                  on: {
                                    imgupload: function ($event) {
                                      return _vm.handleImgs($event, field)
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
      _vm.vehicleManageShow
        ? _c("VehicleManage", {
            on: { selected: _vm.handleSelectedVehicle },
            model: {
              value: _vm.vehicleManageShow,
              callback: function ($$v) {
                _vm.vehicleManageShow = $$v
              },
              expression: "vehicleManageShow",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }