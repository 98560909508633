// 控制快递单输入
import { sanitizeExpressInput } from "@/utils/common"

export default {
    bind: (el, binding, vnode) => {
        const element = el.getElementsByTagName('input')[0]
        element.addEventListener('input', function () {
            let val = sanitizeExpressInput(element.value)
            element.value = val
            vnode.data.model.callback(val)
        })
    }
};