/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-16 16:58:50
 * @LastEditTime: 2024-08-20 15:20:58
 * @LastEditors: Ran junlin
 */
export default {
  path: '/configuration',
  name: 'Configuration',
  meta: {
    role: '14083a5f-d677-4f22-9412-50ebbeb4bfff',
    title: '配置管理',
    icon: 'icon el-icon-cpu',
    hidden: false,
    tag: true
  },
  component: () => import(/* webpackChunkName:"Customer" */ '@/layout/Main'),
  children: [
    {
      name: 'Production',
      path: 'productionManage',
      meta: {
        role: 'f98cddef-28bd-49a9-8005-2550efbb9819',
        icon: 'icon',
        title: '产品管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/customer/productionManage/index')
    },
    {
      name: 'FlowManage',
      path: 'flowManage',
      meta: {
        role: '64ca6399-54f3-4ba6-975d-1d7b91a65176',
        icon: 'icon',
        title: '流程管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/customer/flowManage/index')
    },
    {
      name: 'FlowConfig',
      path: 'flowConfig/:id',
      meta: {
        role: 'f02f53ee-9b9a-4fdd-a3bb-b96bc9cad9e7',
        icon: 'icon',
        title: '流程配置',
        hidden: true,
        tag: true
      },
      component: () => import('@/views/customer/flowConfig/index')
    },
    {
      name: 'CompanyMange',
      path: 'companyMange',
      meta: {
        role: '432245ca-402c-44e3-96a4-6bfa729c040f',
        icon: 'icon',
        title: '上户公司管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/customer/companyMange/index')
    }
  ]
};
