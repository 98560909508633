import idValidator from 'id-validator';

/**
 * 按身份证号码获取性别
 * @idNumber 身份证号码
 * @return 男：male；女：female；异常（身份证号码为空或长度、格式错误）：undefined
 */
export function getGenderByIDCard(idNumber) {
  if (idNumber) {
    let genderCode; // 性别代码
    if (idNumber.length == 18) {
      // 二代身份证号码长度为18位（第17位为性别代码）
      genderCode = idNumber.charAt(16);
    } else if (idNumber.length == 15) {
      // 一代身份证号码长度为15位（第15位为性别代码）
      genderCode = idNumber.charAt(14);
    }
    if (genderCode && !isNaN(genderCode)) {
      // 两代身份证号码的性别代码都为男奇女偶
      if (parseInt(genderCode) % 2 == 0) {
        return 'female';
      }
      return 'male';
    }
  }
}

// 根据身份证号获取年龄
export function getAgeByIDCard(IDCard) {
  var age = 0,
    yearBirth,
    monthBirth,
    dayBirth;
  //获取用户身份证号码
  var userCard = IDCard;
  //如果身份证号码为undefind则返回空
  if (!userCard) {
    return age;
  }
  var reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/; //验证身份证号码的正则
  if (reg.test(userCard)) {
    if (userCard.length == 15) {
      var org_birthday = userCard.substring(6, 12);
      //获取出生年月日
      yearBirth = '19' + org_birthday.substring(0, 2);
      monthBirth = org_birthday.substring(2, 4);
      dayBirth = org_birthday.substring(4, 6);
    } else if (userCard.length == 18) {
      //获取出生年月日
      yearBirth = userCard.substring(6, 10);
      monthBirth = userCard.substring(10, 12);
      dayBirth = userCard.substring(12, 14);
    }
    //获取当前年月日并计算年龄
    var myDate = new Date();
    var monthNow = myDate.getMonth() + 1;
    var dayNow = myDate.getDate();
    age = myDate.getFullYear() - yearBirth;
    if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
      age--;
    }
    //返回年龄
    return age;
  } else {
    return '';
  }
}

// 深拷贝
export const deepClone = obj => {
  var objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
};

// 是否为车架号
export const isCarFrameNumber = function (vin) {
  if (vin.length > 0 && vin.length != 17) {
    return false;
  } else {
    let vinVal = vin.toUpperCase();
    //document.getElementById("vin_"+k).value = vinVal;
    let charToNum = {
      A: 1,
      B: 2,
      C: 3,
      D: 4,
      E: 5,
      F: 6,
      G: 7,
      H: 8,
      J: 1,
      K: 2,
      L: 3,
      M: 4,
      N: 5,
      P: 7,
      R: 9,
      S: 2,
      T: 3,
      U: 4,
      V: 5,
      W: 6,
      X: 7,
      Y: 8,
      Z: 9
    };
    let obj = 0;
    let arr = new Array();
    for (let i = 0; i < vinVal.length; i++) {
      let temp = vinVal.charAt(i);

      if (charToNum[temp]) {
        arr[i] = charToNum[temp];
      } else {
        arr[i] = Number(temp);
      }
      if (i == 8) {
        arr[i] = vinVal.charAt(i);
      }
    }
    let a1 = 8;
    for (let i = 0; i < 7; i++) {
      obj += Number(arr[i]) * a1;
      a1--;
    }

    obj += Number(arr[7]) * 10;

    let a2 = 9;
    for (let i = 9; i < 17; i++) {
      obj += Number(arr[i]) * a2;
      a2--;
    }

    let result = Number(obj) % 11;
    if (parseInt(result) === 10) {
      result = 'X';
    }
    if (result == arr[8]) {
      //成功
      return true;
    } else {
      //失败
      return false;
    }
  }
};

/// 是否为统一社会信用代码
export const isSocialCreditCode = val => {
  const re = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
  return re.test(val);
};

// 8-16位账号正则
export const isValidAccount = val => {
  const re = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/;
  return re.test(val);
};

/// 非中文正则
export const nonChineseRegex = /[^\u4e00-\u9fa5]/g;

/// 是否为中文
export const isChinese = val => {
  const re = /^[\u4e00-\u9fa5]+$/gi;
  return re.test(val);
};

/// 是否为中文+字符·
export const isChineseAndChar = val => {
  const re = /^[\u4e00-\u9fa5·]+$/gi;
  return re.test(val);
};

/// 是否为中文+字符 ~ - _ —— *
export const isNetworkName = val => {
  const re = /^[\u4e00-\u9fa5~\-\-_—*]+$/gi;
  return re.test(val);
};

/// 是否必须包含数字和字母,不含字符（密码）
export const isPassWord = val => {
  const reg = new RegExp(/^(?![^a-zA-Z]+$)(?!\D+$)/); // 必须包含数字+字母
  const reg2 = new RegExp(/^[a-zA-Z0-9]+$/); // 只能数字+英文
  return reg.test(val) && reg2.test(val);
};

/// 只能输入汉字，数字和英文字母
export const isNotIncludeSpecialChar = val => {
  const re = /^[\u4e00-\u9fa5a-zA-Z0-9]+$/gi;
  return re.test(val);
};

/// 是否是ICCID
export const isICCID = val => {
  const re = /^[A-F0-9]+$/g;
  return re.test(val) && val && val.length == 20;
};

/// 只能输入数字和英文字母
export const isNumberOrChar = val => {
  const re = /^[a-zA-Z0-9]+$/gi;
  return re.test(val);
};

/// 是否必填
export const isRequired = val => {
  if (val === '' || typeof val === 'undefined') {
    return false;
  }
  return true;
};

/// 价格格式校验
export const isPrice = val => {
  const re = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
  return re.test(val);
};

/// 是否重复,arr-数组，len-重复的val
export const isRepeat = (arr, val) => {
  const isRepeat = arr.some(item => {
    if (item === val) {
      return true;
    }
  });
  return !isRepeat;
};

/// 是否手机号
export const isMobile = val => {
  const re = /(^1[3456789][0-9]{9}$)/;
  return re.test(val);
};

/// 是否座机号
export const isLandline = val => {
  const reg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
  return reg.test(val);
};

/// 数字是否超出范围限制
export const isInRange = ({ val, max, min }) => {
  if (val.length > max || val.length < min) {
    return false;
  }
  return true;
};

/// 是否数字
export const isNumber = val => {
  if (!Math.trunc(val) && Math.trunc(val) !== 0) {
    return false;
  }
  return true;
};

/// 是否车牌（含新能源车牌）
export const isVehicleNumber = val => {
  var xreg =
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
  var creg =
    /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
  if (val.length == 7) {
    return creg.test(val);
  } else if (val.length == 8) {
    return xreg.test(val);
  } else {
    return false;
  }
};

/// 是否身份证
export const isIdcard = val => {
  const validator = new idValidator();
  return validator.isValid(val);
};

/// 是否email
export const isEmail = val => {
  const re = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  return re.test(val);
};

//手机号或者码校验
export const validPhoneOrMobile = (rule, value, callback) => {
  if (!value && rule.required) {
    callback(new Error('请输入电话号码'));
  } else if (!(isMobile(value) || isLandline(value)) && rule.required) {
    callback(new Error('请输入正确的11位手机号码或者8位座机号'));
  } else {
    callback();
  }
};

//手机号码校验
export const validPhone = (rule, value, callback) => {
  if (!value && rule.required) {
    callback(new Error('请输入电话号码'));
  } else if (!isMobile(value) && rule.required) {
    callback(new Error('请输入正确的11位手机号码'));
  } else {
    callback();
  }
};

//统一社会信用代码校验
export const validSocialCreditCode = (rule, value, callback) => {
  if (!value && rule.required) {
    callback(new Error('请输入统一社会信用代码'));
  } else if (!isSocialCreditCode(value) && rule.required) {
    callback(new Error('请输入正确的18位统一社会信用代码'));
  } else {
    callback();
  }
};

//账号8-16位数字+英文
export const validAccount = (rule, value, callback) => {
  if (!value && rule.required) {
    callback(new Error('请输入账号'));
  } else if (!isValidAccount(value) && rule.required) {
    callback(new Error('请输入8-16位数字及英文组合'));
  } else {
    callback();
  }
};

// 校验array数组
export const validArray = (rule, value, callback) => {
  console.log('校验', rule, value, callback);
  if (!value || value.length === 0) {
    callback(new Error('请选择至少一项'));
  } else {
    callback();
  }
};

/**
 * 对指定的表单进行校验。
 *
 * @param {VueComponent} formRef - 需要校验的表单的 Vue 引用。
 * @returns {Promise<boolean>} 如果校验通过返回 true，否则抛出错误。
 * https://github.com/ElemeFE/element/issues/21348  解决 element官方正对于form表单的validate返回值做处理
 */
export async function validateForm(formRef, tips = '请完善表单信息') {
  return new Promise((resolve, reject) => {
    formRef.validate(valid => {
      if (valid) {
        resolve(true);
      } else {
        reject(tips);
      }
    });
  });
}

// 添加判断方法
export function isMobileClint() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}
