<template>
  <div class="flex flex-col">
    <div v-for="(item, index) in list" :key="index + 'abc'" class="flex flex-col color-black">
      <div>{{ item.requireName }}</div>
      <div v-html="item.description">{{ item.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductionDesc',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [
        // {
        //   name: '>征信要求',
        //   desc: '1、asdfasdfasdfadfassdfads\n2、啊手动阀的说法是',
        // },
        // {
        //   name: '>准入要求',
        //   desc: '1、准入要求撒旦发生\n2、2323撒发生啊手动阀',
        // },
      ],
    },
  },
  computed: {
    list: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
};
</script>

<style scoped></style>
