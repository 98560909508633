// HTTP 请求
import qs from 'qs';
import axios from 'axios';
import HttpConfig from '@/config/http';
import serviceMap from '@/config/servicesConfig';
import '@/http/interceptor/request.js'; // 导入axios请求监听器
import '@/http/interceptor/response.js'; // 导入axios请求回调监听器

// Axios 配置
axios.defaults.baseURL = HttpConfig.api;
axios.defaults.timeout = HttpConfig.timeout; // 超时时间
axios.defaults.withCredentials = true; // 携带客户端验证身份
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

/**
 * URL 参数混入
 * @param {String} url URL
 * @param {Object} params 参数
 * @param {boolean} remove 匹配成功时，是否从参数中移除
 */
function _mixin(url, params, remove = true) {
  if (Array.isArray(params)) {
    return url;
  }
  for (const key in params) {
    const reg = new RegExp(`{${key}}`, 'g');
    if (reg.test(url)) {
      url = url.replace(reg, params[key]);
      if (remove) {
        delete params[key];
      }
    }
  }
  return url;
}

// 分页查询使用的分页参数和默认值
export const paging = {
  pageNumber: 1,
  pageSize: 10
};

/**
 * 发送请求
 * @param {*} config
 */
const request = function (config, remove = true) {
  if (config.url) {
    if (config.data && config.data instanceof Object) {
      config.url = _mixin(config.url, config.data, remove);
    }
    if (config.params && config.params instanceof Object) {
      config.url = _mixin(config.url, config.params, remove);
    }
  }

  return axios(config);
};

// 封装 GET 方法
export const get = function (url, data = {}, options = {}) {
  url = setUrl(data, url);
  return request({
    method: 'GET',
    url,
    params: data,
    data,
    options
  });
};

//将page参数放到url地址上
const setUrl = (data, url) => {
  let pageParams = {};
  for (let key in data) {
    if (!data[key] && data[key] != false) {
      delete data[key];
    }
    // if (['pageIndex', 'pageSize'].includes(key)) {
    //   let value = data[key];
    //   if (key === 'pageIndex') {
    //     value = Math.max(1, +value || 0);
    //     pageParams['pageNumber'] = value;
    //   }
    //   if (key === 'pageSize') {
    //     value = +value || 0;
    //     value = value === 0 ? paging.pageSize : Math.max(1, value);
    //     pageParams[key] = value;
    //   }
    //   if (!data.pageToBody) {
    //     delete data[key];
    //   }
    // }
  }
  if (JSON.stringify(pageParams) !== '{}') {
    if (url.includes('?')) {
      url = `${url}&${qs.stringify(pageParams)}`;
    } else {
      url = `${url}?${qs.stringify(pageParams)}`;
    }
  }
  return url;
};

// 封装 POST 方法
export const post = function (url, data = {}, options = {}) {
  url = setUrl(data, url);
  return request({
    method: 'POST',
    url,
    data,
    ...options
  });
};

// 封装 PUT 方法
export const put = function (url, data = {}, options = {}, remove = true) {
  return request(
    {
      method: 'PUT',
      url,
      data,
      ...options
    },
    remove
  );
};

// 封装 PATCH 方法
export const patch = function (url, data = {}, options = {}) {
  return request({
    method: 'PATCH',
    url,
    data,
    ...options
  });
};

// 封装 DELETE 方法
export const del = function (url, data = {}, options = {}) {
  return request({
    method: 'DELETE',
    url,
    data,
    ...options
  });
};

export function buildServiceUrl(serviceKey, endpoint) {
  const { serverName, serverVersion } = serviceMap[serviceKey];
  const baseUrl = `/${serverName}/${serverVersion}`;
  return `${baseUrl}/${endpoint}`;
}

export default {
  paging,
  get,
  post,
  put,
  patch,
  axios,
  request,
  delete: del,
  config: HttpConfig,
  buildServiceUrl,
  serviceMap
};
