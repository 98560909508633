import Vue from 'vue';
import VueRouter from 'vue-router';
import { clearCache } from '@/utils/app';

import * as auth from '@/utils/auth';
import routes from './routes';

Vue.use(VueRouter);

/** 解决二次跳转路由报错问题 start **/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
/** 解决二次跳转路由报错问题 end **/

export const routes_default = [
  // 主页
  // 默认情况下，页面会跳转到 { name: 'home' } 路由，
  // 如果为登录，则跳转到 { name: 'login' } 路由
  {
    path: '/',
    redirect: {
      name: 'login'
    },
    component: () => import(/* webpackChunkName:"routes" */ '@/layout/Main')
    // children: [
    //   // 管理中心主页
    //   // 主页一般不做动态路由，否则登录成功后无落地页面
    //   {
    //     path: '/home',
    //     name: 'Home',
    //     meta: {
    //       title: '首页',
    //       icon: 'kd-icon kd-icon-home',
    //       hidden: true,
    //       tag: false
    //     },
    //     component: () =>
    //       import( /* webpackChunkName:"routes" */ '@/views/home/Home')
    //   }
    // ]
  },

  /**
   * 登录页
   * 登录页不使用 layout 布局，所以单独为一个路由
   */
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      hidden: true,
      tag: false
    },
    component: () => import(/* webpackChunkName:"routes" */ '@/views/login/Login')
  },

  // 404
  {
    path: '*',
    name: 'notFound',
    component: () => import(/* webpackChunkName:"routes" */ '_v/NotFound')
  }
];

export const createRouter = params_routes =>
  new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...params_routes]
  });

const router = createRouter(routes_default);

window.router = router;

// 添加动态路由
router.$addRoutes = (params = []) => {
  const newRoute = createRouter(routes_default);
  router.matcher = newRoute.matcher;
  router.options.routes = newRoute.options.routes;
  router.options.routes.push(...params);
  params.forEach(_route => {
    router.addRoute(_route);
  });
};

/**解决路由懒加载发送错误时的处理 */
router.onError(error => {
  console.log(error, '解决路由懒加载发送错误时的处理');
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

// 获取第一个有效的权限路由
// 有效：① 左侧菜单；② 如果存在重定向，则重定向也必须存在；
// 如果没有符合条件的路由，则显示一个空白页；
export const _getFirstRoleRoute = routesData => {
  if (!routesData || !routesData.length) return null;

  // 按层级把路由展开为一维数组
  const _flat = [];
  const _top = [];

  const _expand = (data, level = 0) => {
    data.forEach(item => {
      _flat.push(item);

      if (level === 0) {
        _top.push(item);
      }

      if (item.children) {
        _expand(item.children, level + 1);
      }
    });
  };

  _expand(routesData);

  const _routes = _top.filter(item => {
    if (item.meta.redirect && item.meta.redirect.name) {
      return !!_flat.find(v => v.name === item.meta.redirect.name);
    }

    if (item.redirect && item.redirect.name) {
      return !!_flat.find(v => v.name === item.redirect.name);
    }

    return true;
  });

  if (!_routes.length) return null;

  return _routes[0];
};

// 初始化动态路由
export const initDynamicAuthRoute = () => {
  try {
    // 获取缓存路由并根据用户权限进行递归处理
    // 无需指定权限类型，因为这里只处理菜单
    const _authRoutes = auth.parseRoleRoutes(routes);
    // 更新到路由配置
    if (_authRoutes.length) {
      // 缓存第一个有效的路由为默认路由
      const _firstRoleRoute = _getFirstRoleRoute(_authRoutes);
      auth.setFirstRoleRoute(_firstRoleRoute);
      // 添加动态路由
      router.$addRoutes(_authRoutes);
    }
  } catch (error) {
    console.error(error);
  }
};

// 初始化动态路由
initDynamicAuthRoute();

router.beforeEach((to, from, next) => {
  // 获取登录用户
  if (to.name === 'login') {
    clearCache();
    next();
  } else {
    next();
    return;
  }
});

export default router;
