var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-wrap gap-12" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        {
          key: index + "gls",
          staticClass: "button-item",
          class: _vm.active == item.value ? "isActive" : "",
          on: {
            click: function ($event) {
              return _vm.handlerClick(item)
            },
          },
        },
        [_vm._v(" " + _vm._s(item.label) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }