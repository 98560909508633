<template>
  <div class="w_100_per flex gap-12 flex-center justify-between">
    <el-input-number
      v-model="formData.startNum"
      controls-position="right"
      :min="0"
      size="small"
      :precision="2"
      placeholder="请输入开始金额"
    ></el-input-number>
    <span>至</span>
    <el-input-number
      v-model="formData.endNum"
      controls-position="right"
      :min="0"
      size="small"
      :precision="2"
      placeholder="请输入结束金额"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: 'LineCredit',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formData: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
};
</script>

<style scoped></style>
