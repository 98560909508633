<template>
  <div class="flex flex-center justify-start gap-6">
    <span>{{ labelObj.firstTitle }}</span>
    <el-input-number v-if="isEdit" v-model="val.min" v-bind="minProps"></el-input-number>
    <span v-else>{{ val.min }}</span>
    <span>{{ labelObj.secondTitle }}</span>
    <el-input-number v-if="isEdit" v-model="val.max" v-bind="maxProps"></el-input-number>
    <span v-else>{{ val.max }}</span>
    <span>{{ labelObj.threeTitle }}</span>
  </div>
</template>

<script>
export default {
  name: 'RuleFormEdit',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          min: 0,
          max: 0,
        };
      },
    },
    minProps: {
      type: Object,
      default: () => {
        return {
          min: 0,
          size: 'small',
        };
      },
    },
    maxProps: {
      type: Object,
      default: () => {
        return {
          min: 0,
          size: 'small',
        };
      },
    },
    labelObj: {
      type: Object,
      default: () => {
        return {
          firstTitle: '至少需填写',
          secondTitle: '个联系人，且',
          threeTitle: '个直系亲属',
        };
      },
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    val: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
};
</script>

<style scoped></style>
