<template>
  <div class="flex gap-12">
    <div style="width: 252px">
      <el-input v-model="fileUrl" placeholder="请输入进件流程文档链接" clearable size="small"></el-input>
    </div>
    <!--    <div class="w_184 ellipsis-1" :title="fileUrl">{{ fileUrl }}</div>-->
    <el-upload
      :show-file-list="false"
      accept=".jpg,.jpeg,.png,.pdf"
      :action="uploadUrl"
      :file-list="fileList"
      :on-success="handlerUploadSuccess"
    >
      <el-link type="primary">{{ fileList && fileList.length ? '重新上传' : '+上传进件流程文件' }}</el-link>
    </el-upload>

    <el-link v-if="fileList && fileList.length" type="danger" @click="handlerDel">删除</el-link>
  </div>
</template>

<script>
import HttpConfig from '@/config/http';
import config from '@/config/config';
import * as http from '@/http';
export default {
  name: 'UploadInputFile',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    fileUrl: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  },
  watch: {
    fileUrl: {
      handler(val) {
        if (!val) {
          this.fileList = [];
        } else {
          this.$set(this.$data, 'fileList', [{ name: new Date().getTime() + '文件', url: val }]);
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      uploadUrl: HttpConfig.api + http.buildServiceUrl('FILE', `file/upload`), //'/file/service/file/uploadFile'
      fileList: [
        {
          name: '',
          url: ''
        }
      ]
    };
  },
  methods: {
    handlerUploadSuccess(response, file, fileList) {
      const { data } = response;
      if (data) {
        // const { name, storageAddress } = data;
        const { storageAddress, name } = data || {};
        if (storageAddress) {
          this.fileUrl = storageAddress;
          this.fileList = [{ name, url: storageAddress }];
          this.$emit('callBackData', {
            name,
            url: storageAddress
          });
        }
      }
    },
    handlerDel() {
      this.fileUrl = '';
      this.fileList = [];
    }
  }
};
</script>

<style scoped></style>
