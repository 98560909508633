var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          directives: [
            { name: "elDraggableDialog", rawName: "v-elDraggableDialog" },
          ],
          ref: "dialogRef",
          attrs: { visible: _vm.visible, title: _vm.titleName },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              _vm.hasFooter
                ? {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small" },
                            on: { click: _vm.close },
                          },
                          [_vm._v("取 消")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.handlerSubmit },
                          },
                          [_vm._v("确 定")]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }