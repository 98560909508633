export default {
  // 绑定元素时调用
  bind(el, binding) {
    const formatNumber = (num, unit) => {
      if (num == null || num === '' || num === undefined || isNaN(num)) {
        return '0' + ' ' + unit; // 处理 null、空字符串、undefined 和 NaN 的情况
      }
      num = Number(num); // 确保数字类型
      if (num >= 100000000) {
        return (num / 100000000).toFixed(2) + ' 亿';
      } else if (num >= 10000) {
        return (num / 10000).toFixed(2) + ' 万';
      } else {
        return num.toLocaleString()+' 元'; // 小于 10000 的数使用千分位格式
      }
    };

    const defaultUnit = binding.arg || ' 元'; // 获取传递的单位，默认是“元”

    // 设置元素的文本内容
    el.innerHTML = formatNumber(binding.value, defaultUnit);

    // 如果绑定的值发生变化，更新格式化后的值
    el.__update = newVal => {
      el.innerHTML = formatNumber(newVal, defaultUnit);
    };
  },
  update(el, binding) {
    const defaultUnit = binding.arg || ' 元'; // 获取传递的单位
    el.__update(binding.value, defaultUnit);
  },
  unbind(el) {
    delete el.__update;
  }
};

// <!-- 传递 '元' 作为单位 -->
// <p v-format-number:元="number">0</p>

// <!-- 传递 '美元' 作为单位 -->
// <p v-format-number:美元="numberInUSD">0</p>

// <!-- 传递 '斤' 作为单位 -->
// <p v-format-number:斤="weight">0</p>
