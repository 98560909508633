var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex" },
    [
      _c("span", [_vm._v(_vm._s(_vm.config.https))]),
      _c("el-input", {
        attrs: { size: "small", clearable: "", placeholder: "请输入域名" },
        model: {
          value: _vm.getVal,
          callback: function ($$v) {
            _vm.getVal = $$v
          },
          expression: "getVal",
        },
      }),
      _c("span", [_vm._v(_vm._s(_vm.config.domains))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }