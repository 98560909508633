var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex", attrs: { title: _vm.tips } },
    [
      !_vm.isShowTip
        ? _c(
            "span",
            {
              staticClass:
                "flex_1 overflow-hidden font_14 color-black ellipsis-0",
            },
            [
              _vm._v(_vm._s(_vm.tips || "-") + " "),
              _vm.isShowDetail
                ? _c("i", {
                    staticClass: "el-icon-view icon-view",
                    on: { click: _vm.handlerView },
                  })
                : _vm._e(),
            ]
          )
        : [
            _vm.isImg
              ? _c(
                  "div",
                  [
                    _vm.tips
                      ? _c("el-image", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { fit: "fill", src: _vm.tips },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "el-link",
                  _vm._b(
                    { on: { click: _vm.handlerClick } },
                    "el-link",
                    _vm.$attrs,
                    false
                  ),
                  [_vm._v(_vm._s(_vm.tips))]
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }