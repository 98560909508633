export const inventoryStatusEnums = [
  {
    value: '正常',
    key: 'Y'
  },
  {
    value: '告警',
    key: 'N'
  }
];

export const deviceEnums = [
  {
    key: '有线',
    value: '有线'
  },
  {
    key: '无线',
    value: '无线'
  }
]

export const weekEnums = [
  {
    label: '周一',
    value: 1,
  },
  {
    label: '周二',
    value: 2,
  },
  {
    label: '周三',
    value: 3,
  },
  {
    label: '周四',
    value: 4,
  },
  {
    label: '周五',
    value: 5,
  },
  {
    label: '周六',
    value: 6,
  },
  {
    label: '周日',
    value: 7,
  },
];

export const prerangeEnums = [
  {
    label: '上周',
    value: 1,
  },
  {
    label: '上上周',
    value: 2,
  },
];

export const product = [
  {
    value: '畅捷签',
    key: '1'
  },
  {
    value: '蜜蜂租赁',
    key: '2'
  },
  {
    value: '叮当车务',
    key: '3'
  },
  {
    value: '助贷系统',
    key: '4'
  }
];

export const typeList = [
  {
    value: '试用',
    key: 'TRIAL'
  },
  {
    value: '正式',
    key: 'OFFICIAL'
  },
  {
    value: '续费',
    key: 'RENEWAL'
  }
];
export const typeListShow = [
  ...typeList,
  {
    value: '已购买',
    key: 'PURCHASED'
  }
];

export const fromList = [
  {
    value: '线上',
    key: 'ONLINE'
  },
  {
    value: '线下',
    key: 'OFFLINE'
  }
];

export const useStatus = [
  {
    value: '正常',
    key: 'NORMAL'
  },
  {
    value: '停用',
    key: 'DISABLED'
  },
  {
    value: '过期',
    key: 'EXPIRED'
  },
  {
    value: '待激活',
    key: 'ACTIVATING'
  }
];

export const diningStatus = [
  {
    value: '关闭',
    key: '0'
  },
  {
    value: '空闲',
    key: '1'
  },
  {
    value: '已预定',
    key: '2'
  },
  {
    value: '就餐中',
    key: '3'
  }
];

export const statusEmus = [
  {
    value: '禁用',
    key: 'N'
  },
  {
    value: '启用',
    key: 'Y'
  }
];

export const statusTypeEmus = [
  {
    value: '正常',
    key: 'Y'
  },
  {
    value: '停用',
    key: 'N'
  }
];

export const companyScaleEmus = [
  {
    value: '0-20人',
    key: '0-20人'
  },
  {
    value: '20-50人',
    key: '20-50人'
  },
  {
    value: '50-200人',
    key: '50-200人'
  },
  {
    value: '200-500人',
    key: '200-500人'
  },
  {
    value: '500人以上',
    key: '500人以上'
  }
];

export const companyTypeEmus = [
  {
    value: '银行',
    key: '银行'
  },
  {
    value: '小贷公司',
    key: '小贷公司'
  },
  {
    value: '投资公司',
    key: '投资公司'
  },
  {
    value: '金融租赁公司',
    key: '金融租赁公司'
  },
  {
    value: '商业租赁公司',
    key: '商业租赁公司'
  },
  {
    value: '汽车金融公司',
    key: '汽车金融公司'
  },
  {
    value: '贷款中介公司',
    key: '贷款中介公司'
  },
  {
    value: '其他',
    key: '其他'
  }
];

export const whetherEmus = [
  {
    value: '是',
    key: 'Y'
  },
  {
    value: '否',
    key: 'N'
  }
];

export const ExamineList = [
  {
    key: "Y",
    value: "通过"
  },
  {
    key: "N",
    value: "驳回"
  },
]
export const timeoutStatusList = [
  {
    key: "Y",
    value: "已处理"
  },
  {
    key: "N",
    value: "未处理"
  },
]
export const fileStatusList = [
  {
    key: "Y",
    value: "已归档"
  },
  {
    key: "N",
    value: "未归档"
  },
]

export const ZSResultCode = [
  ['000000', '交易成功'],
  ['100001', '参数非法'],
  ['100003', '业务校验失败'],
  ['100007', '业务校验拒绝'],
  ['100023', '借款申请文件不全'],
  ['999999', '借款申请文件不全'],
]
export const ZSApplyCode = [
  ['000000', '交易成功'],
  ['100001', '参数非法'],
  ['100015', '业务校验异常'],
  ['100023', '借款申请文件不全'],
  ['300001', '放款失败'],
  ['300005', '放款处理中'],
  ['300006', '放款拒绝，额度失效，需重新进件'],
  ['999999', '借款申请文件不全'],
]
