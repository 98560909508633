import Vue from 'vue';

export default {
  bind(el, binding, Vnode) {
    const { value, arg } = binding;
    const [confrim, cancel] = /:/g.test(arg) ? arg.split(':') : [arg]
    el.onclick = () => {
      Vue.prototype.$confirm(value, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Vnode.context[confrim] && Vnode.context[confrim]();
      }).catch(() => {
        Vnode.context[cancel] && Vnode.context[cancel]()
      });
    }
  }
}
