/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-07-10 18:03:37
 * @LastEditTime: 2024-07-16 20:34:54
 * @LastEditors: Ran junlin
 */
/**
 * 可拖动弹窗
 * @description 使用 v-dialogDrag 调用指令；
 */

import { nextTick } from 'vue';
export default {
    bind (el, binding) {
        nextTick(() => {
            const { arg } = binding;
          console.log(arg,'arg');
            if (!arg) return;
            const dialogHeaderEl = document.querySelector('.dialog-header');
            const dragDom = document.querySelector('.customer-dialog');
            if (!dialogHeaderEl || !dragDom) {
                return console.log('dom不存在');
            }
            dialogHeaderEl.style.cursor = 'move';

            // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
            const sty =
                dragDom.currentStyle || window.getComputedStyle(dragDom, null);

            dialogHeaderEl.onmousedown = e => {
                // 鼠标按下，计算当前元素距离可视区的距离
                const disX = e.clientX - dialogHeaderEl.offsetLeft;
                const disY = e.clientY - dialogHeaderEl.offsetTop;

                // 获取到的值带px 正则匹配替换
                let styL, styT;

                // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
                if (sty.left.includes('%')) {
                    styL =
                        +document.body.clientWidth *
                        (+sty.left.replace(/\%/g, '') / 100);
                    styT =
                        +document.body.clientHeight *
                        (+sty.top.replace(/\%/g, '') / 100);
                } else {
                    styL = +sty.left.replace(/\px/g, '');
                    styT = +sty.top.replace(/\px/g, '');
                }

                document.onmousemove = function (e) {
                    // 通过事件委托，计算移动的距离
                    const l = e.clientX - disX;
                    const t = e.clientY - disY;

                    // 移动当前元素
                    dragDom.style.left = `${l + styL}px`;
                    dragDom.style.top = `${t + styT}px`;

                    //将此时的位置传出去
                    //binding.value({x:e.pageX,y:e.pageY})
                };

                document.onmouseup = function (e) {
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
            };
        });
    }
};
