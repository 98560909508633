import Template from '_c/Template/index';
import Copy from '_c/Copy';
import FilterBox from '_c/FilterBox';
import filters from '../filters/filter';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import { RTable, RSelect, RSelectLoadMore } from './globRenderCom';
import SplitDownloadAndExport from '_c/bussinseeComponents/splitDownloadAndExport.vue';
import ModalDrag from '_c/ModalDrag.vue';
import CustomForm from '@/components/CustomForm/index';
import DynamicForm from './DynamicForm.vue';

import {
  submenu,
  menuItem,
  menu,
  colorPicker,
  CollapseItem,
  Collapse,
  TimelineItem,
  Timeline,
  InputNumber,
  Cascader,
  Steps,
  Step,
  Rate,
  Badge,
  Pagination,
  Progress,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Radio,
  RadioGroup,
  Dialog,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Option,
  OptionGroup,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  RadioButton,
  DropdownItem,
  DropdownMenu,
  Drawer,
  Tooltip,
  Tag,
  Dropdown,
  Form,
  FormItem,
  Loading,
  Tree,
  Card,
  InfiniteScroll,
  Result,
  Image,
  Upload,
  Popover,
  Switch,
  Transfer,
  Carousel,
  carouselItem,
  Empty,
  Link,
  Popconfirm,
  Descriptions,
  DescriptionsItem,
  Alert,
  Row,
  col,
  timeSelect,
  skeleton,
  skeletonItem,
  Main,
  Container,
  Aside,
	Divider
} from 'element-ui';
import watermark from '@/utils/watermark';

// ElementUI 取消弹窗点击遮罩和按 Esc 关闭
Dialog.props.closeOnClickModal.default = false;
Dialog.props.closeOnPressEscape.default = false;

Drawer.props.direction.default = 'rtl';

// 弹窗添加水印
Drawer.mixins = [
  ...Drawer.mixins,
  {
    props: {
      // 是否需要展示水印
      watermask: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      // 挂载完成时，添加水印
      if (this.watermask) {
        watermark(this.$refs.drawer);
      }
    },
  },
];

// 弹窗添加水印
Dialog.mixins = [
  ...Dialog.mixins,
  {
    props: {
      // 是否需要展示水印
      watermask: {
        type: Boolean,
        default: true,
      },
    },
    mounted() {
      // 挂载完成时，添加水印
      if (this.watermask) {
        watermark(this.$refs.dialog);
      }
    },
  },
];

export default {
  submenu,
  RTable,
  RSelect,
  Template,
  FilterBox,
  Copy,
  filters,
  Rate,
  Badge,
  Pagination,
  Progress,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  Radio,
  RadioGroup,
  Dialog,
  Button,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Option,
  OptionGroup,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  RadioButton,
  DropdownItem,
  DropdownMenu,
  Drawer,
  Tooltip,
  Tag,
  Dropdown,
  Form,
  FormItem,
  Loading,
  Tree,
  Card,
  InfiniteScroll,
  CollapseTransition,
  Result,
  Image,
  Upload,
  Popover,
  Step,
  Steps,
  Cascader,
  InputNumber,
  Switch,
  TimelineItem,
  Timeline,
  CollapseItem,
  Collapse,
  colorPicker,
  menu,
  menuItem,
  Transfer,
  Carousel,
  carouselItem,
  Empty,
  Link,
  Popconfirm,
  Descriptions,
  DescriptionsItem,
  Alert,
  Row,
  col,
  timeSelect,
  SplitDownloadAndExport,
  skeleton,
  skeletonItem,
  RSelectLoadMore,
  Main,
  Container,
  Aside,
  ModalDrag,
  CustomForm,
	Divider,
	DynamicForm
};
