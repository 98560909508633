import storage from '@/utils/storage';
import * as http from '@/http';

import ENUM_LIST from '@/api/enum';
import { plateCityEnums, getAllOrgList, getSingleEnumsData } from '@/api/home/index';
import { msg } from '@/utils/common';
const ENUM_KEY = 'ENUM_CACHE';

const ENUM_URLS = ENUM_LIST;

const _enums = storage.getSession(ENUM_KEY) || {};

Object.keys(ENUM_URLS).forEach(key => {
  _enums[key] = _enums[key] || [];
});

/**
 * 静态枚举值
 */
Object.assign(_enums, {
  // yesNoEnum: [
  //     {
  //         value: '1',
  //         displayName: '是'
  //     },
  //     {
  //         value: '0',
  //         displayName: '否'
  //     }
  // ],
});

const state = {
  enums: _enums,
  plateCity: [],
  orgList: []
};

const mutations = {
  SET_ENUMS() {
    state.enums = {
      ..._enums
    };
    storage.setSession(ENUM_KEY, _enums);
  },
  SET_PLATE_CITY(state, payLoad) {
    state.plateCity = payLoad;
  },
  SET_ORG_LIST(state, payLoad) {
    state.orgList = payLoad;
  }
};

const actions = {
  async fetchEnums({ commit }, enumName) {
    if (!enumName || !enumName.length) {
      return;
    }
    if (Array.isArray(enumName[0])) {
      enumName = enumName[0];
    }
    return await Promise.all(
      enumName.map(async key => {
        if (_enums[key] && _enums[key].length) {
          return _enums[key];
        }
        const url = ENUM_URLS[key];
        if (!url) {
          return;
        }
        const res = await http.get(url);
        _enums[key] = res.data;
        commit('SET_ENUMS');
        return res.data;
      })
    );
  },
  async fetchPlateCity({ commit }, payload) {
    try {
      const data = await plateCityEnums();
      if (data) {
        const list = data.map(res => {
          return {
            ...res,
            childList: res.childList.map(item => {
              return {
                ...item,
                childList: null
              };
            })
          };
        });
        commit('SET_PLATE_CITY', list);
      }
    } catch (e) {
      msg({
        type: 'error',
        content: e
      });
    }
  },
  async fetchOrgList({ commit }) {
    try {
      const data = await getAllOrgList();
      if (data) {
        commit('SET_ORG_LIST', data);
      }
    } catch (e) {
      msg({
        type: 'error',
        content: e
      });
    }
  },
  //查询单个枚举
  async fetchEnumSingle({ commit }, code = 'FlowChildNodeEnum') {
    try {
      const data = await getSingleEnumsData(code);
      if (data) {
        return data;
        console.log(data, 'data');
      }
    } catch (e) {
      msg({
        type: 'error',
        content: e
      });
    }
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
