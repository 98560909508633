var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pos_rel" },
    [
      _c("vue-ueditor-wrap", {
        attrs: {
          value: _vm.value,
          destroy: true,
          config: {
            ..._vm.myConfig,
            wordCount: true,
            wordCountMsg: `<span style='color:#999'>最多${_vm.maximumWords}个字符</span>`,
          },
        },
        on: {
          input: function ($event) {
            return _vm.$emit("input", $event)
          },
          ready: (v) => (_vm.ue = v),
          beforeInit: _vm.addCustomButtom,
        },
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.disabled,
            expression: "disabled",
          },
        ],
        staticClass:
          "pos_abs l_0 t_0 z_9999 w_100_per h_100_per bg_col_rgba_255_255_255_50_per",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }