var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { visible: _vm.value, title: _vm.title, width: "240px" },
      on: {
        "update:visible": function ($event) {
          _vm.value = $event
        },
        closed: _vm.closed,
      },
    },
    [
      _c(
        "el-menu",
        { on: { select: _vm.menuSelect } },
        _vm._l(_vm.enums, function (row, index) {
          return _c("el-menu-item", { key: index, attrs: { index: row.key } }, [
            _c("span", [_vm._v(_vm._s(row.value))]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }