const onblur = (el, binding, vnode) => {
  let { modifiers } = binding;
  let { decimal, append } = modifiers;
  let ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
  if (decimal && append) {
    let val = ele.value;
    if (val === '') {
      return;
    }
    let decimalZero = '';
    for (let index = 0; index < decimalLength; index++) {
      decimalZero += '0';
    }
    if (!/\./.test(val)) {
      val = `${val}.`;
    }
    val = `${val}${decimalZero}`;

    val = val.replace(new RegExp(`(\\.\\d{1,${decimalLength}})(.*)`, 'g'), '$1');
    ele.value = val;
    if (vnode && vnode.componentInstance && vnode.componentInstance.$emit) {
      vnode.componentInstance.$emit('input', val);
    }
  }
  // 设置了最小值之后输入之后设置为最小值
  let valNum = ele.value;
  const minValue = ele.min;
  if (minValue && +valNum < minValue) {
    valNum = minValue;
    if (vnode && vnode.componentInstance && vnode.componentInstance.$emit) {
      vnode.componentInstance.$emit('input', valNum);
    }
  }
};

const onInputHandler = (el, binding, vnode, isInputZh) => {
  if (isInputZh) {
    return;
  }
  let ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
  let { modifiers, value } = binding;
  let { decimal, negative } = modifiers;
  let { integerLength, decimalLength } = value || {};
  if (typeof decimalLength === 'undefined') {
    decimalLength = 2;
  }
  if (typeof integerLength === 'undefined') {
    integerLength = Number.MAX_SAFE_INTEGER;
  }
  const maxValue = ele.max;
  const minValue = ele.min;
  let val = ele.value || '';
  if (val.replace) {
    val = val.replace(/[^\d.-]/g, '');
  }

  // 如果超过两个长度，但是第一个为0，第二个不为小数点，丢弃0
  if (val.length > 1 && val[0] === '0' && val[1] !== '.') {
    val = val.slice(1);
  }
  // 设置最大值
  if (maxValue && +val > maxValue) {
    val = maxValue;
  }
  // 设置最小值 解决不能删除得问题
  // if (minValue && +val < minValue) {
  //   val = minValue;
  // }

  // 整数位长度
  if (val.replace) {
    val = val.replace(new RegExp(`^(-?)(\\d{1,${integerLength}})(\\d*)(\\.?)`, 'g'), '$1$2$4');
  }

  if (negative) {
    //TODO 删除多余负号
  } else {
    if (val.replace) {
      val = val.replace(/-/g, '');
    }
  }
  if (decimal) {
    if (val.replace) {
      val = val.replace(new RegExp(`(\\.\\d{1,${decimalLength}})(.*)`, 'g'), '$1');
      // 连续两个'.', 只保留第一个'.'
      val = val.replace(/\.{2,}/g, '.');
      // 隔着字符, 也保证只有一个'.'
      val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 第一位不能以'.'开头
      val = val.replace(/^\.+/g, '');
    }
  } else {
    if (val.replace) {
      val = val.replace(/\.\d*/g, '');
    }
  }
  ele.value = val;
  if (vnode && vnode.componentInstance && vnode.componentInstance.$emit) {
    vnode.componentInstance.$emit('input', val);
  }
};

/**
 * 金额输入 目前只能用于 el-input
 * @description 使用 v-amount 调用指令；
 */
export default {
  inserted() {},
  bind: async (el, binding, vnode) => {
    // 避免v-if导致的el获取不到
    await vnode.context.$nextTick();
    let ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
    // 输入法处理
    var isInputZh = false;
    ele.addEventListener(
      'compositionstart',
      function () {
        isInputZh = true;
      },
      false
    );
    ele.addEventListener(
      'compositionend',
      function () {
        isInputZh = false;
        onInputHandler(el, binding, vnode, isInputZh);
      },
      false
    );

    ele.oninput = function () {
      setTimeout(() => {
        onInputHandler(el, binding, vnode, isInputZh);
      }, 0);
    };
    ele.onblur = function () {
      onblur(el, binding, vnode);
    };
  },
  unbind() {}
};
