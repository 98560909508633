<template lang="pug">
.data
  .data-container(v-if='!hideDataContainer')
    //- 左栏
    .left-box(v-if='isShowleft')
      slot(name='leftBox')

    //- 主体
    .right-box.data-body
      //- 自定义路由
      slot(name='nav')

      //- 头部
      slot(name='header')

      .frame
        //- 操作栏
        template(v-if='operationPosition == "top" && isShowOeration')
          Operation(
            :operationPosition.sync='operationPosition',
            :showOperationPadding='showOperationPadding',
            :showLayoutSetting='showLayoutSetting',
            :isCollapse='operationisCollapse',
            :showCollapse='showCollapse'
          )
            div(slot='oper-tit-attach')
              slot(name='oper-tit-attach')

            .operation(slot='operation')
              slot(name='operation')

        //- 列表
        .table-wrap(v-watermark='{ isWatermark:isWatermark }', ref='tablewrap', :class='{ "leftList": isShowleftList }')
          .left-box(v-if='isShowleftList')
            slot(name='leftList')
          slot(name='list')

        //- 分页
        .data-footer.margin-top.flex.cros-start(v-if='!hidePage')
          .sub.expand 共 {{ page.total }} 条记录，第 {{ page.pageIndex }} / {{ Math.ceil(page.total / page.pageSize) }} 页
          .sub
            el-pagination(
              background,
              layout='prev,pager,next,sizes,jumper',
              :current-page.sync='page.pageIndex',
              :page-sizes='page.pageSizes',
              :page-size='page.pageSize',
              @size-change='handlePageSizeChange',
              @current-change='handlePageNumChange',
              :total='page.total'
            )

  //- 抽屉
  el-drawer.drawer-waper(
    :class='{ "drawer-waper-nopadding": !showDrawPadding }',
    direction='rtl',
    :visible.sync='comPialogVisible',
    :wrapperClosable='false',
    :before-close='handleClose',
    :show-close='false',
    @closed='drawerClosed',
    :append-to-body='true',
    :modal="false"
    :modal-append-to-body='false',
    :size='size'
  )
    .el-drawer__title(slot='title')
      .header-left
        .header-name
          slot(name='allTitle')
            span {{ allTitle ? allTitle : status + title }}

      .header-right(@click='handleClose')
        i.el-icon-close

    .drawer__body(v-if='comPialogVisible && !$slots.detailWithoutWrap')
      slot(name='detail')
    .data-footer.margin-top.flex.cros-start(v-if='!hidePage')
          .sub.expand 共 {{ page.total }} 条记录，第 {{ page.pageIndex }} / {{ Math.ceil(page.total / page.pageSize) }} 页
          .sub
            el-pagination(
              background,
              layout='prev,pager,next,sizes,jumper',
              :current-page.sync='page.pageIndex',
              :page-sizes='page.pageSizes',
              :page-size='page.pageSize',
              @size-change='handlePageSizeChange',
              @current-change='handlePageNumChange',
              :total='page.total'
            )

    slot(name='detailWithoutWrap')
    .drawer__footer(v-if='showDrawerFooter')
      slot(name='headerRight')
      el-button(
        size='small',
        type='primary',
        v-if='showSaveBtn',
        :loading='comPending',
        @click='$parent.save()'
      ) {{saveBtnText}}
      el-button(v-if='showCancelBtn', size='small', @click='handleClose') 取消

//-
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Template',
  components: {
    Operation: () => import('./components/operation.vue'),
  },
  props: {
    saveBtnText: {
      type: String,
      default: '确 定',
    },
    // 默认新增或修改 **（如：新增设置或修改设置）
    title: {
      type: String,
      default: '详情',
    },
    // 需要替换的title（传入则忽略title）
    allTitle: {
      type: String,
      default: '',
    },
    // 是否显示取消按钮
    showCancelBtn: {
      type: Boolean,
      default: true,
    },
    // 是否显示保存按钮
    showSaveBtn: {
      type: Boolean,
      default: true,
    },
    // 控制弹出页面的宽度
    size: {
      type: [String, Number],
      default: 600,
    },
    // 是否显示左侧菜单栏
    isShowleft: {
      type: Boolean,
      default: false,
    },
    // 是否显示列表左侧
    isShowleftList: {
      type: Boolean,
      default: false,
    },
    // 是否显示分页
    hidePage: {
      type: Boolean,
      default: false,
    },
    hideDataContainer: {
      type: Boolean,
      default: false,
    },
    // 是否显示水印
    isWatermark: {
      type: Boolean,
      default: true,
    },
    // 是否布局设置
    showLayoutSetting: {
      type: Boolean,
      default: false,
    },
    // 是否显示收起功能
    showCollapse: {
      type: Boolean,
      default: false,
    },
    // 是否显示按钮组
    showDrawerFooter: {
      type: Boolean,
      default: true,
    },
    // 是否固定操作栏
    operationPostionFixed: {
      type: Boolean,
      default: false,
    },
    // 是否显示操作栏的左右padding
    showOperationPadding: {
      type: Boolean,
      default: false,
    },
    // 是否显示Draw的左右padding
    showDrawPadding: {
      type: Boolean,
      default: true,
    },
    // 操作栏标题
    operationTitle: {
      type: String,
      default: () => '',
    },
    // 是否显示辅助操作
    showAssistHandle: {
      type: Boolean,
      default: false,
    },
    // 是否显示辅助操作的列项设置
    showAssistHandleColSetting: {
      type: Boolean,
      default: true,
    },
    // 表格列项
    tableColumn: Object,
  },
  data() {
    return {
      operationisCollapse: false,
    };
  },
  computed: {
    ...mapState('userStore', ['userconfig']),
    currentSetting() {
      let settings = this.userconfig.modularSetting || [];
      let current = settings.find((item) => item.modular == 'home');
      let set = current?.settings || {};
      return set;
    },
    operationPosition() {
      // 固定在上方
      return 'top';
      // DEPRECATED 0.1.0
      /* if (this.currentSetting && !this.operationPostionFixed) {
        return this.currentSetting.position || 'bottom'
      }
      return 'bottom' */
    },
    // 提示： provide 和 inject 绑定并不是可响应的。这是刻意为之的。然而，如果你传入了一个可监听的对象，那么其对象的属性还是可响应的
    comPialogVisible() {
      return this.dialogVisible();
    },
    comPending() {
      return this.pending();
    },
    /* page() {
      return this.$parent.page;
    }, */
    status() {
      return this.comDetailInfo.id ? '修改' : '新增';
    },
    comDetailInfo() {
      return this.detailInfo();
    },
    isShowOeration() {
      return this.$slots?.operation?.length || false;
    },
  },
  provide() {
    return {
      operationTitle: this.$props.operationTitle,
      showAssistHandle: this.$props.showAssistHandle,
      showAssistHandleColSetting: this.$props.showAssistHandleColSetting,
    };
  },
  // 所有方法和参数由父组件的mixins传入
  inject: [
    'handlePageSizeChange',
    'handlePageNumChange',
    'dialogVisible',
    'handleClose',
    'drawerClosed',
    'pending',
    'page',
    'detailInfo',
  ],
};
</script>
<style lang="scss" scoped>
.body-content {
  display: flex;
}
.data {
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100%;
  display: flex;
  .data-container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex: 1;
    padding: 12px;
    padding-top: 0px;
    background-color: #f0f2f5;
    box-sizing: border-box;
  }
  .left-box {
    background: white;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    margin-right: 16px;
    /deep/.left-container {
      width: 250px;
      padding: 16px;
      background-color: #fff;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      max-height: calc(100vh - 134px);
    }
  }
  .right-box {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.drawer-waper {
  /deep/.el-drawer__header {
    padding: 16px 24px;
    line-height: 1;
  }

  /deep/.el-drawer {
    overflow: visible;
  }

  .el-drawer__title {
    font-size: 16px;
    align-items: center;
    .header-name {
      font-size: 16px !important;
      line-height: 24px;
    }
    .header-right {
      //   position: absolute;
      //   left: -56px;
      //   top: 0;
      //   width: 56px;
      //   height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      cursor: pointer;

      .el-icon-close {
        font-size: 18px;
      }
    }
  }
  .drawer__body {
    flex: 1;
    overflow: auto;
    padding: 24px;
    padding-bottom: 70px;
  }
  .drawer__footer {
    padding: 10px 24px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 10000;
    background-color: #fff;
  }
  &-nopadding {
    .drawer__body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  /deep/.el-drawer__body {
    padding: 0;
    overflow: hidden;
    position: relative;
    flex: 1;
    height: 100%;
    z-index: 2;
  }
}
.data-body {
  // padding: 16px;
  // border-radius: 2px;
  // box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.06);
  display: flex;
  height: 100%;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  .table-wrap {
    // max-height: 100%;
    // flex: auto;
    flex: 1;
    position: relative;
    // max-height: calc(100% - 102px); // FIXME:这个地方后期优化
    overflow: hidden;

    padding: 16px;
    background: #fff;
    border-radius: 2px;
    // box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.06);

    .el-table {
      flex: 1;
      max-height: calc(100% - 3px);
      // 修复重复y轴重复滚动条
      //overflow-y: auto;
      overflow: hidden;
    }
  }
  /deep/.el-pagination__jump {
    margin-left: 0;
  }
  /deep/.el-table {
    font-size: $fontSize;
  }

  .data-header {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
    padding: 16px;
    margin-bottom: 12px;
  }
}
.frame {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  overflow: hidden;

  .operation {
    display: flex;
  }

  .leftList {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .left-box {
      width: 200px;
      padding: 8px;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
      .left {
        height: 100%;
        display: flex;
        flex-direction: column;

        /deep/.list {
          flex: 1;
          overflow-y: auto;
        }
      }
    }
  }
}
.data-footer {
  background: #fff;
  padding: 0 16px 12px;
  // margin-top: 20px;
  margin-top: 0;
  border-radius: 0 0 2px 2px;
  position: relative;
}
</style>
