<!--
 * @Description:
 * @Author: Ran junlin
 * @Date: 2023-08-14 15:16:48
 * @LastEditTime: 2023-09-04 09:45:16
 * @LastEditors: Ran junlin
-->
<script>
import { Table, Pagination } from 'element-ui';
import Column from './RColumn.vue';
const { props: elTableProps } = Table;
const { props: elPaginationProps } = Pagination;
export default {
  components: { Table, Column, Pagination },
  name: 'RTable',
  props: {
    ...elPaginationProps,
    ...elTableProps,
    // 表格列配置，支持render写法
    column: {
      type: Array,
      default: () => []
    },
    // 表格源数据
    tableData: {
      type: Array,
      default: () => []
    },
    // 表的高度
    // height: {
    //   type: [Number, String],
    //   default: 500,
    // },
    // maxHeight: {
    //   type: [Number, String],
    //   default: 600,
    // },
    // 是否显示边框
    border: {
      type: Boolean,
      default: false
    },
    // 是否显示斑马纹
    stripe: {
      type: Boolean,
      default: false
    },
    // 表格的尺寸 可选值为 'medium', 'small', 'mini'。
    size: {
      type: String,
      default: 'medium'
    },
    // 是否显示表头
    showHeader: {
      type: Boolean,
      default: true
    },
    // 是否显示表尾
    showFooter: {
      type: Boolean,
      default: false
    },
    // 是否显示多选框
    selection: {
      type: Boolean,
      default: false
    },
    // 是否显示序号
    index: {
      type: Boolean,
      default: false
    },
    // 是否显示加载状态
    loading: {
      type: Boolean,
      default: false
    },
    //每行数据的唯一标识符，类型可以是字符串或者一个函数。
    rowKey: {
      type: [String, Function],
      default: 'id'
    },
    //控制列的显示与隐藏
    columnConfig: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    window.addEventListener('resize', this.doLayout);
  },
  methods: {
    doLayout() {
      if (this.$refs.tbl && this.$refs.tbl.doLayout) {
        this.$refs.tbl.doLayout();
      }
    }
  },
  render() {
    return (
      <Table
        {...{
          props: { ...this.$props, data: this.tableData }
        }}
        on={this.$listeners}
        ref="tbl"
      >
        {this.$slots.prefix}
        {Object.keys(this.$props.columnConfig).length > 0
          ? this.column.map(v => {
              return [this.$props.columnConfig[v.field].show ? <Column key={v.key} customerProps={v} /> : null];
            })
          : this.column.map(v => {
              return [<Column key={v.key} customerProps={v} />];
            })}
        {Object.keys(this.$slots).map(name => (
          <template slot={name}>{this.$slots[name]}</template>
        ))}
      </Table>
    );
  },
  destroyed() {
    window.removeEventListener('resize', this.doLayout);
  }
};
</script>
