var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "div",
        { key: index + "abc", staticClass: "flex flex-col color-black" },
        [
          _c("div", [_vm._v(_vm._s(item.requireName))]),
          _c("div", { domProps: { innerHTML: _vm._s(item.description) } }, [
            _vm._v(_vm._s(item.description)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }