/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-06 10:19:56
 * @LastEditTime: 2024-08-13 10:25:32
 * @LastEditors: Ran junlin
 */
import * as http from '@/http';

// 地区树
export function regionTree() {
  return http.get('/base/infra/region/tree').then(res => res.data);
}

//基础服务- 所有地区
export function getRegionRegion() {
  return http.get('/api/infra/region/region');
}

// 岗位权限获取
export const positionsAuthority = async (positionId) => {
  const url = http.buildServiceUrl('LOAN', `positions/${positionId}/authority`);
  const res = await http.get(url);
  if (!res || !res.data) return {};
  return res.data;
};

// 获取指定客户端下的菜单权限列表
export const GetAuthMenusOfClient = async () => {
  const res = await http.get(`/api/operation/merchant/authority/menus`);
  if (!res || !res.data) return [];
  return res.data;
};


/**
 * @description 根据租户编号获取租户信息
 */
export function getTenantCompany(tenantNo) {
  const url = http.buildServiceUrl('LOAN', `/tenantCompany/get/${tenantNo}`);
  return http.get(url);
}
