var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex gap-12" },
    [
      _c(
        "div",
        { staticStyle: { width: "252px" } },
        [
          _c("el-input", {
            attrs: {
              placeholder: "请输入进件流程文档链接",
              clearable: "",
              size: "small",
            },
            model: {
              value: _vm.fileUrl,
              callback: function ($$v) {
                _vm.fileUrl = $$v
              },
              expression: "fileUrl",
            },
          }),
        ],
        1
      ),
      _c(
        "el-upload",
        {
          attrs: {
            "show-file-list": false,
            accept: ".jpg,.jpeg,.png,.pdf",
            action: _vm.uploadUrl,
            "file-list": _vm.fileList,
            "on-success": _vm.handlerUploadSuccess,
          },
        },
        [
          _c("el-link", { attrs: { type: "primary" } }, [
            _vm._v(
              _vm._s(
                _vm.fileList && _vm.fileList.length
                  ? "重新上传"
                  : "+上传进件流程文件"
              )
            ),
          ]),
        ],
        1
      ),
      _vm.fileList && _vm.fileList.length
        ? _c(
            "el-link",
            { attrs: { type: "danger" }, on: { click: _vm.handlerDel } },
            [_vm._v("删除")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }