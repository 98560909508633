var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col relative h_304 m_b_12" },
    [
      _c(
        "div",
        {
          staticClass: "absolute",
          staticStyle: { top: "-40px", right: "5px" },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "custom-button",
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.addProductionDesc },
            },
            [_vm._v("+添加产品描述")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: "280px" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "requireName",
              "show-overflow-tooltip": "",
              label: "描述项",
              width: "140",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "description",
              "show-overflow-tooltip": "",
              label: "描述内容",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          row.description ? row.description : "-"
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "130", prop: "", label: "操作", fixed: "right" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row, $index }) {
                  return [
                    _c(
                      "el-link",
                      {
                        staticClass: "m_r_4",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onOperate(row, "edit", $index)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-link",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.onOperate(row, "del", $index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.visible
        ? _c("AddDescDialog", {
            attrs: { visible: _vm.visible, "title-name": _vm.titleName },
            on: {
              "update:visible": function ($event) {
                _vm.visible = $event
              },
              handlerSubmit: _vm.handlerSubmit,
              handlerUpdate: _vm.handlerUpdate,
            },
            model: {
              value: _vm.rowData,
              callback: function ($$v) {
                _vm.rowData = $$v
              },
              expression: "rowData",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }