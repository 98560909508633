/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-12 14:28:52
 * @LastEditTime: 2024-08-20 19:33:35
 * @LastEditors: Ran junlin
 */
import axios from 'axios';
import { getTenantNo, getToken } from '@/utils/app';
import _needRelogin from '@/http/interceptor/common/needRelogin';
import * as httpConfig from '@/http';
const White_List = [
  httpConfig.buildServiceUrl('OAUTH', 'authority/password-login'),
  httpConfig.buildServiceUrl('OAUTH', 'api/user/sendLoginSms') //   // '/oauth/hl/v1/api/user/sendLoginSms'
];

// 请求拦截器
axios.interceptors.request.use(
  config => {
    // 除指定接口外，其他接口需要携带有效的 token 信息
    // if (config.url.startsWith('/api') || config.url.startsWith('api'))
    const accessToken = getToken();
    const tenantNo = getTenantNo();
    if (tenantNo && !White_List.includes(config.url)) {
      config.headers['tenant-no'] = tenantNo;
    }
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Bearer
      config.headers.token = `Bearer ${accessToken}`;
    }
    config.headers['env-type'] = process.env.NODE_ENV
    config.headers['client'] = process.env.VUE_APP_AUTH_CLIENT_ID;
    config.headers['terminal'] = process.env.VUE_APP_AUTH_CLIENT_TERMINAL;
    // if (!accessToken && !White_List.includes(config.url)) {
    //   _needRelogin();
    //   throw 'needRelogin';
    // }

    // if (store.state && store.state.userStore && store.state.userStore.userInfo) { // 设置公司账户和总账户标识
    //   const {
    //     identification,
    //   } = store.state.userStore.userInfo
    //   config.headers.identification = identification;
    // }
    return config;
  },
  error => {
    if (error === 'needRelogin') {
      _needRelogin();
      return;
    }
    return Promise.reject(error);
  }
);
