/*
 * @Description:订单信息
 * @Author: Ran junlin
 * @Date: 2024-08-27 20:16:21
 * @LastEditTime: 2024-08-27 20:49:49
 * @LastEditors: Ran junlin
 */
import { orderTaskFlowTask } from '@/api/order/incoming.js';

import router from '@/router/index';
import { msg } from '@/utils/common';

export default {
  namespaced: true,
  state() {
    return {
      isOrderSuccess: false,
      orderInfo: {
        orderNo: '',
        vehicleNo: '',
        customerId: ''
      }, // 点击订单详情对象
      afterOrderInfo: {},
      // 是否已经提交资料补录
      isReplenish: false
    };
  },
  mutations: {
    setIsReplenish(state, data) {
      state.isReplenish = data;
    },
    setOrderInfo(state, data) {
      state.orderInfo = data;
    },
    removeOrderInfo(state) {
      state.orderInfo = {
        orderNo: '',
        vehicleNo: '',
        customerId: ''
      };
    },

    setAfterOrderInfo(state, data) {
      state.afterOrderInfo = data;
    },
    removeAfterOrderInfo(state) {
      state.afterOrderInfo = {};
    },
    //是否领单成功
    setOrderSuccess(state, data) {
      state.isOrderSuccess = data;
    }
  },
  actions: {
    //操作前，静默领单
    async orderTaskFlowTaskFetch({ commit }, data) {
      console.log(data, 'data--data--data');
      const { taskId, instanceId, flowStatus, queryParams } = data;

      try {
        if (flowStatus === 'PENDING') {
          const res = await orderTaskFlowTask({ instanceId, taskId });
          msg({
            type: 'success',
            content: '已成功为你领单'
          });
          console.log(res, '领单');

          commit('setOrderSuccess', true);
          commit('setOrderInfo', data);
          if (queryParams) {
            router.push({
              path: '/order/pre/detail',
              query: {
                taskOrder: 'task',
                id: queryParams.id,
                userId: queryParams.userId,
                carId: queryParams.carId
              }
            });
          } else {
            router.push({ name: 'OrderIncomingDetail' });
          }
        } else {
          commit('setOrderInfo', data);
          commit('setOrderSuccess', true);
          if (queryParams) {
            router.push({
              path: '/order/pre/detail',
              query: {
                taskOrder: 'task',
                id: queryParams.id,
                userId: queryParams.userId,
                carId: queryParams.carId
              }
            });
          } else {
            router.push({ name: 'OrderIncomingDetail' });
          }
        }
      } catch (error) {
        console.log(error);
        msg({
          type: 'error',
          content: error || '操作失败'
        });
        commit('setOrderSuccess', false);
      }
    },
    setOrderInfoByAction(context, data) {
      context.commit('setOrderInfo', data);
    },
    removeOrderInfoByAction(context) {
      context.commit('setOrderInfo');
    },

    setAfterOrderInfoByAction(context, data) {
      context.commit('setAfterOrderInfo', data);
    },
    removeAfterOrderInfoByAction(context) {
      context.commit('removeAfterOrderInfo');
    }
  }
};
