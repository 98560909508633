<template>
  <el-form
    class="search-form"
    v-if="fields.length"
    :model="form"
    size="small"
    inline
    :disabled="disabled"
  >
    <MobileHideButtonBox buttonLabel="展开搜索">
        <div class="searchBoxMobileFixed">
            <div class="left">
            <template v-for="(field, index) in highSearching
                ? renderFieldsHigh
                : renderFields">
                <template v-if="highSearching">
                <div
                    :key="index"
                    class="high-search-field"
                    v-if="highSearchingFormTemp[field.key]"
                >
                    <span>
                    {{
                        field.title ||
                        (
                        field.titleSetting.enums.find((it) => it.key == field.key) ||
                        {}
                        ).value
                    }}：
                    </span>

                    <!-- 文本类型 -->
                    <span v-if="
                        (!field.widget || field.widget === 'text') &&
                        highSearchingFormTemp[field.key]
                    ">{{ highSearchingFormTemp[field.key] }}</span>

                    <!-- 下拉框类型 -->
                    <span v-if="
                        field.widget === 'select' && highSearchingFormTemp[field.key]
                    ">
                    {{
                        Array.isArray(highSearchingFormTemp[field.key])
                        ? highSearchingFormTemp[field.key]
                            .map((item) => {
                                return field.enums.find((it) => it.key == item).value;
                            })
                            .join('、')
                        : field.enums.find(
                            (it) => it.key == highSearchingFormTemp[field.key]
                            ).value
                    }}
                    </span>

                    <!-- 日期区间 -->
                    <span v-if="
                        field.widget === 'datetime' && highSearchingFormTemp[field.key]
                    ">{{
                        highSearchingFormTemp[field.key]
                        .map((item) =>
                            item.substring(0, field.subString ? 10 : item.length)
                        )
                        .join(' 至 ')
                    }}</span>

                    <!-- 级联选择 -->
                    <span v-if="
                        field.widget === 'cascader' && highSearchingFormTemp[field.key]
                    ">{{
                        Array.isArray(highSearchingFormTemp[field.key])
                        ? highSearchingFormTemp[field.key]
                            .map((item) => {
                                return findCurNodeDeepFirst(
                                field.options,
                                item,
                                field.props.value || 'value',
                                field.props.children || 'children'
                                )[field.props.label || 'label'];
                            })
                            .join('-')
                        : findCurNodeDeepFirst(
                            field.options,
                            highSearchingFormTemp[field.key],
                            field.props.value || 'value',
                            field.props.children || 'children'
                            )[field.props.label || 'label']
                    }}</span>

                    <!-- 单个复选框 -->
                    <span v-if="
                        field.widget === 'checkbox' && highSearchingFormTemp[field.key]
                    ">{{ highSearchingFormTemp[field.key] ? '是' : '否' }}</span>
                    <!--  -->
                </div>
                </template>

                <template v-if="!highSearching && (!field.collapsed || !collapsed)">
                <div
                    v-if="field.titleSetting"
                    :key="field.key || index"
                    class="default-setting-select"
                >
                    <el-select
                    v-model="field.key"
                    size="small"
                    placeholder="请选择"
                    :disabled="openHighSearch"
                    :style="field.titleSetting.style"
                    @change="
                        (v) => {
                        field.key = v;
                        $forceUpdate();
                        }
                    "
                    >
                    <el-option
                        v-for="(item, index) in field.titleSetting.enums"
                        :key="index"
                        :label="item.value"
                        :value="item.key"
                    ></el-option>
                    </el-select>

                    <el-input
                    v-if="!field.widget || field.widget === 'text'"
                    class="m_l_neg_1"
                    v-model="form[field.key]"
                    size="small"
                    :maxlength="field.maxlength || 256"
                    :placeholder="getCurrentPlaceholder(field)"
                    :clearable="field.clearable"
                    :disabled="openHighSearch"
                    :style="field.style"
                    />
                </div>

                <el-form-item
                    v-else
                    class="defaultFormItem"
                    :key="(field.key || index) + 1"
                    :label="field.widget !== 'radio-group' ? field.title : ''"
                    :class="field.widget"
                >
                    <!-- 文本类型 -->
                    <template v-if="field.titleSetting"></template>
                    <template v-else>
                    <el-input
                        v-if="!field.widget || field.widget === 'text'"
                        v-model="form[field.key]"
                        size="small"
                        :class="field.class"
                        :maxlength="field.maxlength || 256"
                        :placeholder="field.placeholder"
                        :style="field.style"
                        :clearable="field.clearable"
                        :disabled="openHighSearch"
                    />
                    </template>

                    <!-- 下拉框类型 -->
                    <el-select
                    v-if="field.widget === 'select'"
                    v-model="form[field.key]"
                    :placeholder="field.placeholder"
                    :multiple="field.multiple"
                    :collapse-tags="field.collapseTags"
                    :style="field.style"
                    size="small"
                    :class="field.class"
                    @change="(v) => {(field.onChange ? field.onChange(v) : null);$emit('select-change', v,field.key,form)}"
                    :clearable="field.clearable"
                    :filterable="field.filterable"
                    :disabled="openHighSearch"
                    :remote="field.remote"
                    :remote-method="field.remoteMethod"
                    >
                    <template v-if="Array.isArray(field.enums) && field.enums.length">
                        <el-option
                        v-for="(item, fieldIndex) in field.enums"
                        :key="fieldIndex"
                        :label="item.value || item"
                        :value="item.key"
                        />
                    </template>
                    </el-select>

                    <!-- 日期区间 -->
                    <el-date-picker
                    v-if="field.widget === 'datetime'"
                    :type="field.type || 'date'"
                    v-model="form[field.key]"
                    :placeholder="field.placeholder"
                    :style="field.style"
                    size="small"
                    :class="field.class"
                    :value-format="field.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
                    :range-separator="field.rangeSeparator || '至'"
                    :start-placeholder="field.startPlaceholder || '开始日期'"
                    :end-placeholder="field.endPlaceholder || '结束日期'"
                    :format="field.format || 'yyyy-MM-dd HH:mm:ss'"
                    @change="
                        (data) => (field.onChange ? field.onChange : voidFunction)(data)
                    "
                    :clearable="field.clearable"
                    :default-time="field.defaultTime || ['00:00:00', '23:59:59']"
                    :disabled="openHighSearch"
                    />

                    <!-- 级联选择 -->
                    <el-cascader
                    v-if="field.widget === 'cascader'"
                    v-model="form[field.key]"
                    :placeholder="field.placeholder"
                    :options="field.options"
                    :props="field.props"
										:collapse-tags="field.collapseTags"
                    :separator="field.separator"
                    :show-all-levels="field.showAllLevels"
                    :clearable="field.clearable"
                    :filterable="field.filterable ? field.filterable : false"
                    :disabled="openHighSearch"
                    :class="field.class"
                    :style="field.style"
                    />

                    <!-- 单个复选框 -->
                    <el-checkbox
                    v-if="field.widget === 'checkbox'"
                    v-model="form[field.key]"
                    :true-label="field.trueLabel"
                    :disabled="openHighSearch"
                    >{{ field.title }}</el-checkbox>
                    <!-- 按钮 -->
                    <div
                    class="btn"
                    :style="{
                        color: active == 1 ? '#0F5FD6' : '',
                        border: active == 1 ? '1px solid #0F5FD6' : '',
                    }"
                    v-if="field.widget === 'button'"
                    @click="quality()"
                    >
                    质检
                    </div>
                    <div
                    :style="{
                        color: active == 2 ? '#0F5FD6' : '',
                        border: active == 2 ? '1px solid #0F5FD6' : '',
                    }"
                    class="btn"
                    v-if="field.widget === 'button1'"
                    @click="sendOrders()"
                    >
                    派单
                    </div>
                    <div v-if="field.widget === 'radio-group'">
                    <span>{{field.title}}: </span>
                    <el-radio-group size="mini" v-model="form[field.key]">
                        <template v-if="Array.isArray(field.enums) && field.enums.length">
                        <template v-for="(item, fieldIndex) in field.enums">
                            <el-tooltip
                            class="item"
                            effect="dark"
                            :key="fieldIndex"
                            :content="item.tooltip"
                            placement="top-start"
                            v-if="item.tooltip"
                            >
                            <el-radio-button :label="item.key">
                                <span class="radio-group-item-label">{{ item.label }}</span>

                                <el-badge v-if="item.num" :value="item.num" :max="99" class="item"> </el-badge>
                            </el-radio-button>
                            </el-tooltip>
                            <el-radio-button :label="item.key" v-else :key="fieldIndex">
                            <span class="radio-group-item-label">{{ item.label }}</span>

                            <el-badge v-if="item.num" :value="item.num" :max="99" class="item"> </el-badge>
                            </el-radio-button>
                        </template>
                        </template>
                    </el-radio-group>
                    </div>

                    <!-- 下拉多选 -->
                    <div v-if="field.widget === 'select-more'">
                    <RSelectLoadMore
                        key="associatedOrder"
                        :style="field.style?field.style:'width: 100%'"
                        v-model="form[field.key]"
                                        v-bind="{...field}"
                    />
                    </div>
                    <!--  -->
                </el-form-item>

                <!--  -->
                </template>
            </template>
            <slot></slot>
            <template>
                <slot name="other-tem"></slot>
            </template>
            <div
                v-if="
                highSearching &&
                !renderFieldsHigh.filter((it) => highSearchingFormTemp[it.key]).length
                "
                class="high-search-empty"
            >
                未设置高级筛选条件
            </div>
            </div>
            <div class="rihgt">
            <el-button
                v-if="!highSearching && canReset"
                type="default"
                size="small"
                @click="handleFilterReset"
            >清空</el-button>
            <!-- <el-button
                v-if="highSearching" -->
            <!-- <el-button
                v-if="openHighSearch"
                type="default"
                size="small"
                @click="
                openHighSearch = false;
                highSearching = false;
                active = 0;
                highSearchingForm = {};
                handleFilterSubmit();
                "
            >重置</el-button> -->
            <el-button
                type="primary"
                size="small"
                @click="handleFilterSubmit()"
            >搜索</el-button>
            <el-button
                v-if="hasCollapser"
                class="padding-x"
                type="text"
                size="small"
                @click="handleFilterCollapse"
            >{{ collapsed ? '展开' : '收起' }}</el-button>

            <!-- 高级搜索 -->
            <el-popover
                v-if="fieldsHigh.length"
                v-model="openHighSearch"
                placement="bottom"
                trigger="manual"
                :width="360"
                :teleported="false"
                :offset="-126"
            >
                <el-badge
                slot="reference"
                :hidden="!(highSearching && !openHighSearch)"
                is-dot
                >
                <div
                    class="high-search-button"
                    @click="openHighSearch = !openHighSearch"
                >
                    <span>高级搜索</span>
                    <div class="icon">
                    <img src="https://dingdang-prod.oss-cn-shanghai.aliyuncs.com/car-agent/20230113/gwPWRtzSPVQvzvxtJeLU.svg" />
                    </div>
                </div>
                </el-badge>

                <div class="high-search-popover">
                <div class="title">高级搜索</div>

                <div class="content">
                    <template>
                    <div
                        v-for="(field, index) in renderFieldsHigh"
                        :key="index"
                    >
                        <el-form-item
                        :key="field.key || index"
                        :label="field.title"
                        :class="field.widget"
                        :label-width="field.labelWidth || '68px'"
                        >
                        <!-- 文本类型 -->
                        <template v-if="field.titleSetting">
                            <div class="title-setting">
                            <el-select
                                v-model="field.key"
                                placeholder="请选择"
                                :style="`flex: none;${field.titleSetting.style}`"
                                @change="
                                (v) => {
                                    field.key = v;
                                    $forceUpdate();
                                }
                                "
                            >
                                <el-option
                                v-for="(item, index) in field.titleSetting.enums"
                                :key="index"
                                :label="item.value"
                                :value="item.key"
                                ></el-option>
                            </el-select>

                            <el-input
                                v-if="!field.widget || field.widget === 'text'"
                                class="m_l_neg_1"
                                v-model="highSearchingForm[field.key]"
                                size="small"
                                :maxlength="field.maxlength || 32"
                                :placeholder="field.placeholder"
                                :style="field.style"
                                :clearable="field.clearable"
                            />
                            </div>
                        </template>
                        <template v-else>
                            <el-input
                            v-if="!field.widget || field.widget === 'text'"
                            v-model="highSearchingForm[field.key]"
                            size="small"
                            :maxlength="field.maxlength || 32"
                            :placeholder="field.placeholder"
                            :style="field.style"
                            :clearable="field.clearable"
                            />
                        </template>

                        <!-- 下拉框类型 -->
                        <el-select
                            v-if="field.widget === 'select'"
                            v-model="highSearchingForm[field.key]"
                            :placeholder="field.placeholder"
                            :multiple="field.multiple"
                            :collapse-tags="field.collapseTags"
                            :style="field.style"
                            size="small"
                            @change="(v) => (field.onChange ? field.onChange(v) : null)"
                            :clearable="field.clearable"
                            :filterable="field.filterable"
                            :remote="field.remote"
                            :remote-method="field.remoteMethod"
                        >
                            <template v-if="Array.isArray(field.enums) && field.enums.length">
                            <el-option
                                v-for="(item, fieldIndex) in field.enums"
                                :key="fieldIndex"
                                :label="item.value || item"
                                :value="item.key || item"
                            />
                            </template>
                        </el-select>

                        <!-- 日期区间 -->
                        <el-date-picker
                            v-if="field.widget === 'datetime'"
                            :type="field.type || 'date'"
                            v-model="highSearchingForm[field.key]"
                            :placeholder="field.placeholder"
                            :style="field.style"
                            size="small"
                            :value-format="field.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
                            :range-separator="field.rangeSeparator || '至'"
                            :start-placeholder="field.startPlaceholder || '开始日期'"
                            :end-placeholder="field.endPlaceholder || '结束日期'"
                            @change="
                            (data) =>
                                (field.onChange ? field.onChange : voidFunction)(data)
                            "
                            :clearable="field.clearable"
                            :default-time="
                            field.defaultTime || ['00:00:00', '23:59:59']
                            "
                        />
                        <!-- 级联选择 -->
                        <el-cascader
                            v-if="field.widget === 'cascader'"
                            v-model="highSearchingForm[field.key]"
                            :placeholder="field.placeholder"
                            :options="field.options"
														:collapse-tags="field.collapseTags"
                            :props="field.props"
                            :separator="field.separator"
                            :show-all-levels="field.showAllLevels"
                            :clearable="field.clearable"
                            :filterable="field.filterable ? field.filterable : false"
                            style="width: 100%"
                        />

                        <!-- 单个复选框 -->
                        <el-checkbox
                            v-if="field.widget === 'checkbox'"
                            v-model="highSearchingForm[field.key]"
                            :true-label="field.trueLabel"
                        >{{ field.title }}</el-checkbox>

                        <!--  -->
                        </el-form-item>
                    </div>
                    </template>

                    <!--  -->
                </div>

                <div class="handle">
                    <div class="close">
                    <el-button
                        type="text"
                        size="small"
                        plain
                        @click.stop="openHighSearch = false"
                    >
                        收起
                        <i class="el-icon-arrow-up el-icon--right"></i>
                    </el-button>
                    </div>

                    <el-button
                    type="default"
                    size="small"
                    plain
                    @click="
                        //openHighSearch = false;
                        //highSearching = false;
                        active = 0;
                        openHighSearch = false;
                        highSearching = false;
                        highSearchingForm = {};
                        handleFilterReset()
                        handleFilterSubmit(true);
                    "
                    >重置</el-button>

                    <el-button
                    type="primary"
                    size="small"
                    @click="
                        () => {
                        active = 0;
                        highSearching = true;
                        highSearchingForm;
                        handleFilterSubmit(true);
                        }
                    "
                    >搜索</el-button>

                    <!--  -->
                </div>

                <!--  -->
                </div>

                <!--  -->
            </el-popover>

            <!--  -->
            </div>
            </div>
    </MobileHideButtonBox>
    
  </el-form>
</template>

<script>
// import { slogan } from '@/config/_app';
import { findCurNodeDeepFirst } from '@/utils/tools';
import RSelectLoadMore  from '@/components/RenderSelectLoadmore'
import MobileHideButtonBox from '@/components/bussinseeComponents/MobileHideButtonBox.vue'
/**
 * 字段基础信息
const FIELD_DEFAULT = {
  // 标题
  title: null,

  // 取值
  key: null,

  // 占位提示
  placeholder: null,

  // 字段组件类型，取值范围
  // text 文本输入框 | number 数字输入 | select 下拉 | datetime 时间日期
  widget: "text",

  // 是否默认收起
  // 为 true 时，初始不可见，点击【展开】后可见
  collapsed: false,

  // 数据源sendOrders
  // 当字段类型为 select、radio、checkbox 时，通过该参数指定枚举
  // 注意：每个枚举都需要遵循 key/value 的格式，key 为实际取值，value 为展示文本。
  // 如果不是，则请自行转换；
  enmus: [],

  // 下拉框是否启用多选选
  multiple: false,

  // 下拉框多选时，超出该值隐藏
  collapseTags: 1,

  // 自定义渲染
  render: null,
};
 */

// 搜索栏组件封装
export default {
  name: 'FilterBox',
  components:{RSelectLoadMore,MobileHideButtonBox},
  props: {
    // 数据对象
    value: {
      type: Object,
      default() {
        return null;
      },
    },
    // 默认参数，基于该参数重置表单
    defaults: {
      type: Object,
      default() {
        return null;
      },
    },
    // 启用更多
    enableCollapse: {
      type: Boolean,
      default: true,
    },
    // 字段配置
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否禁用
    disabled: Boolean,
    // 高级字段配置
    fieldsHigh: {
      type: Array,
      default() {
        return [];
      },
    },
    //
  },
  data() {
    return {
      active: 0,
      form: { ...(this.$props.defaults || {}), ...(this.$props.value || {}) },
      collapsed: true,
      openHighSearch: false,
      highSearching: false,
      highSearchingForm: {},
      highSearchingFormTemp: {},
      voidFunction: () => {},
    };
  },
  computed: {
    renderFields() {
      const { fields } = this.$props;
      return [...fields].map((field) => {
      const __filterable = field.filterable !== undefined ? field.filterable : true;  // 如果filterable未定义，设置为true
        return {
          clearable: true,
          filterable:__filterable,
          ...field,
        };
      });
    },
    renderFieldsHigh() {
      const { fieldsHigh } = this.$props;
      // console.log(fieldsHigh);
      return fieldsHigh.map((field) => {
      const __filterable = field.filterable !== undefined ? field.filterable : true;  // 如果filterable未定义，设置为true
        return {
          clearable: true,
          filterable:__filterable,
          ...field,
        };
      });
    },
    formChanged() {
      return this.form;
    },
    // 是否存在查询参数
    canReset() {
      try {
        const { defaults } = this.$props;
        const { form } = this;
        if (defaults) {
          return Object.values(form).join('') !== Object.values(defaults).join('');
        }
        return Object.values(form).join('') !== '';
      } catch (error) {
        return false;
      }
    },
    // 预设字段中，是否存在可收起项
    hasCollapser() {
      const { fields } = this.$props;
      if (!Array.isArray(fields) || !fields.length) return false;
      return [...fields].filter((set) => true === set.collapsed).length > 0;
    },
  },
  watch: {
    model() {
      this.initFields();
    },
    formChanged: {
      deep: true,
      handler() {
        this.$emit('input', this.form);
        // if(v?.addTime?.length){
        //   this.handleFilterSubmit()
        // }
      },
    },
    highSearchingForm: {
      deep: true,
      handler(v) {
        this.$emit('on-highSearchingForm-change', v);
      },
    },
    $route() {
      this.openHighSearch = false;
    },
  },
  methods: {
    findCurNodeDeepFirst,
    getCurrentPlaceholder(field) {
      let enums = field?.titleSetting?.enums || [];
      let current = enums.find((item) => item.key == field.key);
      return current?.placeholder || '请输入';
    },
    // 提交
    handleFilterSubmit(isHighSearch) {
      this.active = 0;
      let params = {};
      if (this.highSearching) {
        if (isHighSearch) {
          this.renderFieldsHigh.forEach((item) => {
            params[item.key] = this.highSearchingForm[item.key];
          });
          this.highSearchingForm = params;
        } else {
          params = this.highSearchingFormTemp;
        }
        this.highSearchingFormTemp = this.$deepClone(params);
      } else {
        this.renderFields.forEach((item) => {
          params[item.key] = this.form[item.key];
        });
      }
      this.$emit('on-submit', params);
    },
    //单个质检、派单搜索（不在此函数封装之类 修改需求个别页面使用）
    quality() {
      let params = {};
      params.orderStatusList = ['PROCESSING_OK', 'REJECTED_AUDIT'];
      this.$emit('on-submit', params);
      this.active = 1;
    },
    sendOrders() {
      let params = {};
      params.orderStageStatus = 'SEND_ORDERS';
      this.$emit('on-submit', params);
      this.active = 2;
    },
    // 重置
    handleFilterReset() {
      this.active = 0;
      this.form = { ...(this.$props.defaults || {}) };
      this.$emit('on-reset', this.form);
      this.$emit('on-reset-defaults-time');
    },
    // 展开收起
    handleFilterCollapse() {
      const { enableCollapse } = this.$props;
      if (!enableCollapse) return;
      if (!this.hasCollapser) return;
      this.collapsed = !this.collapsed;
    },
    initFields() {
      this.form = {
        ...(this.$props.defaults || {}),
        ...(this.$props.value || {}),
      };
      this.$forceUpdate();
    },
  },
  mounted() {
    this.initFields();
    //去掉 派单 与 质检的lable
    const btn = document.querySelectorAll('.el-form-item__label');
    for (let i of btn) {
      if (i.innerText == '质检' || i.innerText == '派单') {
        i.style.display = 'none';
      }
    }
  },
  // components: { slogan }
};
</script>

<style lang="scss" scoped>
.rihgt {
  white-space: nowrap;
}
.search-form {
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: -16px;
}
.el-form--inline {
  .defaultFormItem {
    margin-bottom: 16px !important;
    margin-right: 16px;
    height: 32px;
    // overflow: hidden;
    // border: 1px solid #91a6fd;
    // border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    /deep/.el-form-item__content {
      // border: 1px solid rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      .el-input__inner {
        font-size: 14px;
        // border: none;
        border-radius: 2px;
      }
      .el-select {
        box-sizing: border-box;
        overflow: hidden;
        &.small-padding {
          .el-input__inner {
            padding-left: 75px;
          }
        }
        &.small-padding-112 {
          .el-input__inner {
            padding-left: 112px;
          }
        }
        .el-select__tags {
          padding-left: 80px;
          padding-right: 30px;
          overflow: auto;
          box-sizing: border-box;
        }
        .el-input__inner {
          padding-left: 95px;
        }
        /deep/.el-form-item__label {
          z-index: 9;
          background-color: #fff;
        }
      }
    }
    /deep/.el-form-item__label {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding: 0;
      color: #303133;
      padding-left: 15px;
    }
    /deep/.el-input {
      border-radius: 2px;
      &.mini-padding {
        input {
          padding-left: 60px;
          // width: 200px;
        }
      }
      &.smini-padding {
        input {
          padding-left: 75px;
          // width: 200px;
        }
      }
      &.small-padding {
        input {
          padding-left: 90px;
          // width: 200px;
        }
      }

      &.small-padding-112 {
        input {
          padding-left: 112px;
        }
      }
    }
    /deep/.el-cascader {
      &.small-padding {
        input {
          padding-left: 90px;
        }
      }
    }
    &.checkbox {
      // border: none;
      /deep/.el-form-item__label {
        position: relative;
      }
    }
    /deep/.el-input-group--append .el-input__inner {
      border-right: none;
      padding-right: 0;
    }
    /deep/.el-input-group__prepend {
      background-color: #fff;
      .el-select {
        width: auto;
        /deep/input {
          width: 100px;
        }
      }
    }
    /deep/.el-input-group__append {
      background-color: #fff;
      padding-left: 0;
    }
    /deep/ .el-select__tags {
      flex-wrap: unset;
      overflow: auto;
    }
    &.checkbox {
      /deep/.el-form-item__label {
        display: none;
      }
      /deep/.el-form-item__content {
        border: none;
      }
    }

    &.datetime {
      /deep/.el-form-item__label {
        height: 32px;
        line-height: 34px;
      }
      /deep/.el-form-item__content {
        height: 32px;
        box-sizing: content-box;
      }
      /deep/.el-range-input {
        width: 36%;
        font-size: 14px;
      }
    }
    .el-date-editor {
      &.small-padding {
        padding-left: 70px;
      }
      &.larg-padding {
        padding-left: 130px;
      }
      padding-left: 104px;
      padding-right: 0;
      /deep/.el-input__icon {
        position: relative;
        left: -4px;
      }
    }
  }
}

// 修正日期区间分隔字符宽度挤压问题
/deep/.el-date-editor .el-range-separator {
  width: 32px !important;
}

/deep/.el-form-item__content {
  vertical-align: middle;
  white-space: nowrap;
}

.high-search-button {
  display: inline-flex;
  align-items: center;
  border-left: solid 1px #f0f0f0;
  cursor: pointer;
  padding-left: 16px;
  margin-left: 16px;

  & > span {
    font-size: 14px;
    color: #185aff;
  }

  & > .icon {
    width: 16px;
    height: 16px;
    overflow: hidden;
    margin-left: 8px;

    img {
      display: flex;
      width: 100%;
      height: 100%;
      transform: translateX(-16px);
      filter: drop-shadow($colorPrimary 16px 0);
    }
  }

  //
}

.high-search-popover {
  & > .title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    padding: 20px 16px 16px;
  }

  & > .content {
    margin: 0 16px;

    .title-setting {
      display: inline-flex;
      width: 100%;
    }

    /deep/.el-form-item--small.el-form-item {
      margin-bottom: 16px;
    }

    /deep/.el-form-item__content {
      display: flex;

      .el-input,
      .el-select {
        flex: 1;
      }
    }
  }

  & > .handle {
    display: flex;
    padding: 0 16px 12px;

    & > .close {
      flex: 1;
    }

    .el-button {
      font-size: 12px;
    }

    .el-button.el-button--primary,
    .el-button.el-button--default {
      padding: 8px;
    }
  }

  //
}

.high-search-field {
  display: inline-flex;
  font-size: 14px;
  padding: 2px 0 18px;
  margin-right: 24px;
}

.high-search-empty {
  font-size: 14px;
  padding: 2px 0 18px;
}

.default-setting-select {
  display: inline-flex;
  margin-right: 16px;
  margin-bottom: 16px;
}
.btn {
  width: 70px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #d7d7d7;
  text-align: center;
  display: flex;
  justify-content: center;
  //  align-items: center;
  cursor: pointer;
  color: #aaaaaa;
  // color:  #0F5FD6;
  border-radius: 2px;
}
/deep/ .el-badge__content {
  font-size: 12px;
  height: 16px;
  line-height: 15px;
  vertical-align: middle;
}
.radio-group-item-label {
  margin-right: 3px;
  vertical-align: middle;
}
/deep/ .el-radio-button__inner{
  height: 34px;
}

.searchBoxMobileFixed {
    width: 100%;
    padding: 10px;
    overflow: auto;
    display: flex;
}

/deep/ .el-cascader__tags{
	input,.el-tag{
		padding-left: 90px;
	}
}
</style>
