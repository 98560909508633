export default {
  namespaced: true,
  state() {
    return {
      orderList: []
    };
  },
  mutations: {
    setOrderList(state, arr) {
      state.orderList = arr
    }
  },
  actions: {}
};
