<template>
  <div class="flex flex-wrap gap-12">
    <div
      class="button-item"
      v-for="(item, index) in list"
      :class="active == item.value ? 'isActive' : ''"
      :key="index + 'gls'"
      @click="handlerClick(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemButton',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: String,
      default: '1',
    },
    list: {
      type: Array,
      default: () => [
        {
          label: '企业',
          value: '1',
        },
        {
          label: '个体工商户',
          value: '2',
        },
        {
          label: '政府',
          value: '3',
        },
        {
          label: '事业单位',
          value: '4',
        },
      ],
    },
  },
  computed: {
    active: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    handlerClick(item) {
      this.active = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-item {
  min-width: 100px;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 700;
  border: 1px solid #dcdfe6;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}
.isActive {
  border: 1px solid #1890ff;
}
.isActive::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px; /* 控制三角形大小 */
  border-color: transparent transparent #1890ff transparent; /* 控制三角形颜色 */
}
</style>
