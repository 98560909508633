export default {
  path: '/system',
  name: 'System',
  meta: {
    role: 'bbe912cf-dca2-4ddc-81dd-796caa3765f2',
    title: '系统管理',
    secondaryTitle: '系统管理',
    icon: 'icon icon-font-setting',
    hidden: false,
    tag: false
  },
  component: () => import(/* webpackChunkName:"System" */ '@/layout/Main'),
  children: [
    // {
    //   path: 'resource',
    //   name: 'Resource',
    //   meta: {
    //     role: '9b54a5d4-7352-485a-a1f6-b61fc56dd259',
    //     icon: 'icon',
    //     title: '菜单管理',
    //     hidden: false,
    //     tag: true
    //   },
    //   component: () => import(/* webpackChunkName:"SystemVersion" */ '@/views/system/resource')
    // },

    {
      path: 'role',
      name: 'SystemTeamRole',
      meta: {
        role: 'cd52594a-384c-4da8-a5ff-be22f59d4226',
        icon: 'icon',
        title: '角色管理', // 原 岗位管理
        leftMenu: true,
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"SystemTeamRole" */ '@/views/system/team/Role')
    },
    {
      path: 'member',
      name: 'SystemTeamMember',
      meta: {
        role: 'fc275248-3334-489a-8a85-02d86f111823',
        icon: 'icon',
        title: '用户管理', // 原 组织管理
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"SystemTeamMember" */ '@/views/system/team/Member')
    },
    {
      path: 'metadata',
      name: 'BasicSettingMetadata',
      meta: {
        title: '元数据',
        icon: 'icon',
        hidden: false,
        tag: true,
        role: 'f9d6b5da-7a90-4dbd-9b5e-ad9a078bfcb1'
      },
      component: () => import(/* webpackChunkName:"System" */ '@/views/system/config/platform')
    }
    // // 安全设置
    // {
    //     path: 'securitySetting',
    //     name: 'SecuritySetting',
    //     meta: {
    //         role: 'c8cdc949-d41d-459c-b95b-1f627f829512',
    //         icon: 'icon',
    //         title: '安全设置',
    //         hidden: false,
    //         tag: true,
    //     },
    //     component: () => import(/* webpackChunkName:"securitySetting" */ '@/layout/Sec'),
    //     children: [
    //         {
    //             path: 'watermark',
    //             name: 'SecuritySettingWatermark',
    //             meta: {
    //                 role: '5be1ef77-845b-45cc-9b71-a8248ddba4ee',
    //                 icon: 'icon',
    //                 title: '水印设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"securitySetting" */ '@/views/system/config/Safe'),
    //         },
    //     ],
    // },

    // // 基础设置
    // {
    //     path: 'basicSetting',
    //     name: 'BasicSetting',
    //     meta: {
    //         role: 'b0a1fdab-9655-4830-ba30-0fc15168333f',
    //         icon: 'icon',
    //         title: '基础设置',
    //         hidden: false,
    //         tag: true,
    //     },
    //     component: () => import(/* webpackChunkName:"System" */ '@/layout/Sec'),
    //     children: [
    //         {
    //             path: 'metadata',
    //             name: 'BasicSettingMetadata',
    //             meta: {
    //                 role: '7332282b-6268-485e-9cb8-0b4b8c9431d6',
    //                 icon: 'icon',
    //                 title: '元数据',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"System" */ '@/views/system/config/platform'),
    //         },

    //         // 参数设置
    //         {
    //             path: 'paramsSetting',
    //             name: 'ParamsSetting',
    //             meta: {
    //                 role: 'ff3a3c62-f0dd-4dc9-962b-641df29b74b0',
    //                 icon: 'icon',
    //                 title: '参数设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"Operate" */ '@/views/system/config/paramsSetting'),
    //         },

    //         // 分单设置
    //         {
    //             path: 'separateOrderSetting',
    //             name: 'SeparateOrderSetting',
    //             meta: {
    //                 role: '7b08d7c7-2d92-41fa-abdd-18d9c80415cd',
    //                 icon: 'icon',
    //                 title: '分单设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"Operate" */ '@/views/system/config/separateOrderSetting'),
    //         },

    //         // 区域车牌设置
    //         {
    //             path: 'numberPlate',
    //             name: 'NumberPlate',
    //             meta: {
    //                 role: 'ab8148ce-cc01-4805-8841-e2e2bf92b2e6',
    //                 icon: 'icon',
    //                 title: '区域车牌设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"Operate" */ '@/views/system/config/numberPlate'),
    //         },

    //         // 区域设置
    //         {
    //             path: 'basicSettingAreaSetting',
    //             name: 'BasicSettingAreaSetting',
    //             meta: {
    //                 role: 'e585cf00-27b3-4cf3-a4da-261b62d9926f',
    //                 icon: 'icon',
    //                 title: '区域设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"Operate" */ '@/views/system/config/areaSetting'),
    //         },

    //         {
    //             path: 'approvel',
    //             name: 'SystemTeamApprovel',
    //             meta: {
    //                 role: '31436956-194f-47a3-82af-4f7e63a159d2',
    //                 icon: 'icon',
    //                 title: '审批流程设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"SystemTeamApprovel" */ '@/views/system/config/Approval'),
    //         },

    //         {
    //             path: 'chargeWarn',
    //             name: 'chargeWarn',
    //             meta: {
    //                 role: '0744f8df-aa85-4a6a-9a2c-c5379bf94bc6',
    //                 icon: 'icon',
    //                 title: '渠道剩余单量预警',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () => import(/* webpackChunkName:"SystemTeamApprovel" */ '@/views/system/config/chargeWarn'),
    //         },
    //         {
    //             path: 'approvalIsTurnedOnTheSetting',
    //             name: 'approvalIsTurnedOnTheSetting',
    //             meta: {
    //                 role: '07ce55b4-f495-47eb-862e-30fa0a39603c',
    //                 icon: 'icon',
    //                 title: '审批开启设置',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () =>
    //                 import(/* webpackChunkName:"SystemTeamApprovel" */ '@/views/system/config/approvalIsTurnedOnTheSetting'),
    //         },
    //         {
    //             path: 'approvalIsFeeTemplate',
    //             name: 'approvalIsFeeTemplate',
    //             meta: {
    //                 role: 'ff82d95a-9876-4347-9963-6c01266b211e',
    //                 icon: 'icon',
    //                 title: '附加费模板',
    //                 hidden: false,
    //                 tag: false,
    //             },
    //             component: () =>
    //                 import(/* webpackChunkName:"SystemTeamApprovel" */ '@/views/system/config/approvalIsFeeTemplate'),
    //         },
    //         //
    //     ],
    // },
  ]
};
