import storage from 'storejs';

const AllEnums = 'NewAllEnums';
const AllListEnums = 'AllListEnums';
const AllFlowAllEnums = 'AllFlowAllEnums';
const AllFlowListEnums = 'AllFlowListEnums';
// 设置全局枚举
export const getStoreAllEnums = () => storage.get(AllEnums);
export const setStoreAllEnums = val => storage.set(AllEnums, val);
export const removeStoreAllEnums = () => storage.remove(AllEnums);

// 设置全局枚举为obj list格式
export const getStoreAllListEnums = () => storage.get(AllListEnums);
export const setStoreAllListEnums = val => storage.set(AllListEnums, val);
export const removeStoreAllListEnums = () => storage.remove(AllListEnums);

// 设置flow所有枚举
export const getStoreAllFlowAllEnums = () => storage.get(AllFlowAllEnums);
export const setStoreAllFlowAllEnums = val => storage.set(AllFlowAllEnums, val);
export const removeStoreAllFlowAllEnums = () => storage.remove(AllFlowAllEnums);

// 设置所有flow全局枚举obj list格式
export const getStoreAllFlowListEnums = () => storage.get(AllFlowListEnums);
export const setStoreAllFlowListEnums = val => storage.set(AllFlowListEnums, val);
export const removeStoreAllFlowListEnums = () => storage.remove(AllFlowListEnums);
