import Vue from 'vue';
// TODO 自动读取components目录下的组件
import CustomCascader from './CustomCascader';
import CustomSelect from './CustomSelect';
import LineCredit from './LineCredit';
import TextLine from './TextLine';
import CustomForm from '../index';
import ProductionDesc from './appSetting/ProductionDesc';
import ProductionDescEdit from './appSetting/ProductionDescEdit';
import ProductionTag from './appSetting/ProductionTag';
import UploadInputFile from './appSetting/UploadInputFile';
import DomainInput from './DomainInput';
import ItemButton from './ItemButton';
import RuleFormEdit from './RuleFormEdit';
import CustomBox from './CustomBox';
import TitleForm from './TitleForm';
import CustomRadio from './CustomRadio';
const componentsObj = {
  CustomCascader,
  CustomSelect,
  LineCredit,
  TextLine,
  CustomForm,
  ProductionDesc,
  ProductionDescEdit,
  ProductionTag,
  UploadInputFile,
  DomainInput,
  ItemButton,
  RuleFormEdit,
  CustomBox,
  TitleForm,
  CustomRadio
};

const install = () => {
  // 判断是否安装
  if (install.installed) return;
  // 遍历注册全局组件
  Object.keys(componentsObj).forEach(res => {
    Vue.component(res, componentsObj[res]);
  });
};
// 判断是否是直接引入文件
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
export default {
  install
};
