<template>
  <div class="flex flex-col">
    <div class="flex flex-center justify-start gap-8" v-if="isShowTittle">
      <div class="left-icon"></div>
      <span class="title-name">{{ titleName }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleForm',
  props: {
    isShowTittle: {
      type: Boolean,
      default: true
    },
    titleName: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.left-icon {
  width: 6px;
  height: 20px;
  background: #197de8;
  border-radius: 4px;
}
.title-name {
  font-weight: 800;
  font-size: 20px;
  color: #333333;
}
</style>
