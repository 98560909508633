<template>
  <div class="flex" :title="tips">
    <span
      class="flex_1 overflow-hidden font_14 color-black ellipsis-0" v-if="!isShowTip"
      >{{ tips || '-' }}
      <i v-if="isShowDetail" class="el-icon-view icon-view" @click="handlerView"></i>
    </span>
    <template v-else>
      <div v-if="isImg">
        <el-image v-if="tips" style="width: 100px; height: 100px" fit="fill" :src="tips"></el-image>
      </div>
      <el-link v-else v-bind="$attrs" @click="handlerClick">{{ tips }}</el-link>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TextLine',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: [String, Number]
    },
    isShowTip: {
      type: Boolean,
      default: false
    },
    dealFun: {
      type: Function,
      default: row => row
    },
    // 查看详情
    isShowDetail: {
      type: Boolean,
      default: false
    },
    isImg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tips: {
      get() {
        return this.dealFun ? this.dealFun(this.modelValue) : this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  },
  mounted() {
    this.dealFun && this.dealFun(this.tips);
  },
  methods: {
    handlerClick() {
      this.$emit('handlerClickLink', this.tips);
    },
    handlerView() {
      this.$emit('handlerView', this.tips);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-view {
  cursor: pointer;
  color: #1890ff;
  font-size: 18px;
}
</style>
