<!--
 * @Description: 二次封装El-selected下拉
 * @Author: Ran junlin
 * @Date: 2023-08-14 17:50:48
 * @LastEditTime: 2023-10-27 11:41:10
 * @LastEditors: Ran junlin
-->
<script>
import { Select } from 'element-ui';
// import { FormItem } from "element-ui";
import RFormItem from '../RenderFormItem';
import { Pagination } from 'element-ui';
import { debounce } from '@/utils/variable';
export default {
  components: { Select, RFormItem, Pagination },
  name: 'RSelect',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...Select.props,
    newValue: {
      type: [String, Number, Object, Array]
    },
    // eslint-disable-next-line vue/require-default-prop
    options: {
      type: Array,
      default: () => []
    },
    keyField: {
      type: [String, Number],
      default: 'engineerNo'
    },
    valueField: {
      type: [String, Number, Array, Object],
      default: 'engineerNo'
    },
    labelField: {
      type: [String, Number],
      default: 'name'
    },
    collapseTags: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: '企业名称'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: true
    },
    // eslint-disable-next-line vue/require-default-prop
    size: {
      type: [String],
      default: 'small'
    },
    // 是否允许搜索
    filterable: {
      type: Boolean,
      default: true
    },
    /**
     * multiple-limit
     * 是否支持多选
     */
    multiple: {
      type: Boolean,
      default: false
    },
    /**
     * multiple-limit
     * 多选个限制数
     */
    multipleLimit: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    loadingText: {
      type: String,
      default: '加载中'
    },
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * showPagination
     * 是否启用分页
     * 目前分页只支持直接传service过来，在本组件实现分页功能
     */
    showPagination: {
      type: Boolean,
      default: false
    },
    paginationOptionsTotal: [Number],
    required: {
      type: Boolean,
      default: false
    },
    labelWidth: [String, Number],
    rules: {
      type: Array,
      default: () => []
    },
    // 校验字段
    prop: {
      type: String,
      default: ''
    },
    //如果传了serve,则调用服务
    service: {
      type: [Function, String],
      default: () => () => {}
    },
    params: {
      type: Object,
      default: () => {}
    },
    filterSelf: {
      type: Boolean,
      default: false
    },
    labelFieldTwo: {
      type: String,
      default: ''
    },
    /**
     * 是否开启组件调用service搜索
     */
    callService:{
      type: Boolean,
      default: false
    },
    serviceData:{
      type:String,
      default:''
    },
    searchKey: {
      type: String,
      default: 'keyword'
    },
  },
  data() {
    return {
      ownOptions: [],
      ownLoading: false,
      debouncedGetList: null, // 防抖函数句柄
      paginationOptions: {
        //分页配置项
        pageIndex: 1,
        pageSize: 10, //每页显示条数  6条刚好
        pagerCount: 5, //按钮数，超过时会折叠
        layout: 'prev, pager, next',
        total: 100
      }
    };
  },
  computed: {
    // callService() {
    //   debugger
    //   return typeof this.service === 'function' && this.$props.service.name != 'default';
    // },
    paginationOption() {
      return { ...this.paginationOptions, total: this.paginationOptionsTotal };
    },
    optionList() {
      if (!this.$props.options) {
        return;
      }
      console.log('callService', this.callService);
      console.log('callService', this.callService);
      let list = this.callService ? this.ownOptions : this.$props.options;
      const labelFieldTwo = this.$props.labelFieldTwo;
      const options = list?.map((it, idx) => {
        const item = {
          ...it,
          key: it[this.keyField || this.valueField] || idx,
          label: labelFieldTwo ? it[this.labelField] + '-' + it[labelFieldTwo] : it[this.labelField] || it,
          value: it[this.valueField] || it
        };
        return item;
      });
      return options;
    },
    optionsFormItem() {
      const { required, rules, label, prop, error } = this.$props;
      return {
        required,
        rules,
        label,
        prop,
        error
      };
    },
    optionSelect() {
      const {
        value,
        clearable,
        filterable,
        multiple,
        disabled,
        remote,
        loading,
        size,
        remoteError,
        maxTagCount,
        collapseTags,
        placeholder,
        multipleLimit
      } = this.$props;
      return {
        value,
        clearable,
        filterable,
        multiple,
        disabled,
        remote,
        size,
        remoteError,
        maxTagCount,
        collapseTags,
        placeholder,
        multipleLimit,
        options: this.optionList,
        filterMethod: this.$props.filterSelf
          ? null
          : this.callService
          ? this.debouncedGetList
          : this.handleRemoteMethod, //前端做筛选时不需要重写方法
        loading: this.callService ? this.ownLoading : loading
      };
    }
  },
  watch: {
    params: {
      handler() {
        this.ownOptions = [];
        this.handleClear();
      },
      deep: true
    }
  },
  mounted() {
    if (this.callService) {
      this.getList();
      // 创建防抖函数
      this.debouncedGetList = debounce(this.handleRemoteMethod, 500);
    }
  },
  methods: {
    handleClear() {
      this.callService && this.getList('');
    },
    blur() {
      this.$refs.select.blur();
    },
    focus() {
      this.$refs.select.focus();
    },

    handlePageNumChange(num) {
      this.paginationOptions.pageIndex = num;
      this.getList();
    },

    currentChanges(size) {
      // this.$emit('currentChanges', size);
      this.paginationOptions.pageSize = size;
      this.paginationOptions.pageIndex = 1;
      this.getList();
    },
    change(args) {
      this.$emit('change', args);
      const currentItem = this.optionList.find(it => it.value === args);
      if (currentItem) {
        this.$emit('getCurrentItem', currentItem);
      }
    },
    handleRemoteMethod(val) {
      //callService为true时，不触getList发远程请求，组件自身不触发请求ajax事件，需要通过remoteMethod回传到父组件处理
      if (!this.callService) {
        this.$emit('remoteMethod', val);
        return;
      }
      this.ownLoading = true; // 设置加载状态
      this.getList(val); // 执行获取数据的操作
    },

    async getList(val) {
      try {
        this.ownLoading = true;
        let keyword = val;
        if (keyword) {
          //如果有关键字，重置分页
          this.paginationOptions.pageIndex = 1;
        }
        const page = this.showPagination ? this.paginationOptions : {};
        //传入了serviceData就用,否则分页取data.records，不分页区理data
        if (!this.service) {
          this.ownOptions = [];
          this.paginationOptions.total = 0;
          return;
        }
        const data = await this.service({ keyword, ...this.params, ...page, [this.searchKey]: keyword ? keyword:null });
        // this.ownOptions = this.callService && this.showPagination ? data.records : data || [];
        if(this.serviceData){
           this.ownOptions = this.callService && data[this.serviceData] || [];
        }else{
           this.ownOptions = this.callService && this.showPagination ? data.records : data || [];
        }
        this.$emit('getStoreHouseList',this.ownOptions)

        this.paginationOptions.total = Number(data.total) || 0;
      } catch (error) {
        this.$message({
          message: error,
          type: 'error'
        });
      } finally {
        this.ownLoading = false;
      }
    }
  },
  render() {
    const { pageSize, pagerCount, layout, total } = this.paginationOptions;
    return (
      <RFormItem customerProps={this.optionsFormItem}>
        <Select
          ref="select"
          {...{
            props: {
              ...this.optionSelect
            },
            on: this.$listeners
          }}
          onChange={this.change}
          onClear={this.handleClear}
        >
          {this.optionList.map(v => {
            return (
              <el-option
                on={this.$listeners}
                label={v.label}
                key={v.value}
                value={v.value}
                disabled={v.disabled}
                class={{ showPaginationMb: this.showPagination }}
              ></el-option>
            );
          })}
          {[
            this.showPagination ? (
              <el-pagination
                class={{ showPagination: this.showPagination }}
                {...this.paginationOptions}
                on-current-change={this.handlePageNumChange}
                on-size-change={this.currentChanges}
                small
                layout={layout}
                total={total}
                pager-count={pagerCount}
                page-size={pageSize}
              ></el-pagination>
            ) : (
              ''
            )
          ]}
        </Select>
      </RFormItem>
    );
  }
};
</script>
<style lang="scss">
.showPaginationBox {
  position: relative;
  .el-scrollbar__view {
    margin-bottom: 3px;
  }
}
.el-scrollbar__view {
  .showPaginationMb {
    &:nth-last-child(2) {
      margin-bottom: 30px !important;
      position: relative;
    }
  }
  .showPagination {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0px;
    background: #ffffff;
    padding: 10px 0;
  }
}
</style>
