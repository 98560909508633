export default {
  path: '/order',
  name: 'Order',
  meta: {
    role: '6ceb2983-efda-460f-b2ad-2d844436daf2',
    title: '订单管理',
    secondaryTitle: '订单管理',
    icon: 'icon el-icon-s-order',
    hidden: false,
    tag: false
  },
  component: () => import(/* webpackChunkName:"Order" */ '@/layout/Main'),
  children: [
    {
      path: 'pre',
      name: 'OrderPre',
      meta: {
        role: 'd2d4af77-4854-4523-bac2-1dcd2b55b307',
        icon: 'icon',
        title: '预审订单',
        leftMenu: true,
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"OrderPre" */ '@/views/order/pre/index')
    },
    {
      path: 'incoming',
      name: 'OrderIncoming',
      meta: {
        role: '95730478-04fc-4241-8e03-6e1a0bd8b8d5',
        icon: 'icon',
        title: '进件订单',
        leftMenu: true,
        hidden: false,
        tag: true
      },
      component: () => import(/* webpackChunkName:"OrderIncoming" */ '@/views/order/incoming/index')
    },
    {
      path: 'incoming/detail',
      name: 'OrderIncomingDetailView',
      meta: {
        role: '0e14a900-9336-422e-949f-4b9e1b431717',
        icon: 'icon',
        title: '进件订单详情',
        leftMenu: false,
        hidden: true,
        tag: true,
      },
      component: () => import(/* webpackChunkName:"OrderIncomingDetail" */ '@/views/order/incoming/detail/index'),
    },
    {
      path: 'pre/detail',
      name: 'OrderPreDetail',
      meta: {
        role: 'fc47c72f-954e-42f1-87a7-c8985231417f',
        icon: 'icon',
        title: '预审订单详情',
        leftMenu: false,
        hidden: true,
        tag: true
      },
      component: () => import('@/views/order/pre/components/preDetail.vue')
    }
  ]
};
