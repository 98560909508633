var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    _vm._b(
      {
        directives: [
          {
            name: "el-select-loadmore",
            rawName: "v-el-select-loadmore",
            value: _vm.that,
            expression: "that",
          },
        ],
        staticClass: "my-select",
        staticStyle: { width: "100%" },
        attrs: {
          filterable: "",
          remote: "",
          "reserve-keyword": "",
          "remote-method": _vm.handleRemoteSearch,
        },
        on: {
          change: _vm.handleChange,
          clear: _vm.handleClear,
          "visible-change": _vm.handleVisibleChange,
        },
        model: {
          value: _vm.customValue,
          callback: function ($$v) {
            _vm.customValue = $$v
          },
          expression: "customValue",
        },
      },
      "el-select",
      _vm.$props,
      false
    ),
    [
      _c(
        "div",
        {
          staticClass: "statusIcon",
          attrs: { slot: "prefix" },
          slot: "prefix",
        },
        [
          _vm.customLoading
            ? _c("i", { staticClass: "el-icon-loading" })
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { class: [_vm.customLoading ? "selectLoad" : "noData"] },
        _vm._l(_vm.options, function (item, index) {
          return _c("el-option", {
            key: item[_vm.keyField] ? item[_vm.keyField] : index,
            attrs: {
              label: _vm.getLabel(item),
              value: item[_vm.valueField],
              disabled: _vm.disabledFunc(item),
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }