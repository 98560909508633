import { cachePrefix } from '@/config/_app'

// 缓存操作

// 缓存标识
export const CACHE_NAME = cachePrefix + 'tag_nav';

// 读取缓存
export const getCache = () => {
  try {
    return JSON.parse(localStorage.getItem(CACHE_NAME)) || [];
  } catch (error) {
    return [];
  }
};

// 设置缓存
export const setCache = data => {
  localStorage.setItem(CACHE_NAME, JSON.stringify(data));
};
