<template>
  <div class="flex">
    <el-tag v-bind="$attrs" v-on="$listeners">{{ txtTitle || '-' }}</el-tag>
  </div>
</template>

<script>
export default {
  name: 'CustomTag',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    dealFun: {
      type: Function,
      default: row => row
    }
  },
  computed: {
    txtTitle: {
      get() {
        return this.dealFun ? this.dealFun(this.modelValue) : this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  },
  mounted() {
    this.dealFun && this.dealFun(this.txtTitle);
  }
};
</script>

<style scoped></style>
