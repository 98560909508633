var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col gap-12", class: _vm.contentClass },
    [
      _vm.isShowTittle
        ? _c("TitleForm", { attrs: { "title-name": _vm.titleName } })
        : _vm._e(),
      _c(
        "div",
        { class: _vm.formClass },
        [
          _vm._t("default", function () {
            return [
              _c(
                "CustomForm",
                _vm._g(
                  _vm._b(
                    {
                      ref: "customFormRef",
                      staticClass: "item-form-custom",
                      attrs: {
                        "form-column": _vm.formColumn,
                        rules: _vm.rules,
                      },
                      on: {
                        "update:rules": function ($event) {
                          _vm.rules = $event
                        },
                      },
                      model: {
                        value: _vm.formData,
                        callback: function ($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData",
                      },
                    },
                    "CustomForm",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }