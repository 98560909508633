
import { getRefreshToken, setRefreshToken, setToken } from '@/utils/app';
import * as http from '@/http';
import _needRelogin from '@/http/interceptor/common/needRelogin';

const refreshTokenApi = async (data) => {
    const url = http.buildServiceUrl('OAUTH', `authority/refresh-token?refreshToken=${data}`);
    const res = await http.post(url);
    return res || {};
};

const refreshTokenFetch = async (axiosInstance, originalRequest) => {
    try {
        let param = getRefreshToken()
        const res = await refreshTokenApi(param);
        const { accessToken, refreshToken } = res.data
        // 尝试使用 refreshToken 获取新的 accessToken
        if (res.code === 0 && accessToken) {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            originalRequest.headers.token = `Bearer ${accessToken}`;
            setToken(accessToken);
            setRefreshToken(refreshToken);

            // 更新请求头部的 token
            originalRequest.headers['env-type'] = process.env.NODE_ENV
            originalRequest.headers['client'] = process.env.VUE_APP_AUTH_CLIENT_ID;
            originalRequest.headers['terminal'] = process.env.VUE_APP_AUTH_CLIENT_TERMINAL;

            // 重新发送原来的请求
            return axiosInstance(originalRequest);
        }
    } catch (refreshError) {
        console.log(refreshError);
        //重定向到登录页面
        _needRelogin()
    }

}
export default refreshTokenFetch