import * as http from '@/http';

// 获取融后管理枚举字段
export const getAllEnums = async () => {
  const url = '/afterloan/common/allEnums';
  const res = await http.get(url);
  return res?.data || {};
};

// 获取融后订单列表
export const getAfterFusionOrderList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `bill/page`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 获取融后订单详情
export const getAfterFusionOrderDetail = async (id) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `bill/find/${ id }`);
  const res = await http.get(url);
  return res?.data || {};
};

export const getAfterFusionOrderDetailByOrderNo = async (orderNo) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `bill/find/orderNo/${ orderNo }`);
  const res = await http.get(url);
  return res?.data || {}
}


// 获取结清试算列表
export const getSettleTrialList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `settle/pageSettleCalc`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 获取结清列表
export const getSettleList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `settle/page`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 获取结清试算信息
export const getSettleTrialInfo = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `settle/generateCalc`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 获取结清金额信息
export const getSettlePriceInfo = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `settle/generate`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 提交结清申请
export const addSettleInfo = async (id) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `settle/doSettle`);
  const res = await http.post(url, { id });
  return res?.data || {};
};

// 获取结清详情
export const getSettleDetail = async (id) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `settle/find/${ id }`);
  const res = await http.get(url);
  return res?.data || {};
};

// 获取还款列表
export const getRepaymentList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `repayment/page`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 获取还款详情
export const getRepaymentDetail = async (id) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `repayment/find/${ id }`);
  const res = await http.get(url);
  return res?.data || {};
};

// 新增还款
export const addRepaymentInfo = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `repayment/add`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 查询减免列表
export const getDerateList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `reduction/page`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 查询浙商还款计划列表
export const getZSRepaymentList = async (billId) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `zs/reply-plan/findByBillId/${ billId }`);
  const res = await http.get(url);
  return res?.data || {};
};

// 新增减免
export const addDerateInfo = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `reduction/add`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 查询减免详情
export const getDerateDetail = async (id) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `reduction/find/${ id }`);
  const res = await http.get(url);
  return res?.data || {};
};

// 查询借据总金额列表
export const getBalanceList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `billAmount/page`);
  const res = await http.post(url, data);
  return res?.data || {};
};

// 获取账单列表
export const getBillList = async (data) => {
  const url = http.buildServiceUrl('AFTER_LOAN', `bill/findPlanPage`);
  const res = await http.post(url, data);
  return res?.data || {};
};


