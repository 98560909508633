import Vue from 'vue';
import SourceViewer from '@/components/SourceViewer';
import CustomDialogPicker from '@/components/CustomDialogPicker';
import store from '@/store';
import DialogHandle from '@/components/DialogHandle.vue';
import DIAOBJECT from '@/utils/DialogHandleFormObj';
import { msg } from './common';

/**
 * 选择器 弹窗
 * 
 * @param {Object} options
 * @returns 
 */
export const showPicker = async ({
  title,
  enums = [],
}) => {
  return new Promise(resolve => {
    const page = new (Vue.extend({
      extends: CustomDialogPicker,
      methods: {
        closed() {
          document.body.removeChild(page.$el);
        },
      }
    }))({
      propsData: {
        title,
        enums,
      },
    });
    page.$mount();
    page.$on('handleSelect', (v) => resolve(v));
    page.value = true;
    document.body.appendChild(page.$el);
  })
}

/**
 * @description： 显示资源
 * @param {Object} options 传入对象
 * @param {String} options.sourceList title
 * @return {Promise} Promise
 * @example
 * this.$SourceViewer({
 *  sourceList = []
 * })
 */
export async function SourceCropper({ sourceList = [] } = {}) {
  if (!sourceList.length)
    return msg({
      content: '资源不能为空',
      type: 'warning'
    });
  let croper = new (Vue.extend(SourceViewer))({
    propsData: {
      sourceList
    },
    methods: {
      close() {
        this.value = false;
        document.body.removeChild(croper.$el);
      }
    }
  });
  croper.$mount();
  document.body.appendChild(croper.$el);
  croper.value = true;
}

/**
 * @param { Object } options 传入对象
 * @param { String } options.handleType 同 DIAOBJECT 中的类容
 * @param { Object } options.defaultValues  传入的有默认值的字段
 * @param { Array } options.readOnlyKeys 需要设置表单中为只读的字段
 * @param { Object } options.options 需要设置表单中指定字段的枚举数据
 * @returns 返回一个promise对象
 * @example
 * this.$dialogHandle({
 *  handleType: 'DISPATCH'
 * }).then(async ({ close, ...params } =>{
 *   close()
 * })
 */
export async function dialogHandle({
  handleType,
  defaultValues = {},
  readOnlyKeys,
  options = {},
  callback = () => { },
  additionalData={},
} = {}) {
  let resolve;
  let promise = new Promise((res) => {
    resolve = res;
  });
  let dialog = new (Vue.extend(DialogHandle))({
    store,
    propsData: {
      handleType
    },
    mounted() {
        this.initDialog()
    },
    methods: {
      initDialog(){
        this.isFormComponents = false
        this.resolve = resolve;
        const NAME = this.handleType;
        let item = DIAOBJECT.get(NAME)||DIAOBJECT[NAME]||{};
        /** 原本的封装比较简陋，无法满足需求，逐步替换成IPage系列组件
         * 传入的配置全部记录下来，根据item.$isForm来判断是否是新的表单组件数据集
         * by dxl 2024-03-27
         */
        const {$isForm,title,$beforCheckSubmit,...rest} = item;
        rest.qData = additionalData||{}
        this.configItem = rest||{};
        this.isFormComponents = $isForm || false;
        this.$beforCheckSubmit = $beforCheckSubmit
        this.title = title;
        if($isForm){
            this.configItem.formItems.forEach(item=>{
                let value = item.value
                if(value===undefined){
                    value = null
                }
                this.$set(this.form,item.id,value)
            })
            return
        }
        // 下面是旧表单模式
        let _form = item.form;
        this.formObject = JSON.parse(JSON.stringify(_form));
        // 设置传入的options List
        for (let k in this.formObject) {
            this.$set(
            this.formObject[k],
            'options',
            options[k] ? options[k] : _form[k].options
            );
        }
        // 设置只读字段
        if (readOnlyKeys && readOnlyKeys.length)
            readOnlyKeys.map(e => {
            this.formObject[e].readOnly = true;
            });
        // 设置基础表单字段 & 默认值
        for (let k in _form) {
            this.$set(
            this.form,
            k,
            defaultValues[k] ? defaultValues[k] : _form[k].default
            );
            if (_form[k].rule) {
            this.$set(this.rules, k, _form[k].rule);
            }
        }
      },
      close() {
        this.pending = false;
        dialog.view = false;
        document.body.removeChild(dialog.$el);
      },
      async handleSubmit() {
        console.log('handleSubmitIForm',this.form);
        let valid
        if(this.isFormComponents){
            valid = await this.$refs.IForm.$refs.formRef.validate().catch(err => {
                return err;
            });
        }else{
            valid = await this.$refs.diaHldForm.validate().catch(err => {
                return err;
              });
        }
        console.log(valid,'valid');
        if (valid===true) {
          if(this.isFormComponents && this.$beforCheckSubmit){
            try {
                await this.$beforCheckSubmit(this.form,this)    
            } catch (error) {
                return
            }
          }
          this.pending = true
          callback({
            ...this.form,
            dialog
          });
          this.resolve({
            ...this.form,
            close: this.close
          });
        }
      }
    }
  });
  dialog.view = true;
  dialog.$mount();
  document.body.appendChild(dialog.$el);
  return promise;
}
