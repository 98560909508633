var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-col justify-center" }, [
    _c(
      "div",
      { staticClass: "flex gap-12 flex-center w_320" },
      [
        _c("el-input", {
          attrs: {
            maxlength: "5",
            placeholder: "请输入产品标签",
            clearable: "",
            size: "small",
          },
          model: {
            value: _vm.tagVal,
            callback: function ($$v) {
              _vm.tagVal = $$v
            },
            expression: "tagVal",
          },
        }),
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.addTags } },
          [_vm._v("添加")]
        ),
      ],
      1
    ),
    _vm.newTags && _vm.newTags.length
      ? _c(
          "div",
          { staticClass: "flex flex-wrap gap-12 m_t_12" },
          _vm._l(_vm.newTags, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: tag + "abc",
                attrs: { closable: "" },
                on: {
                  close: function ($event) {
                    return _vm.handlerCloseTag(tag, index)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }