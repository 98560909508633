var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobile-hide-button-box" },
    [
      _vm.isMobile
        ? _c(
            "el-popover",
            {
              attrs: {
                placement: _vm.placement,
                width: _vm.width,
                trigger: "click",
              },
            },
            [
              _vm._t("default"),
              _c(
                "el-button",
                _vm._b(
                  {
                    class: _vm.buttonClass,
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  "el-button",
                  _vm.buttonProps,
                  false
                ),
                [_vm._v(_vm._s(_vm.buttonLabel))]
              ),
            ],
            2
          )
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }