import store from '../index'
import { getAllEnums } from '@/api/afterFusion'
import { isEmpty } from 'lodash'

export default {
  namespaced: true,
  state() {
    return {
      globalEnums: {}
    };
  },
  mutations: {
    setGlobalEnums(state, data) {
      state.globalEnums = data
    }
  },
  getters: {
    getGlobalEnums: (state) => {
      if (isEmpty(state.globalEnums)) {
        store.dispatch('afterFusion/getGlobalEnumsFetch')
      }

      return state.globalEnums
    }
  },
  actions: {
    async getGlobalEnumsFetch({ state, commit }) {
      const data = await getAllEnums()
      commit('setGlobalEnums', data)
    }
  }
};
