import * as http from '@/http';
import config from '@/config/config';

//通用文件上传服务
// export const uploadUrl = '/file/service/file/uploadFile';
export const uploadUrl = http.buildServiceUrl('FILE', `file/upload`);

export const uploadFile = async (file, url = uploadUrl, maxSize = 200 * 1024 * 1024) => {
  try {
    if (!file) {
      throw '上传失败，暂无文件！';
    }

    const { size } = file;
    if (size > maxSize) {
      throw new Error(`${file.name} 文件超过 ${maxSize / 1024 / 1024}M!`);
    }

    let forms = new FormData();
    forms.append('file', file);

    const res = await http.post(url, forms, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    });

    // 统一处理返回值（url需要拼接前缀）
    if (res.data) {
      //判断path是否需要拼接前缀
      let url = res.data.storageAddress;

      if (res.data.storageAddress && !res.data.storageAddress.startsWith('http')) {
        url = config.imgHttps + res.data.storageAddress;
      }

      res.data = {
        ...res.data,
        fileUrl: url,
        url
      };
    }
    console.log(res.data, 'uploadFile');
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
