import http from '@/http';
import { getToken } from '@/utils/app';
import base64 from 'base-64';
import utf8 from 'utf8';
import { isMobile, isValidAccount } from '@/utils/validator';

// 认证协议
export function helperProtocol() {
  return http.get(`/base/authority/helper/protocol`).then(res => res.data);
}

// 获取登录验证码图片
export const GetCaptchaImage = captcha_target => {
  return `${http.config.api}/base/basic/captcha/image/${captcha_target}`;
};

// 登录入参
export const SignLoginParams = {
  grant_type: 'password', // password 仅密码登录 | mobile 验证码
  appId: process.env.VUE_APP_AUTH_CLIENT_ID,
  phone: '',
  pwd: null,
  agree: true,
  forceLogin: 3
};

// 登录入参校验
export const SignLoginValidate = async data => {
  if (!data) throw '请完善登录信息';
  if (data.grant_type == 'phone') {
    // if (!data.phone) throw '请填输入手机号';
    if (!data.username) throw '请填写用户名';
    if (!data.password) throw '请输入密码';
    // if (!data.sms_code) throw '请输入手机验证码';
    // if (!data.agree) throw '请同意平台服务协议';
  } else {
    if (!data.username) throw '请填写用户名';
    if (!data.password) throw '请输入密码';
    // if (!data.pwd) throw '请填写密码';
    // if (!data.agree) throw '请同意平台服务协议';
  }
  return data;
};

export function base64Password(text, com = false) {
  var bytes = utf8.encode(text);
  var encoded = 'bP:' + base64.encode(bytes);
  if (com) {
    return encodeURIComponent(encoded);
  }
  return encoded;
}

// 获取验证码
export const sendLoginSms = async phone => {
  const res = await http.get(http.buildServiceUrl('OAUTH', `api/user/sendLoginSms/?phone=${phone}`));
  return res;
};

// 获取验证码
export const loginByPhoneSms = async data => {
  const res = await http.post(http.buildServiceUrl('OAUTH', `api/user/loginByPhoneSms`), data);
  return res;
};

// 登录
export const SignLogin = async data => {
  const _data = {
    // ...SignLoginParams,
    ...data
  };
  await SignLoginValidate(_data);
  const res = await http.post(http.buildServiceUrl('OAUTH', `authority/password-login`), _data); // api/user/loginWithPhoneAndPwd
  return res;
};

// 刷新token
export const refreshToken = async data => {
  const url = http.buildServiceUrl('OAUTH', 'authority/refresh-token');
  return await http.post(url, data);
};

// 获取认证账户信息，登录成功的账户信息
export const GetAuthUser = async () => {
  const res = await http.get('/api/authority/user');
  return res.data;
};

/* // 获取认证账户信息，登录成功的账户信息
export const getBaseConfig = async () => {
  const res = await http.get('/api/operation/staff/base-setting');
  return res.data;
}; */

// 获取登录账户的权限菜单
export const GetAuthMenu = async () => {
  const res = await http.get('/api/auth/menus');
  const _data = res.data || [];
  // 移除无意义字段，避免占用过多的本地缓存资源
  return _data.map(row => {
    return {
      menuId: row.menuId,
      identifier: row.identifier,
      title: row.title,
      hierarchy: row.hierarchy,
      source: row
    };
  });
};

// 获取登录账户的权限资源
export const GetAuthResource = async () => {
  const res = await http.get('/api/auth/resources');
  const _data = res.data || [];
  // 移除无意义字段，避免占用过多的本地缓存资源
  return _data.map(row => {
    return {
      menuId: row.menuId,
      identifier: row.identifier,
      title: row.title,
      hierarchy: row.hierarchy,
      source: row
    };
  });
};

// 退出登录
export const SignLogout = async () => {
  const token = getToken();
  http.delete(`/api/authority/revoke?token=${token}`);
};

// 获取账号信息
export async function  getUserInfo() {
  const res = await http.get(http.buildServiceUrl('LOAN', `login-user`));
  return res;
}

/**
 * @description: 获取短信验证码
 * @param {String} code 图形验证码
 * @param {String} mobile 手机号码
 * @param {String} target 验证码标识
 * @return {Promise}
 */
export function getInfracaptcha(data) {
  return http.post('/base/basic/captcha/security', data);
}

/**
 * @description: 找回密码
 * @param {String} captchaCode 	手机号验证码
 * @param {String} mobile 手机号
 * @param {String} password 密码
 * @return {Promise}
 */
export async function findPassword(data) {
  let _data = { ...data };
  await findPasswordValidate(_data);
  _data.password = base64Password(_data.password);
  return http.put('/base/authority/helper/reset-password', _data);
}

// 登录入参校验
export const findPasswordValidate = async data => {
  if (!data.mobile) throw '请输入手机号';
  if (!isMobile(data.mobile)) throw '请输入正确的11位手机号码';
  if (!data.captchaCode) throw '请输入手机验证码';
  if (!data.password) throw '请输入密码';
  if (!isValidAccount(data.password)) throw '请输入8-16位数字及英文组合';
  return data;
};

export async function Urltest(url) {
  return http.get(url);
}

/**
 * @description: 设置模块信息
 */
// /api/operation/staff/modular-setting
export function modularSetting(data) {
  return http.post('/api/operation/staff/modular-setting', data);
}

// 员工在线状态修改
export const putOnlineStatus = async (staffNo, data) => {
  return http.put(`/api/platform/staffs/online/${staffNo}`, data);
};

// 修改密码
export function resetPassword(data) {
  return http.put('/api/authority/user/account/password', data);
}
