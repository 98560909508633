/*
 * @Description:路由模块
 * @Author: Ran junlin
 * @Date: 2023-07-26 15:29:26
 * @LastEditTime: 2024-08-14 14:40:09
 * @LastEditors: Ran junlin
 */
import home from './home'; // 工作台
// import customer from './customer'; // 客户管理
import dev from './dev'; // 开发
import system from './system'; // 设置
import order from './order'; // 设置
import configuration from './configuration'; // 配置管理
import auditHall from './auditHall';
import afterFusion from './afterFusion';
import customer from './customer';
// 聚合各个路由模块，暴露给 ./src/router/index.js 使用
const _routes = [home, order, auditHall, afterFusion, customer, configuration, system];

// // 开发环境提供开发组件
if (process.env.NODE_ENV === 'development') {
  _routes.push(dev);
}

export default _routes;
