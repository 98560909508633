//获取单个枚举接口
import * as http from '@/http';

//获取单个枚举接口key
export const getAllEnumsKey = async () => {
  const url = http.buildServiceUrl('LOAN', `common/enums`);
  const res = await http.get(url);
  return res?.data || {};
};
// 获取单个枚举接口的value
export const getSingleEnumsValue = async key => {
  const url = http.buildServiceUrl('LOAN', `common/enums/${key}`);
  const res = await http.get(url);
  return res?.data || {};
};
