import { Message, MessageBox } from 'element-ui';

/**
 * 消息提示封装
 * @description `options` 为通用配置，适合大部分场景，`defaults` 为详细配置，参考对应 mode 的配置项
 * @param {Object} options 通用配置
 * @param {Object} defaults mode 对应的细节配置
 */
export async function msg(options = {}, defaults = {}) {
  const _options = {
    mode: 'message', // 消息类型，message | alert | confirm | notification
    type: 'info', // 消息类型，success | warning | info | error
    content: '', // 消息内容
    title: null, // 标题内容
    duration: 1000 * 3, // 显示停留时间，毫秒
    offset: 20, // Message 消息距离窗口顶部偏移
    rich: false, // 是否把内容作为 HTML 展示
    position: 'top-right', // notification 类型消息的显示位置 top-right | top-left | bottom-right | bottom-left
    ...options
  };

  // 特殊信息需要打印到控制台
  if (['warning', 'error'].includes(_options.type)) {
    switch (_options.type) {
      case 'error':
        console.error(_options.content);
        break;
      case 'warning':
      default:
        console.trace(_options.content);
        break;
    }
  }

  // Alert
  if (_options.mode === 'alert') {
    return MessageBox.alert(_options.content, _options.title, {
      type: _options.type,
      dangerouslyUseHTMLString: _options.rich,
      ...defaults
    });
  }

  // Confirm
  if (_options.mode === 'confirm') {
    return MessageBox.confirm(_options.content, _options.title, {
      type: _options.type,
      dangerouslyUseHTMLString: _options.rich,
      ...defaults
    });
  }

  // Notification 通知
  if (_options.mode === 'notification') {
    return MessageBox.notify({
      type: _options.type,
      title: _options.title,
      message: _options.content,
      duration: _options.duration,
      offset: _options.offset,
      dangerouslyUseHTMLString: _options.rich,
      ...defaults
    });
  }

  // Message 消息提示
  Message.closeAll();
  Message({
    type: _options.type,
    message: _options.content,
    dangerouslyUseHTMLString: _options.rich,
    duration: _options.duration,
    offset: _options.offset,
    ...defaults
  });
}
export function copyText(text) {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          console.log('复制成功');
          resolve()
        })
        .catch((err) => {
          reject(new Error('复制失败'))
          console.log(err, '复制失败');
        });
    } else {
      // 创建text area
      const textArea = document.createElement('textarea');
      textArea.value = text;
      // 使text area不在viewport，同时设置不可见
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((R, rej) => {
        // 执行复制命令并移除文本框
        document.execCommand('copy')
          ? R()
          : rej(new Error('出错了'));
        textArea.remove();
      }).then(
        () => {
          resolve()
          console.log('复制成功');
        },
        () => {
          reject(new Error('复制失败'))
          console.log('复制失败');
        }
      );
    }
  }
  )

}

/** 检查区域是否属于某个等级 */
export const checkCodeIsLevel = (nodes, code, levels=['district']) => {
  if(typeof levels==='string'){
    levels = [levels]
  }
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (node.regionCode === code) {
      return levels.includes(node.level)
    } else if (node.childList) {
      if (checkCodeIsLevel(node.childList, code, levels)) {
        return true
      }
    }
  }
  return false;
}

/** 清洗查询参数中的日期，从element的date-pcker数据格式变为两个字段的切分
 * filterMap = {参数key:[开始日期key,结束日期key]}
 * params = {key:value} 查询参数
 * targetData  实际丢给后端的查询参数对象
*/
export const changeElDatePickerToTwoField = (filterMap, params,targetData, addTime=false) => {
    Object.keys(filterMap).forEach(i=>{
        // 由于tamplate的mixins里面会有合并参数，所以这里需要修改query和params两个对象
        if(params[i] && params[i].length){
            const value1 = addTime?params[i][0]+' 00:00:00':params[i][0]
            const value2 = addTime?params[i][1]+' 23:59:59':params[i][1]
            targetData[filterMap[i][0]] = value1
            targetData[filterMap[i][1]] = value2
            params[filterMap[i][0]] = value1
            params[filterMap[i][1]] = value2
            delete params[i]
        }else{
            delete params[filterMap[i][0]]
            delete params[filterMap[i][1]]
            delete targetData[filterMap[i][0]]
            delete targetData[filterMap[i][1]]
        }
    })
}


/** 防重复提交
 *  先执行，一定时间内不允许执行了
 */
export const preventRepeat = (fn, delay) => {
    let timer = null;
    return function (...args) {
        if (timer) {
            return
        }
        fn.apply(this, args);
        timer = setTimeout(() => {
            timer = null;
        }, delay);
    };
};

/**
 * 清理数字输入
 * @param {string} value 输入值
 * @param {number} precision 小数点精度
 * @param {number} minValue 最小值
 * @param {number} maxValue 最大值
 * @returns
 * @description 1.移除非数字和非小数点的字符
 *              2.检查小数点的位置
 *              3.检查最大值和最小值
 *              4.如果超过两个长度，但是第一个为0，第二个不为小数点，丢弃0
 */
export const sanitizeNumberInput = (value,precision=2,minValue=0,maxValue=99999) => {
    if(!value){return value}
    // 移除非数字和非小数点的字符
    let sanitizedValue = value.replace(/[^\d.]/g, '');
    // 检查小数点的位置
    const decimalIndex = sanitizedValue.indexOf('.');
    if (decimalIndex !== -1) {
        // 如果小数点不是第一位，且只出现一次，则返回处理后的值
        if (decimalIndex !== 0 && sanitizedValue.indexOf('.', decimalIndex + 1) === -1) {
            // 移除小数点超出精度的部分
          sanitizedValue = sanitizedValue.slice(0, decimalIndex + precision + 1);
        } else {
            // 小数点不符合规则，移除小数点及其后的部分
            sanitizedValue = sanitizedValue.slice(0, decimalIndex);
        }
    }
    // 检查最大值和最小值
    if (sanitizedValue < minValue) {
        sanitizedValue = minValue;
    } else if (sanitizedValue > maxValue) {
        sanitizedValue = maxValue;
    }
    // 如果超过两个长度，但是第一个为0，第二个不为小数点，丢弃0
    if(sanitizedValue.length>1 && sanitizedValue[0]==='0' && sanitizedValue[1]!=='.'){
        sanitizedValue = sanitizedValue.slice(1)
    }
    return sanitizedValue;
}

/** 快递单号输入限制
 * @value 输入值
 * @returns
 * @description 1.移除非数字和非字母的字符
 *              2.全部转换大写
 */
export const sanitizeExpressInput = (value) => {
    if(!value){return value}
    // 移除非数字和非字母的字符
    let sanitizedValue = value.replace(/[^a-zA-Z\d]/g, '');
    // 全部转换大写
    sanitizedValue = sanitizedValue.toUpperCase()
    return sanitizedValue;
}


/**
 * 隐藏手机号操作
 * @param {*} phoneNumber 传入手机号
 * @returns  138*****888
 * /(\d{3})\d{5}(\d{3} 显示前三位和后三位
 * '$1*****$2' 中间五位用*代替
 * replace只能替换字符串，Number类型会报错
 */
export const hidePhone = phoneNumber => {
  // 首先确保phoneNumber是一个字符串
  const phoneStr = String(phoneNumber);
  if (phoneStr && /^\d{11}$/.test(phoneStr)) {
    return phoneStr.replace(/(\d{3})\d{5}(\d{3})/, '$1*****$2');
  } else {
    // 如果不是有效手机号或为null，可以选择返回原值或显示默认文本
    return phoneStr || '';
  }
};

/**
 * @method getValidText
 * @description 过滤空字符串,只过滤null和undefined
 * @param value string | number
 */
export const getValidText = (value, type = 'default') => {
  if (value === undefined || value === null) return '-'
  const unitConfig = {
    price() {
      if (typeof value !== 'number') return value
      return value.toFixed(2)
    },
    priceUnit() {
      return this.price() + '元'
    },
    priceUnitAndDay() {
      return this.price() + '元/天'
    },
    date() {
      return value + '号'
    },
    day() {
      return value + '天'
    },
    ratio() {
      return value + '%'
    },
    periods() {
      return value + '期'
    },
    default() {
      return value
    }
  }
  return unitConfig[type]()
}
