/** 用来获取oss文件和其他外部接口，不需要走axios封装后的逻辑 */
export const httpRequest = (url, method = 'get', data) => {
    const request = new XMLHttpRequest();
    // 组装参数
    if (method === 'get') {
        let params = '';
        if (data) {
            for (let key in data) {
                params += `${key}=${data[key]}&`;
            }
            params = params.slice(0, -1);
        }
        url = `${url}?${params}`;
    }
    if (method === 'post') {
        data = JSON.stringify(data);
    }
    return new Promise((resolve, reject) => {
        request.open(method, url);
        request.send(data);
        setTimeout(() => {
            reject('请求超时');
        }, 10000);
        request.onreadystatechange = () => {
            if (request.readyState == 4 && request.status == 200) {
                const json = JSON.parse(request.responseText);
                resolve(json);
            } else if (request.readyState == 4 && request.status == 404) {
                reject(request.status);
            }

        };
    })
}
