var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-center justify-start gap-6" },
    [
      _c("span", [_vm._v(_vm._s(_vm.labelObj.firstTitle))]),
      _vm.isEdit
        ? _c(
            "el-input-number",
            _vm._b(
              {
                model: {
                  value: _vm.val.min,
                  callback: function ($$v) {
                    _vm.$set(_vm.val, "min", $$v)
                  },
                  expression: "val.min",
                },
              },
              "el-input-number",
              _vm.minProps,
              false
            )
          )
        : _c("span", [_vm._v(_vm._s(_vm.val.min))]),
      _c("span", [_vm._v(_vm._s(_vm.labelObj.secondTitle))]),
      _vm.isEdit
        ? _c(
            "el-input-number",
            _vm._b(
              {
                model: {
                  value: _vm.val.max,
                  callback: function ($$v) {
                    _vm.$set(_vm.val, "max", $$v)
                  },
                  expression: "val.max",
                },
              },
              "el-input-number",
              _vm.maxProps,
              false
            )
          )
        : _c("span", [_vm._v(_vm._s(_vm.val.max))]),
      _c("span", [_vm._v(_vm._s(_vm.labelObj.threeTitle))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }