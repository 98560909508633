/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-06 14:19:47
 * @LastEditTime: 2024-08-28 20:50:11
 * @LastEditors: Ran junlin
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import directives from '@/directives/index'; // 自定义指令
import '@/components';
import 'element-ui/lib/theme-chalk/index.css';
import canvasPoster from 'vue-canvas-poster-yufan';
// 复制
import VueClipboards from 'vue-clipboards';
// 深拷贝
import { deepClone } from './utils/validator';
import { Notification } from 'element-ui';

/**
 * 图片预览
 */
import 'viewerjs/dist/viewer.min.css';
import Viewer from 'viewerjs';
import hevueImgPreview from 'hevue-img-preview';
Vue.use(hevueImgPreview);
Vue.prototype.$viewer = Viewer;

//应用插件
Vue.prototype.$notify = Notification;
// 权限校验
import { hasPermissionFun } from '@/utils/auth';
Vue.prototype.$hasPermission = hasPermissionFun;
// 标记是否手机屏幕
Vue.prototype.$isMobile = window.outerWidth < 768;
Vue.prototype.$deepClone = deepClone;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.window = window;
Vue.config.productionTip = false;
Vue.use(VueClipboards);
Vue.use(canvasPoster);
Vue.use(directives);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
