import Vuex from 'vuex';
import Vue from 'vue';
import { getAllEnums, getChildNodes, getEnabledUserList, getFlowAllEnums, getUserListEnums } from '@/api/home/index';
import { metadataNormal } from '@/api/system/config/business/metadata';
import { getRegionRegion } from '@/api/system/team/company';
import userStore from './modules/user.js';
import enumStore from './modules/enum.js';
import diningTable from './modules/diningTable.js';
import tagNav from '@/components/TagNav/lib/store';
import orderStore from './modules/order.js';
import afterFusion from './modules/afterFusion.js';
import persistedState from 'vuex-persistedstate'; //保存刷新后的状态vuex
import { getSingleEnumsValue } from '@/api/emunSigle';
import { msg } from '@/utils/common';
// import { getEngineerList } from '@/api/order'; // 工程师
// import { _AllEnums } from '@/mock/index';
import {
  getStoreAllEnums,
  getStoreAllFlowAllEnums,
  getStoreAllFlowListEnums,
  getStoreAllListEnums,
  setStoreAllEnums,
  setStoreAllFlowAllEnums,
  setStoreAllFlowListEnums,
  setStoreAllListEnums
} from '@/utils/store';
import { manyObjToListEnums } from '@/config/allEnum';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    tagNav,
    userStore,
    enumStore,
    diningTable,
    afterFusion,
    orderStore
  },
  state: {
    loadEnums: 0,
    AllEnums: {}, // 全局 枚举
    flowEnums: 0,
    FlowAllEnums: {}, // 全局flow 枚举
    OrderAllEnums: {}, // 全局-订单枚举
    loadMetaData: 0,
    MetaDataAllEnums: {}, // 全局 元数据
    regionTree: [], // 全局 省市区
    regionTime: 0,
    OrderFilters: {}, // 订单中使用的枚举
    userListAll: [], // 所有用户枚举
    allEnableList: [], // 所有启用用户枚举
    newAllEnums: getStoreAllEnums() || {},
    newAllListEnums: getStoreAllListEnums() || {},
    newFlowAllEnums: getStoreAllFlowAllEnums() || {}, // flow所有枚举
    newFlowListEnums: getStoreAllFlowListEnums() || {} // flow所有list枚举
  },
  getters: {
    getEnumValue:
      state =>
      (key, childKey = '') => {
        const curEnum = state.AllEnums?.[key] ?? '';
        if (childKey) {
          return curEnum[childKey] ?? '';
        }
        return curEnum;
      }
  },
  mutations: {
    setNewAllEnum(state, payLoad) {
      setStoreAllEnums(payLoad);
      const newObj = manyObjToListEnums(payLoad);
      setStoreAllListEnums(newObj);
      state.newAllEnums = payLoad;
      state.newAllListEnums = newObj;
    },
    setNewFlowEnums(state, payLoad) {
      setStoreAllFlowAllEnums(payLoad);
      const newObj = manyObjToListEnums(payLoad);
      setStoreAllFlowListEnums(newObj);
      state.newFlowAllEnums = payLoad;
      state.newFlowListEnums = newObj;
    }
  },
  actions: {
    waitLoadEnums({ state }, { key = 'loadEnums', success = 2 }) {
      return new Promise(resolve => {
        let timer = setInterval(() => {
          if (state[key] === success) {
            clearInterval(timer);
            resolve();
          }
        }, 100);
      });
    },
    // 获取枚举转换为下拉数据
    async getEnumsToSelect({ state, dispatch }, { key, type = 'AllEnums', labelKey = 'value', valueKey = 'key' }) {
      let arr = state[type][key] || [];
      if (!arr.length) {
        await dispatch('waitLoadEnums', { key: 'loadEnums', success: 2 });
        arr = state[type][key] || [];
      }
      arr = arr.map(item => {
        return {
          label: item[labelKey],
          value: item[valueKey]
        };
      });
      return arr;
    },
    async getRegionTree({ state }) {
      if (state.regionTime && new Date().getTime() - state.regionTime < 1000 * 60 * 60 * 24) {
        console.log('不需要重新获取区域树');
        return;
      }
      const res = await getRegionRegion();
      let list = res.data || [];
      // 区级的childList没有数据就去掉
      for (let i = 0; i < list.length; i++) {
        const province = list[i];
        for (let j = 0; j < province.childList.length; j++) {
          const city = province.childList[j];
          for (let k = 0; k < city.childList.length; k++) {
            const district = city.childList[k];
            district.childList && delete district.childList;
          }
        }
      }
      state.regionTree = list;
      state.regionTime = new Date().getTime();
      return list;
      // console.log(state.regionTree);
    },
    // 获取元数据到下拉菜单
    async getMetaDataToSelect({ state, dispatch }, { key, labelKey = 'value', valueKey = 'key' }) {
      let arr = state.MetaDataAllEnums[key];
      if (!arr) {
        await dispatch('waitLoadEnums', { key: 'loadMetaData', success: 1 });
        arr = state.MetaDataAllEnums[key];
      }
      arr = arr.map(item => {
        return {
          label: item[labelKey],
          value: item[valueKey]
        };
      });
      return arr;
    },
    async getMetaDataAllEnums({ state }) {
      state.loadMetaData = 0;
      const data = await metadataNormal();
      const o = {};
      for (const item of data) {
        o[item.code] = item.items.map(item1 => {
          return {
            value: item1.name,
            key: item1.code
          };
        });
      }

      state.MetaDataAllEnums = o;
      state.loadMetaData = 2;
    },
    async getAllEnums({ state, commit }) {
      state.loadEnums = 0;
      const res = await getAllEnums();
      if (res) {
        state.loadEnums += 1;
        state.AllEnums = res.data;
        commit('setNewAllEnum', res.data);
      }
    },
    async getFlowAllEnums({ state, commit }) {
      state.flowEnums = 0;
      const res = await getFlowAllEnums();
      if (res) {
        state.flowEnums += 1;
        state.FlowAllEnums = res.data;
        commit('setNewFlowEnums', res.data);
      }
    },
    // 获取员工管理 list
    async getUserListEnums({ state }) {
      const { data } = await getUserListEnums({});
      if (data && data.length) {
        state.userListAll = data.map(res => {
          return {
            ...res,
            label: res.name,
            value: res.staffNo
          };
        });
      }
    },
    // 获取所有启用用户
    async getAllEnabledUserList({ state }, payLoad = { customerName: '' }) {
      const { data } = await getEnabledUserList({ ...payLoad });
      if (data && data.length) {
        const arrList = data.map(res => {
          return {
            ...res,
            label: res.orgFullName,
            value: res.tenantNo
          };
        });
        state.allEnableList = arrList;
        return arrList;
      }
    },
    //获取单个枚举
    async getSingleEnumsVal({ state }, key) {
      try {
        const data = await getSingleEnumsValue(key);
        return data;
      } catch (e) {
        console.log(e);
      }
    },
    async getChildNodes({ state }, code) {
      try {
        const data = await getChildNodes(code);
        if (data) {
          const list = data.map(res => {
            return {
              ...res,
              label: res.childNodeName,
              value: res.childNodeCode
            };
          });
          return list;
        }
      } catch (e) {
        msg({
          type: 'error',
          content: e
        });
      }
    }
  },
  plugins: [
    //状态可以持续保存，添加即可
    persistedState({
      storage: window.sessionStorage,
      paths: ['user', 'enum', 'diningTable', 'tagNav', 'orderStore']
    })
  ]
});
