// list枚举转为对象枚举
export const listToObjEnums = (list, key = 'value', valKey = 'label') => {
  let objKey = {};
  if (!list || !list.length) return {};
  list.forEach(res => {
    if (!objKey[res[key]]) {
      objKey[res[key]] = res[valKey];
    }
  });
  return objKey;
};
// 多个枚举进行转换
export const manyEnumsToVal = (val, list, key = 'value', valKey = 'label') => {
  let str = '';
  if (!val) return '';
  try {
    const arr = val.split(',');
    if (arr && arr.length) {
      const objKey = listToObjEnums(list, key, valKey);
      arr.forEach(res => {
        if (objKey[res]) {
          str += objKey[res] + ',';
        }
      });
    }
    if (str) {
      str = str.substr(0, str.length - 1);
    } else {
      str = '-';
    }
    return str;
  } catch (e) {}
};
// 将对象枚举转为list label和value
export const objToListEnums = obj => {
  if (!obj) return [];
  const list = [];
  Object.keys(obj).forEach(res => {
    list.push({
      label: obj[res],
      value: res
    });
  });
  return list;
};
// 多个list枚举对象
export const manyObjToListEnums = enumsObj => {
  if (!enumsObj) return;
  const newObj = {};
  Object.keys(enumsObj).forEach(res => {
    if (!newObj[res]) {
      newObj[res] = objToListEnums(enumsObj[res]);
    }
  });
  return newObj;
};
export const CompanyType = [
  { label: '有限责任公司', value: 'company_YX' },
  { label: '股份有限公司', value: 'company_GF' },
  { label: '合伙企业', value: 'company_HF' },
  { label: '个人独资企业', value: 'company_DZ' },
  { label: '农民专业合作社', value: 'company_HZ' }
];

export const CompanySize = [
  { label: '0-20人', value: 'company0_20' },
  { label: '20-99人', value: 'company20_99' },
  { label: '100-500人', value: 'company100_500' },
  { label: '500-1000人', value: 'company500_1000' },
  { label: '1000人以上', value: 'companyOver1000' }
];

export const StatusColorTag = {
  NORMAL: {
    type: 'success',
    tag: '正常'
  }, //正常
  LOCKED: {
    type: 'info',
    tag: '锁定'
  }, //锁定
  DISABLED: {
    type: 'warning',
    tag: '禁用'
  }, //禁用
  DELETED: {
    type: 'warning',
    tag: '删除'
  } //删除
};
// 客户列表状态枚举
export const CustomerStatus = [
  {
    value: 'NORMAL',
    label: '正常',
    tag: null
  },
  {
    value: 'LOCKED',
    label: '锁定',
    tag: null
  },
  {
    value: 'DISABLED',
    label: '禁用',
    tag: null
  },
  {
    value: 'DELETED',
    label: '删除',
    tag: null
  }
];

// 贷款期限枚举
export const loanTermEnums = [
  { label: '12期', value: 'TWELVE' },
  { label: '24期', value: 'TWENTY_FOUR' },
  { label: '36期', value: 'THIRTY_SIX' },
  { label: '48期', value: 'FORTY_EIGHT' },
  { label: '60期', value: 'SIXTY' }
];

// 抵押方式枚举
export const mortgageMethodEnums = [
  { label: '先抵押后放款', value: 'BEFORE_MORTGAGE' },
  { label: '先放款后抵押', value: 'BEFORE_PAYMENT' }
];
// 抵押类型枚举
export const mortgageType = [
  {
    label: '抵押',
    value: 'MORTGAGE'
  },
  {
    label: '抵押过户抵押',
    value: 'TRANSFER_MORTGAGE'
  }
];

//放款方式
export const paymentMethodEnums = [
  {
    label: '平台垫放',
    value: 'ADVANCE_PAYMENT'
  },
  {
    label: '资方放款',
    value: 'CAPITAL_PAYMENT'
  }
];
// 放款对象
export const paymentObjectEnums = [
  {
    label: '客户本人',
    value: 'ONESELF'
  },
  {
    label: '受托支付第三方',
    value: 'THIRD'
  }
];
// 产品上下架状态
export const productStatusEnums = [
  {
    label: '上架',
    value: 'ON'
  },
  {
    label: '下架',
    value: 'OFF'
  }
];

// 还款方式
export const repaymentMethodEnums = [
  {
    label: '等额本息',
    value: 'DEBX'
  },
  {
    label: '等额本金',
    value: 'DEBJ'
  },
  {
    label: '等本等息',
    value: 'DBDX'
  },
  {
    label: '按月付息到期还本',
    value: 'DQHB'
  }
];
// 单位枚举
export const UNITENUMS = {
  万公里: 'custom-million-kilometers',
  万元: 'custom-thousand-icon',
  元: 'custom-yuan-icon'
};
