<template>
  <div class="custom-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CustomBox',
};
</script>

<style lang="scss" scoped>
.custom-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 12px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px -3px 4px 0px rgba(227, 227, 227, 0.25);
}
</style>
