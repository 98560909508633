<template>
  <div class="flex flex-col justify-center">
    <div class="flex gap-12 flex-center w_320">
      <el-input class="" v-model="tagVal" maxlength="5" placeholder="请输入产品标签" clearable size="small"></el-input>
      <el-button size="mini" @click="addTags">添加</el-button>
    </div>
    <div class="flex flex-wrap gap-12 m_t_12" v-if="newTags && newTags.length">
      <el-tag v-for="(tag, index) in newTags" :key="tag + 'abc'" closable @close="handlerCloseTag(tag, index)">
        {{ tag }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductionTag',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: [String],
      default: ''
    }
  },
  computed: {
    tags: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  },
  data() {
    return {
      tagVal: '',
      newTags: []
    };
  },
  mounted() {
    if (this.tags) {
      const arr = this.tags.split(',');
      this.$set(this.$data, 'newTags', arr);
    }
  },
  methods: {
    addTags() {
      if (!this.tagVal) {
        this.msg({
          type: 'error',
          content: '标签不能为空'
        });
        return;
      }
      if (this.newTags.includes(this.tagVal)) {
        this.msg({
          type: 'error',
          content: '标签不允许重复'
        });
        return;
      }
      this.newTags.push(this.tagVal);
      const valTag = this.newTags.join(',');
      this.$emit('handlerChange', valTag);
      this.tagVal = '';
    },
    handlerCloseTag(tag, index) {
      this.newTags.splice(index, 1);
      let str = this.newTags.join(',');
      this.$emit('handlerChange', str);
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-button {
  height: 32px;
}
</style>
