<!-- 用户操作 组件 -->
<template>
  <el-dialog :visible.sync="view" :title="title" width="480px" append-to-body>
    <div class="dialog-handle-wrap">
      <IForm 
      ref="IForm"
      v-if="isFormComponents" 
      v-model="form" 
      v-bind="configItem" />
      <el-form
        ref="diaHldForm"
        class="form"
        size="small"
        :model="form"
        :rules="rules"
        label-position="left"
        v-else
      >
        <el-form-item v-for="(item, key) in formObject" :key="key" :label="item.name" :prop="key">
            <!-- 展示文本 -->
            <!-- 只读，且值有效 -->
            <span v-if="item.readOnly && form[key]">{{ form[key] }}</span>
            <!-- 文本框 -->
            <el-input v-bind="item.props||{}" v-else-if="item.type == 'text'" v-model="form[key]" placeholder="请输入" />
            <!-- 下拉 -->
            <el-select
                v-else-if="item.type == 'select'"
                v-model="form[key]"
                placeholder="请选择"
                size="small"
                @change="(v) => selcetChange(v,item)"
                v-bind="item.props||{}"
            >
                <el-option
                v-for="op,index in getOptions(item)"
                :key="index"
                :label=" op.value"
                :value=" op.key"
                ></el-option>
            </el-select>
            <!-- 文本域 -->
            <el-input
                v-else-if="item.type == 'textarea'"
                type="textarea"
                v-model="form[key]"
                v-bind="item.props||{}"
                placeholder="请输入"
            />
            <!-- 单选按钮 -->
            <el-radio-group v-bind="item.props||{}" v-else-if="item.type == 'radio'" v-model="form[key]">
                <el-radio
                v-for="op in getOptions(item)"
                :key="op.value"
                :label="op.value"
                :value="op.key"
                ></el-radio>
            </el-radio-group>
            <!-- 区域选择 -->
            <el-cascader
                v-else-if="item.type == 'regiontree' && regionTreeWithoutDistrict"
                v-model="form[key]"
                :props="{
                label: 'regionName',
                value: 'regionCode',
                children: 'childList',
                checkStrictly: false,
                emitPath: false
                }"
                :options="regionTreeWithoutDistrict"
                clearable
                filterable
            ></el-cascader>

            <el-switch
                v-else-if="item.type == 'switch'"
                v-model="form[key]"
                active-value="Y"
                inactive-value="N"
                active-text="是"
                inactive-text="否"
            ></el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" v-loading="pending">
      <el-button size="small" @click="close">取消</el-button>
      <el-button size="small" type="primary" @click="handleSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import {IForm} from 'ipage-element-expand'
export default {
  components:{IForm},
  name: 'DialogHandle',
  props: {
    value: Boolean,
    view: Boolean,
    handleType: {
      type: String,
      default: '',
      required: true,
    },
  },
  data() {
    return {
      form: {},
      rules: {},
      title: '',
      formObject: {},
      pending: false,
      isFormComponents:false,
      configItem:{},  // 传入的完整配置，IForm将直接从这里取参数，避免影响旧业务
    };
  },
  watch: {
    view(v) {
        if(!v){
          this.init();
        }else{
          this.initDialog()
        }
    },
  },
  mounted(){
    
  },
  computed: {
    ...mapState(['AllEnums', 'regionTree']),
    show: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) {
          this.init();
        }
        this.$emit('input', v);
      },
    },
    regionTreeWithoutDistrict() {
      const list = this.$deepClone(this.regionTree);
      // 去掉 区级
      list.forEach((item) => {
        item.childList.forEach((item1) => {
          delete item1.childList;
        });
      });
      return list;
    },
  },
  methods: {
    init() {
      this.form = {};
      this.pending = false;
      this.configItem = {}
      this.isFormComponents = false
    },
    getOptions(item) {
      if (typeof item.options == 'string') {
        return this.AllEnums[item.options];
      } else {
        return item.options || [];
      }
    },
    selcetChange(v, item) {
      if (item.isNeedName) {
        let cur = item.options.find((item) => item.key == v);
        let name = cur?.name || cur?.value;
        this.$set(this.form, item.isNeedName, name);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
