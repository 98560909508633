/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-12 14:28:52
 * @LastEditTime: 2024-08-20 14:19:26
 * @LastEditors: Ran junlin
 */
import config from '@/config/_app';
export default {
  path: '/home',
  name: 'Home',
  meta: {
    role: 'd92cbfd4-932e-4565-8888-a143186e86c9',
    title: '工作台',
    secondaryTitle: config.title,
    icon: 'icon nav icon-font-home',
    hidden: false,
    tag: true
  },
  component: () => import(/* webpackChunkName:"routesHome" */ '@/layout/Main'),
  children: [
    {
      path: 'index',
      name: 'homeIndex',
      meta: {
        role: 'd92cbfd4-932e-4565-8888-a143186e86c9',
        title: '工作台',
        icon: 'icon kd-icon-group',
        hidden: false,
        tag: true
      },
      component: () =>
        import(
          /* webpackChunkName:"routesHomeMgr" */
          '@/views/home/Home.vue'
        )
    }
  ]
};
