<!--
 * @Description: 二次封装El-selected下拉
 * @Author: Ran junlin
 * @Date: 2023-08-14 17:50:48
 * @LastEditTime: 2023-08-16 15:12:58
 * @LastEditors: Ran junlin
-->
<script lang="jsx">
import { FormItem } from "element-ui";
export default {
  components: {  FormItem },
  name: "RFormItem",
  props: {
    ...FormItem.props,
   customerProps: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    //如果默认传入required，则必填，校验规则默认如下；如果要自定义校验规则，需要传入rules规则
    costumerRules() {
      const {required,label,rules} = this.$props.customerProps
      return required&& !rules.length  ? [ { required: true, message: `请选择${label}`, trigger: 'blur' }]:rules
    },
    optionList(){
      const { required, rules, label, prop, error } = this.$props.customerProps;
      return {
        required,
        rules,
        label,
        prop,
        error,
    }}
  },
  render() {
    return (
      <FormItem
        {...{
          props: { ...this.$props, ...this.optionList,rules: this.costumerRules }
        }}
      >
        {Object.keys(this.$slots).map(name => (
        <template slot={name}>{this.$slots[name]}</template>
        ))}
      </FormItem>
    );
  },
};
</script>