<template>
  <div class="flex flex-col flex-center justify-start">
    <el-input size="small" v-model="txtVal"></el-input>
  </div>
</template>

<script>
export default {
  name: 'CustomPassword',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    txtVal: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  }
};
</script>

<style scoped></style>
