/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-26 11:35:23
 * @LastEditTime: 2024-08-28 20:10:07
 * @LastEditors: Ran junlin
 */
import * as http from '@/http';

const getConfig = () => {
  return { $config: { tips: true } };
};

/**
 *
 * @description 进件订单-请款-请款信息-GPS安装信息查询接口
 * @param {string} orderNo 订单编号
 * @returns Promise
 */
export function orderVehicleGps(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderVehicle/${orderNo}/vehicleGps`);
  return http.get(url);
}

/**
 *
 * @param {*} orderNo
 * @returns
 */
export function orderPaymentList(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderPayment/${orderNo}/list`);
  return http.get(url);
}

/**
 *
 * @param {*} orderNo
 * @returns
 */
export function orderTelephoneReviewCollate(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderTelephoneReview/collate/${orderNo}`);
  return http.get(url);
}

/**
 *
 * @param {*} orderNo
 * @returns
 */
export function orderQuotaInformation(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderQuotaInformation/find/${orderNo}`);
  return http.get(url);
}

/**
 * @description 还款计划表
 * @param {*} orderNo
 * @returns
 */
export function orderRepaymentPlanCollate(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderRepaymentPlan/collate/${orderNo}`);
  return http.get(url);
}

/**
 * @description 进件订单-签约-签约信息-合同信息查询
 * @param {*} orderNo
 * @returns
 */
export function orderContractDetail(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/orderContractDetail`);
  return http.get(url);
}

/**
 * @description 待请款-合同信息(签约-签约信息)-放款卡查询接口
 * @param {*} orderNo
 * @returns
 */
export function paymentBankcard(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/paymentBankcard`);
  return http.get(url);
}

/**
 * @description 待请款-合同信息(签约-签约信息)-还款卡查询接口
 * @param {*} orderNo
 * @returns
 */
export function repaymentBankcard(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/repaymentBankcard`);
  return http.get(url);
}

/**
 * @description 待请款-合同信息(签约-签约信息)-签约记录列表查询接口
 * @param {*} orderNo
 * @returns
 */
export function orderContractSigned(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/orderContractSigned`);
  return http.get(url);
}

/**
 * @description 待请款-合同信息(签约-签约信息)-签约记录列表查询接口
 * @param {*} orderNo
 * @returns
 */
export function orderContractSignedDetail(orderNo, signedId) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/orderContractSignedDetail/${signedId}`);
  return http.get(url);
}

/**===================================客户信息============================================ */

/**
 * @description 客户信息-基础信息详情
 * @param {*} customerId
 * @returns
 */
export function orderCustomerBasicInformation(customerId) {
  const url = http.buildServiceUrl('LOAN', `customer/query/${customerId}`);
  return http.get(url);
}

/**
 * @description 客户信息-更新客户信息
 * @param {*} data
 * @returns
 */
export function orderCustomerUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `customer/update`);
  return http.put(url, data);
}

/**
 * @description 客户信息-根据订单编号查询客户信息
 * @param {*} customerId
 * @returns
 */
export function orderCustomerBaseByOrderInformation(orderNo) {
  const url = http.buildServiceUrl('LOAN', `customer/query/${orderNo}/orderNo`);
  return http.get(url);
}

/**
 * @description 客户信息-工作信息详情
 * @param {*} customerId
 * @returns
 */
export function orderCustomerWorkInformation(customerId) {
  const url = http.buildServiceUrl('LOAN', `customer/work/query/${customerId}`);
  return http.get(url);
}

/**
 * @description 客户信息-保存或更新客户工作信息
 * @param {*} customerId
 * @returns
 */
export function orderCustomerWorkSaveOrUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `customer/work/saveOrUpdate`);
  return http.put(url, data);
}

/**
 * @description 客户信息-联系人数量
 * @param {*} customerId
 * @returns
 */
export function orderCustomerContactNumber(orderNo) {
  const url = http.buildServiceUrl('LOAN', `app/order/incoming/contact/count/${orderNo}`);
  return http.get(url);
}

/**
 * @description 客户信息-联系人
 * @param {*} customerId
 * @returns
 */
export function orderCustomerContactInformation(customerId) {
  const url = http.buildServiceUrl('LOAN', `customer/contact/query/${customerId}`);
  return http.get(url);
}

/**
 * @description 保存或更新客户联系人信息
 * @param {*} data
 * @returns
 */
export function orderCustomerContactSaveOrUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `customer/contact/saveOrUpdate`);
  return http.post(url, data);
}

/**
 * @description 保存或更新客户联系人信息
 * @param {*} data
 * @returns
 */
export function orderCustomerContactDelete(id) {
  const url = http.buildServiceUrl('LOAN', `customer/contact/delete/${id}`);
  return http.del(url);
}

/**
 * @description 客户信息-地址
 * @param {*} customerId
 * @returns
 */
export function orderCustomerAddressInformation(customerId) {
  const url = http.buildServiceUrl('LOAN', `customer/address/query/${customerId}`);
  return http.get(url);
}

/**
 * @description 客户信息-添加或更新联系地址信息
 * @param {*} data
 * @returns
 */
export function orderCustomerAddressSaveOrUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `customer/address/saveOrUpdate`);
  return http.post(url, data);
}

/**
 * @description 客户信息-添加或更新联系地址信息
 * @param {*} data
 * @returns
 */
export function orderCustomerAddressDel(id) {
  const url = http.buildServiceUrl('LOAN', `customer/address/delete/${id}`);
  return http.del(url);
}

/**===================================车辆信息============================================ */

/**
 * @description 车辆信息-根据车辆编号查询车辆信息
 * @param {*} vehicleNo
 * @returns
 */
export function orderVehicleBaseInformation(vehicleNo) {
  const url = http.buildServiceUrl('LOAN', `vehicle/query/${vehicleNo}`);
  return http.get(url);
}

/**
 * @description 车辆信息-保存或更新车辆信息
 * @param {*} data
 * @returns
 */
export function orderVehicleBaseSaveOrUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `vehicle/saveOrUpdate`);
  return http.post(url, data);
}

/**
 * @description 车辆信息-保单信息
 * @param {*} customerId
 * @param {*} vehicleNo
 * @returns
 */
export function orderVehicleInsuranceInformation(customerId, vehicleNo) {
  const url = http.buildServiceUrl('LOAN', `vehicle/insurance/query/${customerId}/${vehicleNo}`);
  return http.get(url);
}

/**
 * @description 车辆信息-保存或更新车辆保单信息
 * @param {*} data
 * @returns
 */
export function orderVehicleInsuranceSaveOrUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `vehicle/insurance/saveOrUpdate`);
  return http.post(url, data);
}

/**
 * @description 车辆信息-保单信息
 * @param {*} customerId
 * @param {*} vehicleNo
 * @returns
 */
export function orderVehicleValuationInformation(customerId, vehicleNo) {
  const url = http.buildServiceUrl('LOAN', `vehicle/valuation/query/${customerId}/${vehicleNo}`);
  return http.get(url);
}

/**
 * @description 车辆信息-保单信息
 * @param {*} data
 * @returns
 */
export function orderVehicleValuationSaveOrUpdate(data) {
  const url = http.buildServiceUrl('LOAN', `vehicle/valuation/saveOrUpdate`);
  return http.post(url, data);
}

/**
 * @description 车辆信息-验车影像
 * @param {*} orderNo
 * @returns
 */
export function orderVehicleCustomerInformation(orderNo) {
  const url = http.buildServiceUrl('LOAN', `customerVehicle/image/get/${orderNo}`);
  return http.get(url);
}

/**===================================请款信息============================================ */

/**
 * @description 请款信息-进件订单-请款-请款信息-GPS安装信息查询接口（待签约-GPS下单车辆信息查询接口）
 * @param {*} orderNo
 * @returns
 */
export function orderLoanGpsInformation(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderVehicle/${orderNo}/vehicleGps`);
  return http.get(url);
}

/**
 * @description 请款信息-进件订单-请款-请款信息-抵押信息查询接口（待签约-抵押下单车辆信息查询接口）
 * @param {*} orderNo
 * @returns
 */
export function orderLoanMortgageInformation(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderVehicle/${orderNo}/vehicleMortgage`);
  return http.get(url);
}

/**
 * @description 请款信息-待请款-补录材料-补录材料查询接口
 * @param {*} orderNo
 * @returns
 */
export function orderSuppleMaterials(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderSuppleMaterials/${orderNo}`);
  return http.get(url);
}

/**===================================签约信息============================================ */

/**
 * @description 签约信息-待请款-合同详情信息查询接口
 * @param {*} orderNo
 * @returns
 */
export function orderContractList(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/detail`);
  return http.get(url);
}

/**
 * @description 签约信息-合同信息-签约记录文件清单查看接口
 * @param {*} orderNo
 * @returns
 */
export function orderLoanContractList(orderNo, signedId) {
  const url = http.buildServiceUrl('LOAN', `pc/orderContract/get/${orderNo}/orderContractSignedDetail/${signedId}`);
  return http.get(url);
}

/**===================================放款信息============================================ */

/**
 * @description 放款信息-根据请款编号查询
 * @param {*} orderNo
 * @returns
 */
export function orderLoanPaymentList(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/orderPayment/${orderNo}/list`);
  return http.get(url);
}

/**
 * @description 资方接口-文件补全
 * @param {*} orderNo
 * @returns
 */
export function loanFileCompletion(orderNo) {
  const processNodeEnum = {
    fileCompletion: '2'
  };
  const url = http.buildServiceUrl(
    'LOAN',
    `capital/accountFileQuery?orderNo=${orderNo}&processNode=${processNodeEnum.fileCompletion}`
  );
  return http.get(url);
}

/**
 * @description 资方接口-请求浙商放款接口
 * @param {*} orderNo
 * @returns
 */
export function getLoanApplyListZS(orderNo) {
  const url = http.buildServiceUrl('LOAN', `zs/loanApply/find/${orderNo}`);
  return http.get(url);
}

/**
 * @description 资方接口-请求浙商放款接口
 * @param {*} orderNo
 * @returns
 */
export function saveLoanApplyZS({ orderNo, taskId }) {
  const url = http.buildServiceUrl('LOAN', `capital/${orderNo}/loanApply?taskId=${taskId}`);
  return http.get(url);
}

/**===================================进件详情头部============================================ */

/**
 * @description 签约信息-根据进件订单申请编号查询
 * @param {*} orderNo
 * @returns
 */
export function orderDetail(orderNo) {
  const url = http.buildServiceUrl('LOAN', `pc/order/detail/${orderNo}`);
  return http.get(url);
}

/**===================================进件右侧============================================ */

/**
 * @description 签约信息-根据订单编号和客户编号查询进件材料
 * @param {*} orderNo
 * @returns
 */
export function imageMaterial(orderNo, groupCode) {
  return http.get(
    http.buildServiceUrl('LOAN', `order/image_material/pc/query?orderNo=${orderNo}&modelCode=${groupCode}`)
  );
}

/**
 * @description 签约信息-获取其他类型进件材料
 * @param {*} orderNo
 * @returns
 */
export function getOtherMaterialConfig(orderNo, type) {
  return http.get(
    http.buildServiceUrl('LOAN', `order/image_material/pc/query/otherOrAfterLoan?orderNo=${orderNo}&modelCode=${type}`)
  );
}

/**===================================进件材料保存或提交============================================ */

/**
 * @description 签约信息-进件材料保存或提交
 * @param {*} data
 * @returns
 */
export function materialSave(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/order/material/saveOrSubmit`), data);
}

/**===================================查询流程进度条接口============================================ */

/**
 * @description 签约信息-查询流程进度条接口
 * @param {*} orderNo
 * @returns
 */
export function orderQueryProgress(instanceId) {
  return http.get(http.buildServiceUrl('FLOW', `flow/task/step/${instanceId}`));
}

/**===================================查询流程日志============================================ */

/**
 * @description 签约信息-查询流程日志接口
 * @param {*} instanceId
 * @returns
 */
export function orderFlowTaskLogs(instanceId) {
  return http.get(http.buildServiceUrl('FLOW', `flow/task/log/${instanceId}`));
}

/**===================================详情底部按钮============================================ */

/**
 * @description 签约信息-查询当前节点任务的操作按钮属性列表
 * @param {*} data
 * @returns
 */
export function orderTaskFlowOperations(data) {
  return http.get(http.buildServiceUrl('FLOW', `flow/task/find/operations`), data, getConfig());
}

// 各个状态下的按钮提交接口

/**
 * @description 签约信息-任务取消接口
 * @param {*} data
 * @returns
 */
export function orderTaskFlowCancel(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/cancel`), data, getConfig());
}

/**
 * @description 签约信息-任务加签接口
 * @param {*} data
 * @returns
 */
export function orderTaskFlowCountersign(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/countersign`), data, getConfig());
}

/**
 * @description 签约信息-任务通过接口
 * @param {*} data
 * @returns
 */
export function orderTaskFlowPass(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/pass`), data, getConfig());
}

/**
 * @description 签约信息-任务通过接口 loan端去验证
 * @param {*} data
 * @returns
 */
export function orderTaskLoanPass(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/order/loanPass`), data, getConfig());
}

/**
 * @description 签约信息-任务通过接口
 * @param {*} data
 * @returns
 */
export function orderTaskFlowSubmit(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/submit`), data, getConfig());
}

/**
 * @description 签约信息-任务拒绝接口
 * @param {*} orderNo
 * @returns
 */
export function orderTaskFlowReject(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/reject`), data, getConfig());
}

/**
 * @description 签约信息-任务退回接口
 * @param {*} data
 * @returns
 */
export function orderTaskFlowRollback(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/rollback`), data, getConfig());
}

/**
 * @description 签约信息-任务领取接口
 * @param {*} orderNo
 * @returns
 */
export function orderTaskFlowTask(data) {
  return http.put(http.buildServiceUrl('FLOW', `flow/operate/take`), data, getConfig());
}

/**
 * @description 签约信息-任务转办接口
 * @param {*} orderNo
 * @returns
 */
export function orderTaskFlowTransfer(data) {
  return http.post(http.buildServiceUrl('FLOW', `flow/operate/transfer`), data, getConfig());
}

/**
 * @description 签约信息-新增进件订单电核记录
 * @param {*} orderNo
 * @returns
 */
export function orderTelephoneReviewAdd(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/orderTelephoneReview/add`), data, getConfig());
}

/**
 * @description 签约信息-更新电核记录
 * @param {*} orderNo
 * @returns
 */
export function orderTelephoneReviewEdit(data) {
  return http.put(http.buildServiceUrl('LOAN', `pc/orderTelephoneReview`), data, getConfig());
}

/**
 * @description 待授信-批款信查询
 * @param {*} orderNo
 * @returns
 */
export function orderQuotaInformationFind(orderNo) {
  return http.get(http.buildServiceUrl('LOAN', `pc/orderQuotaInformation/find/${orderNo}`));
}

/**
 * @description 待授信-批款接口
 * @param {*} orderNo
 * @returns
 */
export function orderQuotaInformationSave(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/orderQuotaInformation/save`), data, getConfig());
}

/**
 * @description 待授信-批款接口
 * @param {*} orderNo
 * @returns
 */
export function orderQuotaInformationGetAmount(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/orderQuotaInformation/getAmount`), data, getConfig());
}

/**
 * @description 查询 进件材料 树形结构
 * @param {*} orderNo
 * @returns
 */
export function imageMaterialQuery(data) {
  return http.get(http.buildServiceUrl('LOAN', `form/queryMaterial`), data, getConfig());
}

/**
 * @description 通过任务编号查询当前可退回的节点列表
 * @param {*} data
 * @returns
 */
export function findRollbackNodes(data) {
  return http.get(http.buildServiceUrl('FLOW', `flow/task/find/rollbackNodes`), data, getConfig());
}

/**
 * @description 通过任务编号查询当前可退回的节点列表
 * @param {*} orderNo
 * @returns
 */
export function findOperators(data) {
  return http.get(http.buildServiceUrl('LOAN', `flow/task/find/operators`), data, getConfig());
}

/**
 * @description 通过任务编号查询当前可退回的节点列表
 * @param {*} orderNo
 * @returns
 */
export function findTelephoneAndInformation(data) {
  return http.get(http.buildServiceUrl('LOAN', `pc/orderQuotaInformation/findTelephoneAndInformation`), data);
}

/**
 * @description 删除电核记录
 * @param {*} id
 * @returns
 */
export function orderTelephoneReviewDel(id) {
  return http.del(http.buildServiceUrl('LOAN', `pc/orderTelephoneReview/${id}`));
}

/**
 * @description 订单放款登记
 * @param {*} data
 * @returns
 */
export function orderPaymentSave(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/orderPayment`), data, getConfig());
}

/**
 * @description 待请款-根据类型查询合同详情信息接口
 * @param {*} data
 * @returns
 */
export function orderContractDetailList(data) {
  return http.get(http.buildServiceUrl('LOAN', `pc/orderContract/get/contractType/detail`), data, getConfig());
}

/**
 * @description 检查是否已经放款完成
 * @param {*} orderNo
 * @returns
 */
export function orderPaymentCheckPayment(orderNo) {
  return http.get(http.buildServiceUrl('LOAN', `pc/orderPayment/${orderNo}/checkPayment`), getConfig());
}

/**
 * @description 订单详情的报告查询
 * @param {*} orderNo
 * @returns
 */
export function orderQueryReport(orderNo) {
  return http.get(http.buildServiceUrl('LOAN', `pc/order/${orderNo}/queryReport`), getConfig());
}

/**
 * @description 客户管理-贷款信息
 * @param {*} customerId
 * @returns
 */
export function customerLoanList(customerId) {
  return http.get(http.buildServiceUrl('LOAN', `customer/${customerId}/loan`), getConfig());
}

/**
 * @description 获取错误详情
 * @param {*} orderNo
 * @returns
 */
export function getCapitalAuditResult(orderNo) {
  return http.get(http.buildServiceUrl('LOAN', `pc/order/${orderNo}/getCapitalAuditResult`));
}

/**
 * @description 重试错误
 * @returns
 */
export function capitalAuditRetry(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/order/capitalAuditRetry`), data);
}

/**
 * @description 补录资料
 * @returns
 */
export function capitalReplenish(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/order/capitalReplenish`), data);
}

export function computedVal(data) {
  return http.post(http.buildServiceUrl('LOAN', `pc/orderQuotaInformation/calculateOrderCost`), data);
}
