var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.options, function (item, index) {
      return _c(
        "el-option",
        _vm._b({ key: index + "abc" }, "el-option", item, false)
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }