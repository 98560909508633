/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-12 14:28:52
 * @LastEditTime: 2024-08-13 20:55:10
 * @LastEditors: Ran junlin
 */
import $store from "@/store";
// 基础模板 可在 DetailDialogHandleBox 中根据需求动态调整该结构内容

/** 订单状态
 * "OrdStatusEnum": [
            {
                "key": "NEW",
                "value": "新订单",
                "tag": null
            },
            {
                "key": "PENDING_CLAIM",
                "value": "待领单",
                "tag": null
            },
            {
                "key": "PENDING_DISPATCH",
                "value": "待派单",
                "tag": null
            },
            {
                "key": "NO_MATERIAL",
                "value": "待移交材料",
                "tag": null
            },
            {
                "key": "PENDING_RECEIPT",
                "value": "待接单",
                "tag": null
            },
            {
                "key": "PENDING_SIGN",
                "value": "待开工",
                "tag": null
            },
            {
                "key": "PROCESSING",
                "value": "施工中",
                "tag": null
            },
            {
                "key": "PROCESSING_OK",
                "value": "施工完成",
                "tag": null
            },
            {
                "key": "REJECTED_AUDIT",
                "value": "质检驳回",
                "tag": null
            },
            {
                "key": "REPEAT_AUDIT",
                "value": "重新质检",
                "tag": null
            },
            {
                "key": "DONE",
                "value": "已完成",
                "tag": null
            },
            {
                "key": "CANCELED",
                "value": "已取消",
                "tag": null
            },
            {
                "key": "CANCELED_AUDIT",
                "value": "已取消审核中",
                "tag": null
            },
            {
                "key": "REJECTED_CANCELED",
                "value": "取消驳回",
                "tag": null
            },
            {
                "key": "CHANNEL_PENDING_AUDIT",
                "value": "渠道待审核",
                "tag": null
            },
            {
                "key": "CHANNEL_AUDIT_REJECT",
                "value": "渠道审核驳回",
                "tag": null
            },
            {
                "key": "CHANNEL_AUDIT_OK",
                "value": "渠道已审核",
                "tag": null
            },
            {
                "key": "PAUSED",
                "value": "已暂停",
                "tag": null
            },
            {
                "key": "PAUSE_APPROVING",
                "value": "暂停审核中",
                "tag": null
            },
            {
                "key": "PAUSE_REJECT",
                "value": "暂停驳回",
                "tag": null
            }
        ]
 */

/** 可操作类型
 * "FollowOperate": [
            {
                "key": "PAUSE_ORDER",
                "value": "暂停订单",
                "tag": null
            },
            {
                "key": "REVOKE_ORDER_PAUSE_APPLY",
                "value": "撤销订单暂停申请",
                "tag": null
            },
            {
                "key": "RESTORE_ORDER_STATUS",
                "value": "恢复订单状态",
                "tag": null
            },
            {
                "key": "CONTINUE_PAUSE_ORDER",
                "value": "继续订单暂停申请",
                "tag": null
            },
            {
                "key": "END_ORDER_APPLY",
                "value": "终止订单",
                "tag": null
            },
            {
                "key": "REVOKE_END_ORDER_APPLY",
                "value": "撤销订单终止申请",
                "tag": null
            },
            {
                "key": "CONTINUE_END_ORDER_APPLY",
                "value": "继续申请订单终止",
                "tag": null
            }
        ]
 *
*/
/** 跟进类型
 * {
            "group": "ORDER_FOLLOW_REASON",
            "items": [
                {
                    "id": "96",
                    "code": "ORDER_FOLLOW_1",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "客户改约",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "97",
                    "code": "ORDER_FOLLOW_2",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "工程师忙",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "143",
                    "code": "ORDER_FOLLOW_3",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "工程师嫌距离远",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "144",
                    "code": "ORDER_FOLLOW_4",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "工程师休假",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "145",
                    "code": "ORDER_FOLLOW_5",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "工程师不愿意上门",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "146",
                    "code": "ORDER_FOLLOW_6",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "客户未签完合同",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "147",
                    "code": "ORDER_FOLLOW_7",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "客户未带备用钥匙",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "148",
                    "code": "ORDER_FOLLOW_8",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "缺失GPS设备",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "149",
                    "code": "ORDER_FOLLOW_9",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "缺失抵押三件套",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "150",
                    "code": "ORDER_FOLLOW_10",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "调度营业执照原件",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "151",
                    "code": "ORDER_FOLLOW_11",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "车管所问题",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "152",
                    "code": "ORDER_FOLLOW_12",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "其他",
                    "sort": null,
                    "activeState": "Y"
                },
                {
                    "id": "250",
                    "code": "ORDER_FOLLOW_13",
                    "group": "ORDER_FOLLOW_REASON",
                    "name": "回件逾期",
                    "sort": null,
                    "activeState": "Y"
                }
            ],
            "name": "订单-跟进原因",
            "code": "ORDER_FOLLOW_REASON"
        },
 */

/** 根据订单状态，控制可操作类型列表 和 跟进类型数据*/
/**    ORDER_PLACEMENT("ORDER_PLACEMENT", "挂单","挂单",ProcessDataType.APPLY),
    CANCEL_ORDER_PLACEMENT("CANCEL_ORDER_PLACEMENT", "取消挂单","取消挂单",ProcessDataType.APPLY),
    CONVERT_ORDER_PLACEMENT("CONVERT_ORDER_PLACEMENT", "转为挂单","转为挂单",ProcessDataType.APPLY); */
const operateMaps = {
    NEW:{
        followOperate:[]
    },
    // 待领单:订单暂停，跟进类型选中“订单暂停”并disabled掉
    PENDING_CLAIM:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 待派单
    PENDING_DISPATCH:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 待移交材料
    NO_MATERIAL:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 待接单
    PENDING_RECEIPT:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 待开工
    PENDING_SIGN:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // PROCESSING
    PROCESSING:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 施工完成
    PROCESSING_OK:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 暂停审核中
    PAUSE_APPROVING:{
        followOperate:['CONVERT_ORDER_PLACEMENT', 'REVOKE_ORDER_PAUSE_APPLY']
    },
    // 暂停
    PAUSED:{
        // 暂停暂时隐藏，提供转为挂单
        followOperate:['CONVERT_ORDER_PLACEMENT','RESTORE_ORDER_STATUS']
        // followOperate:['RESTORE_ORDER_STATUS']
    },
    // 暂停驳回
    PAUSE_REJECT:{
        followOperate:['CONVERT_ORDER_PLACEMENT', 'RESTORE_ORDER_STATUS','CONTINUE_PAUSE_ORDER']
    },
    // // 挂单
    // ORDER_PLACEMENT:{
    //     followOperate:['CANCEL_ORDER_PLACEMENT']
    // },
    // 这两个要额外判断另外一个状态
    DONE:{
        followOperate:['END_ORDER_APPLY','REVOKE_END_ORDER_APPLY','CONTINUE_END_ORDER_APPLY']
    },
    // 渠道待审核
    CHANNEL_PENDING_AUDIT:{
        followOperate:['END_ORDER_APPLY','REVOKE_END_ORDER_APPLY','CONTINUE_END_ORDER_APPLY']
    },
    // 质检驳回
    REJECTED_AUDIT:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
    // 重新质检
    REPEAT_AUDIT:{
        // 提供新的挂单跟进项目
        followOperate:['ORDER_PLACEMENT']
        // followOperate:['PAUSE_ORDER']
    },
}
const operateConfirmMaps = {
    PAUSE_ORDER:{
        title:'确定发起暂停订单申请？',
        content:'提示：订单暂停申请后，订单将不能继续处理，并会推送审核'
    },
    REVOKE_ORDER_PAUSE_APPLY:{
        title:'确定恢复暂停前的订单状态？',
        content:'提示：撤销订单暂停申请后，订单会恢复之前的状态'
    },
    RESTORE_ORDER_STATUS:{
        title:'确定恢复暂停前的订单状态？',
        content:''
    },
    CONTINUE_PAUSE_ORDER:{
        title:'确定继续发起订单暂停申请？',
        content:''
    },
    END_ORDER_APPLY:{
        title:'确定发起订单终止申请？',
        content:'提示：订单终止申请后，订单将不能继续处理，并会推送审核'
    },
    REVOKE_END_ORDER_APPLY:{
        title:'确定撤销终止订单？',
        content:'提示：撤销终止订单后，订单会恢复之前的状态'
    },
    CONTINUE_END_ORDER_APPLY:{
        title:'确定继续发起订单终止申请？',
        content:'提示：订单终止申请后，订单将不能继续处理，并会推送审核'
    }
}
/** 根据订单状态过滤可操作列表 */
const clearOperateList = (arr,orderStatus, orderPlacement)=>{
    const full = JSON.parse(JSON.stringify(arr))
    const obj = operateMaps[orderStatus]
    if(!obj){
        return [{label:'无操作',value:undefined}]
    }
    arr = arr.filter(item=>obj.followOperate.includes(item.value))

    if(orderPlacement==='Y'){
        // 从full提取取消挂单添加到可操作arr数组
        const cancelOrderPlacement = full.find(item=>item.value==='CANCEL_ORDER_PLACEMENT')
        if(cancelOrderPlacement){
            arr.push(cancelOrderPlacement)
        }
        // 挂单的订单，把挂单和转挂单丢弃了
        arr = arr.filter(item=>!['ORDER_PLACEMENT','CONVERT_ORDER_PLACEMENT'].includes(item.value))
    }
    arr.unshift({label:'无操作',value:undefined})
    return arr
}

/** 当操作发生变化时，其他单元交互变化 */
const changeOperate = (value,loadData)=>{
    const {qData,data,allItems} = loadData
    const orderStatus = qData.setForm.orderStatus
    const followReason_item = allItems.find(item=>item.id==='followReason')
    console.log(value,'changeoperate',orderStatus,followReason_item);
    followReason_item.props.disabled = false
    if(['PAUSE_ORDER','CONTINUE_PAUSE_ORDER'].includes(value)){
        // 暂停和继续暂停
        data.followReason = 'ORDER_FOLLOW_16'
        data.followReasonName = '订单暂停'
        followReason_item.props.disabled = true
    }else if(value==='REVOKE_ORDER_PAUSE_APPLY'){
        // 撤销订单暂停申请
        data.followReason = 'ORDER_FOLLOW_17'
        data.followReasonName = '撤销订单暂停申请'
        followReason_item.props.disabled = true
    }else if(value==='RESTORE_ORDER_STATUS'){
        // 恢复暂停
        data.followReason = 'ORDER_FOLLOW_18'
        data.followReasonName = '恢复订单'
        followReason_item.props.disabled = true
    }else if(value==='END_ORDER_APPLY'){
        // 终止订单
        data.followReason = 'ORDER_FOLLOW_19'
        data.followReasonName = '终止订单'
        followReason_item.props.disabled = true
    }else if(value==='REVOKE_END_ORDER_APPLY'){
        // 撤销终止订单
        data.followReason = 'ORDER_FOLLOW_20'
        data.followReasonName = '撤销终止订单'
        followReason_item.props.disabled = true
    }else if(value==='CONTINUE_END_ORDER_APPLY'){
        // 继续终止订单
        data.followReason = 'ORDER_FOLLOW_19'
        data.followReasonName = '终止订单'
        followReason_item.props.disabled = true
    }else if(['ORDER_PLACEMENT','CONVERT_ORDER_PLACEMENT'].includes(value)){
        // 挂单和转为挂单
        data.followReason = 'ORDER_FOLLOW_21'
        data.followReasonName = '挂单'
        // followReason_item.props.disabled = true
    }else if(['CANCEL_ORDER_PLACEMENT'].includes(value)){
        // 取消挂单
        data.followReason = 'ORDER_FOLLOW_22'
        data.followReasonName = '取消挂单'
        // followReason_item.props.disabled = true
    }else if(!value){
        data.followReason = ''
        data.followReasonName = ''
        followReason_item.props.disabled = false
    }
}




export default {
  // TODO: field names 根据后端返回设置
  // TODO: options 根据接口或者原型设置
  get(name){
    /** 原本的封装比较简陋，无法满足需求，逐步替换成IPage系列组件
       * 传入的配置全部记录下来，根据$isForm来判断是否是新的表单组件数据集
       * by dxl 2024-03-27
    */
    if(name==='FOLLOW'){
        return {
            title: '填写跟进',
            $isForm:true, // 启用IPage中的表单组件
            formItems:[
                {
                    id:'followOperate',
                    label:'订单操作：',
                    slot:'radio-group',
                    value:undefined,
                    // isButton:true,
                    show:true,
                    options:async(loadData)=>{
                        const {qData} = loadData
                        console.log(JSON.stringify(qData.setForm),'qData.setForm');
                        let arr = await $store.dispatch('getEnumsToSelect',{key:'FollowOperate'})
                        const orderStatus = qData.setForm.orderStatus
                        const orderPlacement = qData.setForm.orderPlacement
                        arr = clearOperateList(arr,orderStatus, orderPlacement)
                        /** 例外处理 */
                        if(['DONE','CHANNEL_PENDING_AUDIT'].includes(orderStatus)){
                            const endStatus = qData.setForm.endStatus
                            if(endStatus==='APPROVAL_PASS'){
                                // 订单已完成并渠道审核通过，不能发起订单操作
                                loadData.item.show = false
                            }else if([null,undefined,'REVOKE'].includes(endStatus)){
                                // 允许发起申请
                                arr = arr.filter(item=>['END_ORDER_APPLY',undefined].includes(item.value))
                            } else if(['IN_APPROVAL'].includes(endStatus)){
                                // 审核中，允许撤销终止申请
                                arr = arr.filter(item=>['REVOKE_END_ORDER_APPLY',undefined].includes(item.value))
                            } else if(['APPROVAL_REJECT'].includes(endStatus)){
                                // 驳回，允许继续申请
                                arr = arr.filter(item=>['CONTINUE_END_ORDER_APPLY',undefined].includes(item.value))
                            }
                        }

                        return arr
                    },
                    on:{
                        change:(value,loadData)=>{
                            console.log('订单操作：',value,loadData);
                            changeOperate(value,loadData)

                        }
                    }
                },
                {
                    id:'followReason',
                    label:'跟进类型：',
                    slot:'select',
                    span:24,
                    props:{
                        disabled:false
                    },
                    on:{
                        change:async (value,loadData)=>{
                            console.log('跟进类型：',value,loadData);
                            const {data} = loadData
                            if(value){
                                const arr = await $store.dispatch('getMetaDataToSelect',{key:'ORDER_FOLLOW_REASON'})
                                data.followReasonName = arr.find(item=>item.value===value)?.label
                            }else{
                                data.followReasonName = ''
                            }
                        }
                    },
                    options:async ()=>{
                        const arr = await $store.dispatch('getMetaDataToSelect',{key:'ORDER_FOLLOW_REASON'})
                        arr.forEach(row=>row.disabled=false)
                        return arr
                    },
                },
                // 跟进说明 followContent
                {
                    id:'followContent',
                    label:'跟进说明：',
                    slot:'input',
                    span:24,
                    props:{
                        type:'textarea',
                        rows:3,
                        maxlength:255
                    }
                },
                // 是否推送渠道
                {
                    id:'pushChannel',
                    label:'是否推送渠道：',
                    slot:'switch',
                    span:24,
                    props:{
                        activeText:'是',
                        inactiveText:'否',
                        activeValue:'Y',
                        inactiveValue:'N'
                    }
                },
                // 是否推送工程师
                {
                    id:'pushEngineer',
                    label:'是否推送工程师：',
                    slot:'switch',
                    span:24,
                    props:{
                        activeText:'是',
                        inactiveText:'否',
                        activeValue:'Y',
                        inactiveValue:'N'
                    }
                },
                // 附件
                {
                    id:'imgUrl',
                    label:'附件',
                    slot:'upload',
                    span:24,
                    props:{
                        width:100,
                        height:100,
                        number:5,
                        multiple:true,
                        enableCropper:false,
                        initUrl:[],
                        fileType:'imgAndPDF'
                    },
                    on:{
                        imgupload:(v,loadData)=>{
                            console.log('imgupload',v,loadData);
                            const {allItems,data} = loadData
                            const item = allItems.find(item=>item.id==='imgUrl')
                            item.props.initUrl = v
                            data.imgUrl = v
                        }
                    }
                }

            ],
            formRules:{
                followReason:[
                    {required:true,message:'请选择跟进类型',trigger:'change'}
                ],
                followContent:[
                    {required:true,message:'请输入',trigger:'blur'},
                    {min:2,max:255,message:'长度在 2 到 255 个字符',trigger:'blur'}
                ]
            },
            formProps:{
                class:'form',
                size:'small',
                labelPosition:'left'
            },
            // 额外添加的检查方法，如果返回reject，就不提交，业务执行查看domUtil.js
            $beforCheckSubmit:async (data,that)=>{
                console.log(data,that,'beforCheckSubmit');
                // 询问用户是否确认
                if(data.followOperate){
                    const obj = operateConfirmMaps[data.followOperate]
                    if(obj){
                        // 需要二次确认的跟进
                        const title = obj.title
                        const content = obj.content
                        await that.$confirm(content, title, {
                            confirmButtonText: '确定并提交',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                    }

                }

                return Promise.resolve()
            },
            submitButton:false,
            cancelButton:false,
        }
    }
  },
  FOLLOW: {
    title: '填写跟进11',
    form: {
      followReason: {
        name: '跟进类型：',
        default: '',
        type: 'select',
        isNeedName: 'followReasonName',
        options: [],
        rule: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      followContent: {
        name: '跟进说明：',
        default: '',
        type: 'textarea',
        rule: [
          { required: true, message: '请输入', trigger: 'blur' },
          {
            min: 5,
            max: 255,
            message: '长度在 5 到 255 个字符',
            trigger: 'blur'
          }
        ]
      },
      pushChannel: {
        name: '是否推送渠道：',
        default: '',
        type: 'switch'
      },
      pushEngineer: {
        name: '是否推送工程师：',
        default: '',
        type: 'switch'
      },
      orderTitleCode:{
        name:'订单标签',
        default:'',
        type:'select',
        options:[],
        rule: [{ required: true, message: '请选择', trigger: 'change' }],
        isNeedName:'orderTitleName'
      },
    }
  },
  DISPATCH: {
    title: '申请资料调度',
    form: {
      applicantName: {
        name: '当前工程师：',
        default: '',
        type: 'text',
        readOnly: true
      },
      areaId: {
        name: '申请区域：',
        default: '',
        type: 'regiontree',
        rule: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      mortgageeNo: {
        name: '抵押权人：',
        default: '',
        type: 'select',
        options: [],
        rule: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      channelNo: {
        name: '隶属渠道：',
        default: '',
        type: 'select',
        options: [],
        rule: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      inventory: {
        name: '资料清单：',
        default: '',
        type: 'text',
        readOnly: true
      }
    }
  },
  LICENSE: {
    title: '申请执照',
    form: {
      applicantName: {
        name: '当前工程师：',
        default: '',
        type: 'text',
        readOnly: true
      },
      areaId: {
        name: '申请区域：',
        default: '',
        type: 'regiontree',
        rule: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      mortgageeNo: {
        name: '抵押权人：',
        default: '',
        isNeedName: 'mortgageeName',
        type: 'select',
        options: [],
        rule: [{ required: true, message: '请选择', trigger: 'blur' }]
      },
      licenseNumber: {
        name: '证照编号：',
        default: '',
        type: 'text',
        readOnly: true
      }
    }
  },
  ISSUEORDER: {
    title: '问题订单',
    form: {
      abnormalType: {
        name: '问题类型：',
        default: '',
        type: 'select',
        options: 'AbnormalTypeEnum',
        rule: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      cause: {
        name: '问题原因：',
        default: '',
        type: 'textarea',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  CANCEL: {
    title: '取消订单',
    form: {
      vehicleOwner: {
        name: '车主姓名：',
        default: '',
        readOnly: true
      },
      orderNo: {
        name: '订单编号：',
        default: '',
        readOnly: true
      },
      vehicleNo: {
        name: '车牌号：',
        default: '',
        readOnly: true
      },
      cause: {
        name: '取消原因：',
        default: '',
        type: 'textarea',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  EQUIPMENT: {
    title: '申请设备',
    form: {
      engineerName: {
        name: '当前工程师：',
        default: '',
        type: 'text',
        readOnly: true
      },
      areaId: {
        name: '申请区域：',
        default: '',
        type: 'regiontree',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      devicePackageStr: {
        name: '设备清单：',
        default: '',
        type: 'text',
        readOnly: true
      }
    }
  },
  REPLY: {
    title: '回复',
    form: {
      replyContent: {
        name: '回复内容：',
        default: '',
        type: 'textarea',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  RECEIPT: {
    title: '分配',
    form: {
      transferToUserNo: {
        name: '选择成员：',
        type: 'select',
        isNeedName: 'transferToUserNickName',
        options: [],
        rule: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  TRANSFERORDER: {
    title: '转单',
    form: {
      transferToUserNo: {
        name: '选择成员：',
        type: 'select',
        isNeedName: 'transferToUserNickName',
        options: [],
        props:{
          filterable:true
        },
        rule: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      transferReason: {
        name: '转单原因：',
        default: '',
        type: 'textarea',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  EDITUSER: {
    title: '修改车主信息',
    form: {
      vehicleOwner: {
        name: '车主姓名：',
        type: 'text',
        default: '',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      vehicleOwnerMobile: {
        name: '车主手机号：',
        type: 'text',
        default: '',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      vehicleNo: {
        name: '车牌号：',
        type: 'text',
        default: '',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      vehicleVinNo: {
        name: '车架号：',
        type: 'text',
        default: '',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      vehicleRegistrationNo: {
        name: '车辆登记证：',
        type: 'text',
        default: '',
        rule: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  }
};
