<template>
  <div>
    <!-- 蒙层 -->
    <div v-if="mask && visible" class="mask-layer" @click="handleMaskClick"></div>
    <div
      v-if="visible"
      class="customer-dialog"
      id="customer-dialog"
      :style="dialogStyles"
      v-dialogDrag:[dialogDrag]
    >
      <div class="dialog-container">
        <div
          class="dialog-header"
          id="dialog-header"
          :style="{
            '--dialog-center': `${center ? 'center' : 'left'}`
          }"
        >
          <slot name="header" v-if="!headerHide">
            <span class="dialog__title">{{ title }}</span>
          </slot>
          <button class="dialog__header-close" @click="handleClose">
            <i class="el-dialog__close el-icon el-icon-close"></i>
          </button>
        </div>
        <div class="dialog-body">
          <slot> </slot>
        </div>
        <div
          class="dialog-footer"
          :style="{
            '--dialog-center': `${center ? 'center' : 'right'}`
          }"
          v-if="!footerHide"
        >
          <slot name="footer">
            <el-button size="small" @click="handleClose">{{ cancelText }}</el-button>
            <el-button
              v-if="!submitHide"
              type="primary"
              show
              :loading="loading"
              size="small"
              @click="onSubmit"
              >{{ saveText }}</el-button
            >
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalDrag',
  props: {
    /**
     * 是否需要是否需要遮罩层
     * @default false
     */
    mask: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: ''
    },
    /**
     * @description 是否开启拖拽功能
     * @default false
     */
    dialogDrag: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 500
    },
    maxHeight: {
      type: [Number, String],
      default: 800
    },
    /**
     * 未传值时候，默认
     * top: 30%
     * 传入值时候，以传入值为准
     */
    top: {
      type: [Number, String],
      default: 0
    },
    /**
     * 未传值时候，默认
     * left: 50%
     * 传入值时候，以传入值为准
     */
    left: {
      type: Number,
      default: 0
    },
    zIndex: {
      type: Number,
      default: 9999
    },
    /**
     * 是否显示
     * @default false
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * 是否居中
     * @default true
     */
    center: {
      type: Boolean,
      default: false
    },
    /**
     * 是否隐藏footer
     * @default false
     */
    footerHide: {
      type: Boolean,
      default: false
    },

    /**
     * 是否隐藏header
     * @default false
     */
    headerHide: {
      type: Boolean,
      default: false
    },
    /**
     * 是否隐藏确定按钮
     * @default false
     */
    submitHide: {
      type: Boolean,
      default: false
    },

    /**
     * 加载状态
     * @default true
     */
    loading: {
      type: Boolean,
      default: false
    },

    saveText: {
      type: String,
      default: '确定'
    },

    cancelText: {
      type: String,
      default: '取消'
    }
  },
  computed: {
    dialogStyles() {
      return {
        '--dialog-width': typeof this.width === 'number' ? `${this.width}px` : this.width,
        '--dialog-max-height':
          typeof this.maxHeight === 'number' ? `${this.maxHeight}px` : this.maxHeight,
        '--dialog-top': this.top
          ? typeof this.top === 'number'
            ? `${this.top}px`
            : this.top
          : '50%',
        '--dialog-left': this.left
          ? typeof this.left === 'number'
            ? `${this.left}px`
            : this.left
          : '50%',
        '--dialog-z-index': this.zIndex
      };
    },
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
    },
    handleClose() {
      this.visible = false;
    },
    handleMaskClick() {
      this.handleClose(); // 点击蒙层时关闭对话框
    }
  }
};
</script>

<style scoped lang="scss">
.customer-dialog {
  width: var(--dialog-width);
  max-width: calc(100vw - 32px);
  max-height: var(--dialog-max-height);
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  list-style: none;
  margin: 0 auto;
  position: fixed;
  top: var(--dialog-top);
  left: var(--dialog-left);
  transform: translate(-50%, -50%);
  overflow: auto;
  margin: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  z-index: var(--dialog-z-index);
  // overflow-y: auto;
}

.dialog-container {
  width: 100%;
  background: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dialog-header {
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    background-color: rgba(230, 233, 240, 0.15);
    border-bottom: 1px #e6e9f0 solid;
    padding: 10px 0;
    .dialog__title {
      font-weight: bold;
      display: block;
      font-size: 1rem;
      font-weight: bold;
      height: 32px;
      line-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 15px;
    }
    .dialog__header-close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 16px;
      &:hover {
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.88);
        background-color: rgba(0, 0, 0, 0.06);
        transition: all 0.3s;
      }
    }
  }
  .dialog-body {
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.5714285714285714;
    word-wrap: break-word;
    flex: 1;
    // padding: 0 20px;
    position: relative;
    height: 500px;
    overflow-y: auto;
  }
}

.dialog-header,
.dialog-body,
.dialog-footer {
  text-align: var(--dialog-center);
  margin-bottom: 10px;
}
.dialog-footer {
  display: flex;
  justify-content: var(--dialog-center);
  align-items: center;
  padding: 0 10px;
}
.footer-title {
  margin-right: 10px;
}

/* 蒙层样式 */
.mask-layer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background: #000;
  z-index: 9998;
}
</style>
