<template>
  <el-dialog
    :visible.sync="value"
    :title="title"
    width="240px"
    @closed="closed"
  >
    <el-menu @select="menuSelect">
      <el-menu-item v-for="(row, index) in enums" :key="index" :index="row.key">
        <span>{{ row.value }}</span>
      </el-menu-item>
    </el-menu>
  </el-dialog>
</template>

<script>
export default {
  mixins: [],
  components: {},
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },

    title: {
      type: String,
      default: () => '请选择',
    },

    enums: Array,

    //
  },
  data() {
    return {};
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 选择
    async menuSelect(v) {
      this.value = false;
      this.$emit('handleSelect', v);
    },

    // 关闭
    closed() {},

    //
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-dialog .el-dialog__body {
  padding: 0;
}
/deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0 20px !important;
}
/deep/.el-menu {
  border-right: none;
}
</style>
