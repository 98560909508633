<template>
  <div class="dialog">
    <el-dialog
      :title="title"
      :visible.sync="value"
      :before-close="close"
      custom-class="transparent-background"
      close-on-press-escape
      append-to-body
      width="60%"
    >
      <el-carousel
        :autoplay="false"
        :initial-index="initialIndex"
        height="70vh"
        ref="carousel"
        @change="onChange"
      >
        <el-carousel-item v-for="(item, index) in renderImages" :key="index" name="index">
          <iframe v-if="item.isPdf" :src="item.url" frameborder="0" />
          <video v-else-if="item.isVideo" :src="item.url" controls />
          <img v-else-if="item.isImage" :src="item.url" />
          <legend v-if="item.title">{{ item.title }}</legend>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    sourceList: {
      type: Array,
      default: () => [],
    },
    // 是否显示缩略图
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '查看影像',
    },
  },
  name: 'SCVideoViewer',
  data() {
    return {
      initialIndex: 0, //轮播默认滑动到第一个
    };
  },
  computed: {
    renderImages() {
      try {
        const { sourceList } = this.$props;
        if (!sourceList || !sourceList.length) return [];
        // 条目格式
        const item = {
          url: null, // 显示使用的文件地址
          downloadUrl: null, // 下载使用的文件地址，不传时，只是显示的 URL 地址
          title: null, // 显示的标题
        };
        return [...sourceList]
          .filter((file) => file.url)
          .map((file) => {
            // 处理显示的 URL
            const _url = file.url;

            // 获取原始文件地址
            const _urlOrg = _url.split('?')[0];

            // 处理下载地址
            const _downloadUrl = (file.downloadUrl || _url).replace(
              /^(https|http):\/\//,
              '//'
            );
            let data = {
              ...item,
              ...file,
              url: _url,
              title: file.title || _url.split('/').pop(),
              downloadUrl: _downloadUrl,
              isImage: /.(jpg|jpeg|png|gif|bmp|webp|svg)$/.test(_urlOrg),
              isPdf: /.(pdf)$/.test(_urlOrg),
              isVideo: /.(mp4|avi|mkv|mov|mpeg|MP4)$/.test(_urlOrg),
            };
            return data;
          });
      } catch (error) {
        return [];
      }
    },
  },
  methods: {
    onChange() {},
  },
};
</script>

<style lang="scss" scoped>
.dialog /deep/.el-dialog__wrapper {
  background-color: rgba(0, 0, 0, 0.8);
}
/deep/.el-carousel__item {
  display: flex;
  justify-content: center;
}
video,
iframe {
  height: 100%;
  width: 100%;
}

img {
  cursor: zoom-in;
  max-height: 100%;
  max-width: 100%;
  transition: all 0.3s ease;
}

legend {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 1rem;
  padding: 4px 8px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>