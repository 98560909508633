<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from '@/config/app';
export default {
  name: 'App',
  watch: {
    $route() {
      this.updateTitle();
    },
  },
  data() {
    return {
      appConfig,
    };
  },
  methods: {
    // 更新页面标题
    updateTitle() {
      const { appConfig } = this;
      const _match = this.$route.matched;
      // 获取路由链路的标题
      const _titles = [..._match].map((part) => part.meta.title).filter((set) => set);
      document.title = [..._titles, appConfig.title].join(' - ');
    },
  },
  mounted() {
    // 更新页面标题
    this.updateTitle();
  },
};
</script>

<style lang="scss">
@import './style/app.scss';
@import './style/fonts/iconfont.css';

/* .disabledLevelOne 禁用它下面的第一级.el-cascader-menu下的label选框 */
.disabledLevelOne .el-cascader-menu:nth-child(1) label.el-radio {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
  display: none;
}
body .el-table th.gutter {
  display: table-cell !important;
}
body .el-table colgroup.gutter {
  display: table-cell !important;
}
</style>
