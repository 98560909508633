<template>
  <el-select v-model="selectValue" v-bind="$attrs" v-on="$listeners">
    <el-option v-for="(item, index) in options" :key="index + 'abc'" v-bind="item"> </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'CustomSelect',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: [String, Array],
    },
    options: {
      type: Array,
      default: () => [
        {
          label: '测试1',
          value: '1',
        },
      ],
    },
  },
  computed: {
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
};
</script>

<style scoped></style>
