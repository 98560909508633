var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=d5180b36&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/.jenkins/workspace/frontend-control/feiyan/test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d5180b36')) {
      api.createRecord('d5180b36', component.options)
    } else {
      api.reload('d5180b36', component.options)
    }
    
  }
}
component.options.__file = "src/components/RenderSelect/index.vue"
export default component.exports