<template>
  <el-cascader v-model="cascaderValue" :options="options" v-bind="$attrs" v-on="$listeners"></el-cascader>
</template>

<script>
export default {
  name: 'CustomCascader',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: [String, Array],
    },
    options: {
      type: Array,
      default: () => [
        {
          value: 'zhinan',
          label: '指南',
          children: [
            {
              value: 'shejiyuanze',
              label: '设计原则',
              children: [
                {
                  value: 'yizhi',
                  label: '一致',
                },
                {
                  value: 'fankui',
                  label: '反馈',
                },
                {
                  value: 'xiaolv',
                  label: '效率',
                },
                {
                  value: 'kekong',
                  label: '可控',
                },
              ],
            },
          ],
        },
      ],
    },
  },
  computed: {
    cascaderValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
};
</script>

<style scoped></style>
