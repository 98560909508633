<template>
  <el-radio-group v-model="selectValue" v-bind="$attrs" v-on="$listeners">
    <el-radio v-for="(item, index) in options" :key="index + 'radio'" :label="item.value">{{ item.label }}</el-radio>
  </el-radio-group>
</template>

<script>
export default {
  name: 'CustomRadio',
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: {
      type: [String, Number]
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  }
};
</script>

<style scoped></style>
