export default {
  bind: el => {
    if (el.textContent && /^\d{11}$/.test(el.textContent)) {
      el.textContent = el.textContent.replace(/(\d{3})\d{5}(\d{3})/, '$1*****$2');
    } else {
      // 如果不是有效手机号或为null，可以选择保留原文本或显示默认文本
      el.textContent = el.textContent || '';
    }
  }
};
