var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "handle-copy", attrs: { title: _vm.tips } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "clipboard",
            rawName: "v-clipboard",
            value: _vm.content,
            expression: "content",
          },
        ],
        staticClass: "handle",
        on: { success: _vm.handleSuccess, error: _vm.handleError },
      },
      [
        _c("div", { staticClass: "expand" }, [_vm._t("default")], 2),
        _c("i", { staticClass: "theme-color iconfont icon-fuzhi" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }