/*
 * @Description:客户管理
 * @Author: Ran junlin
 * @Date: 2024-08-06 10:19:57
 * @LastEditTime: 2024-08-20 14:20:54
 * @LastEditors: Ran junlin
 */
export default {
  path: '/customer',
  name: 'Customer',
  meta: {
    role: '76c47621-6f4b-4c07-b5f3-eeee6e5114d3',
    title: '客户管理',
    icon: 'icon el-icon-s-custom',
    hidden: false,
    tag: true
  },
  component: () => import(/* webpackChunkName:"Customer" */ '@/layout/Main'),
  children: [
    {
      name: 'UserList',
      path: 'userList',
      meta: {
        role: 'b936056b-9502-45c6-aa96-5ce38f946a9c',
        icon: 'icon',
        title: '客户列表',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/customer/userList/index')
    }
  ]
};
