import moment from 'moment';
import * as baseDate from './baseDate';
import * as _ from 'lodash';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';

moment.locale('zh-cn');

/**
 * 节点查询 - 深度优先
 *
 * @param {*} tree
 * @param {*} curKey
 * @param {*} keyField
 * @param {*} childField
 * @param {*} node
 * @returns
 */
export const findCurNodeDeepFirst = (tree, curKey, keyField, childField, node = null) => {
	const stack = [];
	for (const item of tree) {
		if (item) {
			stack.push(item);
			while (stack.length) {
				const temp = stack.pop();

				if (temp[keyField] === curKey) {
					node = temp;
					break;
				}

				const children = temp[childField] || [];
				for (let i = children.length - 1; i >= 0; i--) {
					stack.push(children[i]);
				}
			}
		}
	}
	return node;
};

/**
 * base64转blob
 *
 * @param {*} base64
 * @returns
 */
export const base64ToBlob = (base64) => {
	let audioSrc = base64; // 拼接最终的base64

	let arr = audioSrc.split(',');
	let array = arr[0].match(/:(.*?);/);
	let mime = (array && array.length > 1 ? array[1] : type) || type;
	// 去掉url的头，并转化为byte
	let bytes = window.atob(arr[1]);
	// 处理异常,将ascii码小于0的转换为大于0
	let ab = new ArrayBuffer(bytes.length);
	// 生成视图（直接针对内存）：8位无符号整数，长度1个字节
	let ia = new Uint8Array(ab);
	for (let i = 0; i < bytes.length; i++) {
		ia[i] = bytes.charCodeAt(i);
	}
	return new Blob([ab], {
		type: mime,
	});
};

/**
 * 图片预览
 *
 * @param {Array} arr - 图片数组
 * @param {int} initialViewIndex - 显示第几张
 */
export const previewImg = (arr, initialViewIndex = 0) => {
	const div = window.document.createElement('div');
	arr.forEach((item) => {
		const img = window.document.createElement('img');
		img.src = item;
		div.appendChild(img);
	});
	new Viewer(div, { initialViewIndex }).show();
};

/**
 * 日期格式化
 * @description 基于 moment 的日期格式化
 * @param {Number|String|Date} value
 * @param {String} format 更多配置参考 moment 文档 <https://momentjs.com/docs/>
 * @param {String} unlimitedText
 * @returns String
 */
export const dateFormat = (value, format = 'YYYY-MM-DD HH:mm:ss', unlimitedText = '') => {
	if (!value) return '';
	let date = moment(value).toDate();
	if (Number.isNaN(date.getTime())) return '';
	if (date.getFullYear() === 9999) return unlimitedText;
	return moment(date).format(format);
};

/**
 * 千分位数字格式化
 * @param {Number|String} value
 * @param {int} fixed 修正位数
 * @returns {null|String}
 */
export const amount = (value, fixed = 2) => {
	if (isNaN(value)) return null;
	if ([null, undefined, ''].includes(value)) return null;
	if (value === 0) return parseFloat(0).toFixed(fixed);
	let num = value.toString().replace(/,/g, '');
	num = parseFloat(num || 0);
	num = num.toFixed(+fixed || 0).toString();
	num = num.split('.');
	if (num.length === 1) return num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
	return [num[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'), num[1]].join('.');
};

/**
 * 单元格金额处理
 * @param {Number|String} value
 * @param {int} placeholder 值为空时的占位符
 * @param {int} fixed 修正位数
 * @returns String
 */
export const columnAmount = (value, { placeholder = '-', fixed = 2, prefix = '', suffix = '' } = {}) => {
	if (value === 0) return 0;
	if (isNaN(value)) return placeholder;
	const _amount = amount(value, fixed);
	return _amount === null ? placeholder : prefix + _amount + suffix;
};

// 去除左右空格
export const trimValue = (value) => {
	// 以下参数不予处理

	const _trim = (val) => val.trimLeft().trimRight();

	// 字符串直接过滤
	if (typeof value === 'string') return _trim(value);

	// 对象递归过滤
	if (Object.prototype.toString.call(value) === '[object Object]') {
		for (const key in value) {
			if (Object.prototype.hasOwnProperty.call(value, key)) {
				let _value = value[key];
				if (typeof _value === 'string') {
					value[key] = trimValue(_value);
				}
			}
		}
		return value;
	}

	// 数组
	if (Array.isArray(value)) {
		value = [...value].map((set) => trimValue(set));
	}

	// 其他不予处理
	return value;
};

// 去除空值参数，包含空字符串、空数组、空对象
// value 仅支持 对象 和 数组 类型
export const removeEmpty = (value) => {
	const _empty = (data) => {
		if (data === '') return true;
		if (Array.isArray(data) && !data.length) return true;
		if (JSON.stringify(data) === '{}') return true;
		return false;
	};

	if (value instanceof Object) {
		for (const key in value) {
			if (Object.prototype.hasOwnProperty.call(value, key) && _empty(value[key])) {
				delete value[key];
			}
		}
		return value;
	} else if (Array.isArray(value)) {
		// 数组处理
		return [...value].filter((set) => !_empty(set));
	} else {
		return value;
	}
};

// 传入指定字符，和字符串
// 帮字符串删掉最后一个指定字符
export const removeLastCharFromString = (char, str) => {
	let lastIndex = str.lastIndexOf(char);

	if (lastIndex > -1) {
		let string = str.substring(0, lastIndex) + str.substring(lastIndex + 1, str.length);
		return string;
	}
};

// 手机号脱敏
export const operatePhoneNumb = (str) => {
	var pat = /(\d{3})\d*(\d{4})/;
	var b = str.replace(pat, '$1****$2');
	return b;
};

/**
 * 根据枚举 key 返回 value
 * @param {*} key
 * @param {*} enums
 */
export const enumMessage = (key, enums) => {
	if (!enums || !enums.length) {
		return key;
	}
	for (const $enum of enums) {
		if ($enum.key === key) {
			return $enum.value;
		}
	}
	if (/,/.test(key)) {
		const keyArr = key.split(',');
		return keyArr.map((item) => enumMessage(item, enums)).join(',');
	}
	return key;
};

/**
 * 默认横杠
 * @param {*} value
 */
export const dash = (value, placeholder = '-') => {
	if (value == null || _.trim(value) === '') {
		return placeholder;
	}
	return value;
};

/**
 * 来源filter
 * @param {*} value
 */
export const sourseFilter = (value) => {
	let sourse = baseDate.fromList.find((item) => item.key == value);
	return sourse ? sourse.value : '';
};

/**
 * 订单类型filter
 * @param {*} value
 */
export const typeListFilter = (value) => {
	let sourse = baseDate.typeListShow.find((item) => item.key == value);
	return sourse ? sourse.value : '';
};

/**
 * 使用状态filter
 * @param {*} value
 */
export const useStatusFilter = (value) => {
	let sourse = baseDate.useStatus.find((item) => item.key == value);
	return sourse ? sourse.value : '';
};

/**
 * 从 window.location.href 中获取指定key的value
 * @param {*} key 要获取的 key
 * @returns window.location.href 中指定key对应的value
 * @example
 * const value = getUrlParam(key);
 */
export function getUrlParam (key, href = window.location.href) {
	const url = decodeURI(href.replace(/^[^?]*\?/, ''));
	const regexp = new RegExp(`(^|&)${key}=([^&#]*)(&|$|)`, 'i');
	const paramMatch = url.match(regexp);

	return paramMatch ? paramMatch[2] : null;
}

/** 深度获取字段渲染，支持.分割，比如abc.fff 表示获取abc下fff字段 */
export function fieldDeepText (row, path, space = '-') {
	const arr = path.split('.');
	let value = row;
	for (let i = 0; i < arr.length; i++) {
		const key = arr[i];
		if (value[key]) {
			value = value[key];
		} else {
			return space;
		}
	}
	return value || space;
}

/** 合并一些字段，单个key支持.分割 */
export function merageValueString (row, keyList, interval = ' ', space = '-', keepSpace = true) {
	const arr = [];
	keyList.forEach((key) => {
		const str = fieldDeepText(row, key, space);
		if (!keepSpace && str !== space) {
			// pass
		} else {
			arr.push(str);
		}
	});
	return arr.join(interval);
}
// 图片地址转为base64
export const imgUrlToBase64 = (image) => {
	let canvas = document.createElement('canvas');
	canvas.width = image.width;
	canvas.height = image.height;
	let context = canvas.getContext('2d');
	context.drawImage(image, 0, 0, image.width, image.height);
	console.log(context, 'context=---context');
	// 将canvas的内容转换为base64编码的字符串
	let base64 = canvas.toDataURL('image/png'); // 可以根据需要更改为其他格式，如'image/jpeg'等
	return base64;
};

// blob to base64
export const blobToBase64 = (blob) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = (e) => {
			resolve(e.target.result);
		};
		// readAsDataURL
		fileReader.readAsDataURL(blob);
		fileReader.onerror = () => {
			reject(new Error('blobToBase64 error'));
		};
	});
};
// base64 to blob
export function data64ToBlob (base64) {
	let arr = base64.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], { type: mime });
}


//json数组根据某一属分组
export function groupByKey (array, key) {
	if (array.length === 0) {
		return {}
	}
	return array.reduce((result, currentValue) => {
		// 获取当前项的分组字段的值
		const groupKey = currentValue[key];
		// 如果结果对象中还没有该分组字段，则创建一个新数组
		if (!result[groupKey]) {
			result[groupKey] = [];
		}
		// 将当前项添加到对应的分组数组中
		result[groupKey].push(currentValue);
		return result;
	}, {}); // 初始化为一个空对象
}
