import {
  getUserInfo
  // getBaseConfig
} from '@/api/sign';
import Vue from 'vue';
import { getTenantNo } from '@/utils/app';
import { getTenantCompany } from '@/api/common';

export default {
  namespaced: true,
  state() {
    return {
      user: {}, // 已登录用户
      userInfo: {}, // 已登录用户
      userconfig: {} // 已登录用户配置
    };
  },
  mutations: {
    // 设置用户信息
    setUser(state, user) {
      state.user = user;
    },
    removeUser(state) {
      state.user = state.userInfo = {};
    },
    update(state, config) {
      Object.keys(config).map(item => {
        Vue.set(state, item, config[item]);
        console.log(item, config[item], '写数据');
      });
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      /* getBaseConfig().then(data => {
        commit('update', {
          userconfig: data
        });
      }); */
      const { data: logoInfo } = await getTenantCompany(getTenantNo());
      await getUserInfo().then(res => {
        let data = res.data || {};
        let { merchant, org, userDetails } = data;
        data.identification = merchant || org || false;
        const newObj = {
          ...data,
          ...userDetails,
          logo: logoInfo?.logo ?? ''
        };
        commit('update', {
          userInfo: newObj
        });
      });
    },
    setUserByAction(context, user) {
      context.commit('setUser', user);
    },
    removeUserByAction(context) {
      context.commit('removeUser');
    },
    setUserconfigByAction(context, v) {
      context.commit('update', { userconfig: v });
    }
  }
};
