<template>
  <div class="flex">
    <span>{{ config.https }}</span>
    <el-input v-model="getVal" size="small" clearable placeholder="请输入域名"></el-input>
    <span>{{ config.domains }}</span>
  </div>
</template>

<script>
import config from '@/config/config';
export default {
  name: 'DomainInput',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    getVal: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
  data() {
    return {
      config,
    };
  },
};
</script>

<style scoped></style>
