<template>
  <div class="flex flex-col gap-12" :class="contentClass">
    <TitleForm v-if="isShowTittle" :title-name="titleName"></TitleForm>
    <div :class="formClass">
      <slot>
        <CustomForm
          class="item-form-custom"
          ref="customFormRef"
          v-model="formData"
          :form-column="formColumn"
          :rules.sync="rules"
          v-bind="$attrs"
          v-on="$listeners"
        ></CustomForm>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomItemForm',
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    titleName: {
      type: String,
      default: '车E融A'
    },
    formColumn: {
      type: Array,
      default: () => []
    },
    isShowTittle: {
      type: Boolean,
      default: true
    },
    contentClass: {
      type: String,
      default: ''
    },
    formClass: {
      type: String,
      default: ''
    },
    rules: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    formData: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  },
  methods: {
    getFormRef() {
      return this.$refs.customFormRef.getFormRef();
    }
  }
};
</script>

<style lang="scss" scoped>
.item-form-custom {
  /deep/.el-form-item {
    margin-bottom: 0px;
  }
}
</style>
