var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.value,
            "before-close": _vm.close,
            "custom-class": "transparent-background",
            "close-on-press-escape": "",
            "append-to-body": "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.value = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                autoplay: false,
                "initial-index": _vm.initialIndex,
                height: "70vh",
              },
              on: { change: _vm.onChange },
            },
            _vm._l(_vm.renderImages, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  item.isPdf
                    ? _c("iframe", {
                        attrs: { src: item.url, frameborder: "0" },
                      })
                    : item.isVideo
                    ? _c("video", { attrs: { src: item.url, controls: "" } })
                    : item.isImage
                    ? _c("img", { attrs: { src: item.url } })
                    : _vm._e(),
                  item.title
                    ? _c("legend", [_vm._v(_vm._s(item.title))])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }