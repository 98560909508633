import Vue from 'vue';
import amount from './amount';
import watermark from './watermark';
import express from './express';
import hidePhone from './hidePhone';
import { elDraggableDialog } from './elDraggableDialog';
import dialogDrag from './customerDialogDrag';
import formatNumber from './formatNumber';
import confirm from './confirm';

const directive = {
  amount,
  watermark,
  express,
  hidePhone,
  elDraggableDialog,
  dialogDrag,
  formatNumber,
  confirm
};

export default {
  install() {
    Object.keys(directive).forEach((res) => {
      Vue.directive(res, directive[res]);
    });
  },
};
