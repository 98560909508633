/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2023-07-26 15:29:26
 * @LastEditTime: 2024-02-20 18:30:53
 * @LastEditors: Ran junlin
 */
import comps from './comps';
import config from '../config/config';
import Vue from 'vue';
import CustomComponents from '@/components/CustomForm/components/index';
import { Message, MessageBox } from 'element-ui';
import { msg, copyText } from '@/utils/common';
// import '@/icons/index';
// UI
import 'element-ui/lib/theme-chalk/index.css';
import '../style/ui/ui_extend.scss';
import '../style/ui/ui_overwrite.scss';
import { showPicker, SourceCropper, dialogHandle } from '@/utils/domUtil';

Vue.prototype.$ELEMENT = { size: 'small' }; // 设置全局默认尺寸

export function installGlobalComponents() {
  for (const comp in comps) {
    if (Object.prototype.hasOwnProperty.call(comps, comp)) {
      const item = comps[comp];
      if (!item.name || item.name == 'InfiniteScroll') {
        Vue.use(item);
      } else {
        Vue.component(item.name, item);
      }
    }
  }
  Vue.prototype.$config = config;
  Vue.prototype.$EventBus = new Vue();
  // 注册自定义CustomForm所需组件
  Vue.use(CustomComponents);
  addMethods();
}

function addMethods() {
  Vue.prototype.msg = msg;
  Vue.prototype.$copyText = copyText;
  Vue.prototype.$message = Message;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$SourceCropper = SourceCropper;
  Vue.prototype.$dialogHandle = dialogHandle;
  Vue.prototype.$showPicker = showPicker;
}

installGlobalComponents();
