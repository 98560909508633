<template>
    <div class="mobile-hide-button-box">
        <el-popover
            :placement="placement"
            :width="width"
            v-if="isMobile"
            trigger="click">
            <slot>
                
            </slot>
            <el-button v-bind="buttonProps" :class="buttonClass" slot="reference">{{buttonLabel}}</el-button>
        </el-popover>
        <slot v-else>
            
        </slot>

    </div>
</template>

<script>
export default {
    props:{
        placement:{
            type:String,
            default:'buttom'
        },
        width:{
            type:String,
            default:'400'
        },
        buttonClass:{
            type:String,
            default:''
        },
        buttonProps:{
            type:Object,
            default:()=>{
                return {
                    round:true,
                    type:"primary"
                }
            }
        },
        buttonLabel:{
            type:String,
            default:'click'
        }
    },
    computed:{
        isMobile(){
            return window.outerWidth < 768;
        }
    }
}
</script>

<style>
.mobile-hide-button-box {
    background-color: rgba(0,0,0,0);
    width: 100%;
} 
</style>