/*
 * @Description:
 * @Author: Ran junlin
 * @Date: 2024-08-12 14:28:52
 * @LastEditTime: 2024-08-14 19:58:40
 * @LastEditors: Ran junlin
 */
// HTTP 请求
import axios from 'axios';
import _needRelogin from '@/http/interceptor/common/needRelogin';
import refreshTokenFetch from '@/http/interceptor/common/refreshToken';
import http from '@/config/http';
import { Message } from 'element-ui';
import * as httpConfig from '@/http';

/**
 * 以下接口即使错误了，也要进业务层处理
 */
const WHITE_URL = [httpConfig.buildServiceUrl('OAUTH', 'authority/password-login')]; //'/oauth/hl/v1/api/user/loginWithPhoneAndPwd'

// 响应拦截器
axios.interceptors.response.use(
  res => {
    const data = res.data;
    // 如果响应包含 'code' 字段，并且 code 不等于 0 ，并且该 URL 不在白名单内
    if (Object.hasOwnProperty.call(data, 'code') && res.data.code != http.successCode) {
      const { $config = {} } = res.config;
      const { tips } = $config;
      if (tips) {
        Message.error(res.data.message || '系统繁忙，请稍后重试！');
      }
      switch (data.code) {
        case 401:
          _needRelogin();
          break;
        default:
          break;
      }
      throw res.data.message; // 统一抛出错误信息，终止执行
    }
    return data;
  },
  async error => {
    //error 函数处理的是 HTTP status 请求出现的错误情况
    console.log(error);
    const originalRequest = error.config;
    let message = '';
    if (error.__CANCEL__) {
      message = '请求被取消';
    } else if (/timeout/.test(error)) {
      message = '接口响应超时，请重试！';
    } else if (!error.response) {
      message = '服务器无响应';
    } else if (!error.response.status) {
      message = '未知错误，请刷新页面！';
    } else if (401 === error.response.status) {
      _needRelogin();
      message = '登录信息无效，请重新登录！';
    } else if (404 === error.response.status) {
      message = `${error.request.responseURL} 接口不存在`;
    } else if (!error.response.data) {
      message = error.response.toString();
    } else {
      const { code = '' } = error.response.data;
      if (code === 401) {
        if (!originalRequest._retry) {
          originalRequest._retry = true; // 标记已经重试过
          await refreshTokenFetch(axios, originalRequest);
          return axios(originalRequest); // 返回重新发送的请求
        }
        //401不需要提示msg
        return { data: {} };
      }

      message =
        error.response.data.message ||
        error.response.data.message ||
        error.response.data.error_description ||
        `服务器未知错误，请联系管理员！` ||
        error.toString();
    }
    const { $config = {} } = error.config || {};
    const { tips } = $config;
    if (tips) {
      Message.error(message);
    }
    return Promise.reject(message);
  }
);
