var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      attrs: {
        visible: _vm.visible,
        titleName: _vm.titleName,
        width: "880px",
        "append-to-body": "",
        "modal-append-to-body": "",
      },
      on: { handlerClose: _vm.handlerClose, handlerSubmit: _vm.handlerSubmit },
    },
    [
      _c("CustomForm", {
        ref: "customFormRef",
        attrs: { rules: _vm.rules, "form-column": _vm.formColumn },
        on: {
          "update:rules": function ($event) {
            _vm.rules = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "descSlot",
            fn: function () {
              return [
                _c("CustomUeditorWrap", {
                  model: {
                    value: _vm.formData.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }