import Vue from 'vue';
import { message } from 'element-ui';

export const pickerOptions = {
  // disabledDate (time) {
  //   return time.getTime() > Date.now() // 选当前时间之前的时间
  // },
  shortcuts: [
    {
      text: '最近一天',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近一周',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近一个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '最近三个月',
      onClick(picker) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }
  ]
};

export const units = [
  { value: 'UNIT_GRAM', label: '克' },
  { value: 'UNIT_KILOGRAM', label: '千克' },
  { value: 'UNIT_ONE', label: '个' },
  { value: 'UNIT_SET', label: '台' },
  { value: 'UNIT_SUIT', label: '套' },
  { value: 'UNIT_T', label: '吨' }
];

export const useLikeAjax = (data, delay) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(data);
    }, delay);
  });
};

const PHONE_REGEXP = /^1\d{10}$/;
export const validatePhone = (rule, value, callback) => {
  if (!value) {
    // 如果表单项为空，则直接返回成功
    callback();
    return;
  }

  if (!PHONE_REGEXP.test(value)) {
    // 如果表单项不为空且不是合法的手机号格式，则返回错误提示信息
    callback(new Error('请输入正确的手机号码'));
    return;
  }
  // 如果表单项不为空且是合法的手机号格式，则返回成功
  callback();
};

const ANY_PHONE_REGEXP = /[0-9]/;
export const validateAnyNumPhone = (rule, value, callback) => {
  if (!value) {
    // 如果表单项为空，则直接返回成功
    callback();
    return;
  }

  if (!ANY_PHONE_REGEXP.test(value)) {
    // 如果表单项不为空且不是合法的手机号格式，则返回错误提示信息
    callback(new Error('请输入正确的手机号码'));
    return;
  }
  // 如果表单项不为空且是合法的手机号格式，则返回成功
  callback();
};

// 选择最后一级树后关闭面板
export const closeTreeModal = (value, _this, refName = 'myCascader') => {
  _this.$nextTick(() => {
    console.log(value);
    if (value && !!value.length) {
      const refInstance = _this.$refs[refName];
      const label = refInstance.getCheckedNodes()[0];
      // 没有子节点了，直接关闭弹框
      if (!label.hasChildren) {
        refInstance.toggleDropDownVisible();
      }
    }
  });
};

// 剔除最后一层树形为children:[]的情况
export const cleanTreeData = data => {
  return data.filter(item => {
    if (item?.children && item?.children.length === 0) {
      delete item.children;
    } else if (item?.children && item?.children.length > 0) {
      item.children = cleanTreeData(item.children);
    }
    return true;
  });
};

/**
 * 获取treeData 的ParentNode,
 * return nodeList || empty
 */

export const getParentNode = (parentId, data) => {
  const parent = findParent(parentId, data);
  if (parent) {
    return parent;
  }
  return {};
};

/**
 * 获取treeData 的findParent
 */

export const findParent = (parentId, data) => {
  // console.log(data);
  for (let i = 0; i < data.length; i++) {
    if (data[i].regionCode === parentId) {
      return data[i];
    }
    if (data[i].childList && data[i].childList.length > 0) {
      const parent = findParent(parentId, data[i].childList);
      if (parent) {
        return parent;
      }
    }
  }
  return null;
};

/**
 * 根据code查询完整路径
 * @param {*} options 城市树
 * @param {*} value 选择的最后一级code
 * @param {*} path
 * @returns
 */
export const findFullPath = (options, value, path = []) => {
  for (const option of options) {
    if (option.regionCode === value) {
      path.push({ regionName: option.regionName, regionCode: option.regionCode });
      return path;
    }
    if (option.childList) {
      const foundPath = findFullPath(
        option.childList,
        value,
        path.concat({ regionName: option.regionName, regionCode: option.regionCode })
      );
      if (foundPath) return foundPath;
    }
  }
  return null;
};

/**
 * 防抖
 */
export const debounce = (fn, delay = 1000) => {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
};

// 生成唯一id
export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * 时间类型
 */
export const paymentWay = [
  { value: '微信', key: '微信', tag: '' },
  { value: '支付宝', key: '支付宝', tag: '' },
  { value: '转账', key: '转账', tag: '' }
];

export const statusColor = {
  invalidColor: '#D9001B', //未提交 已作废
  bandColor: '#ff6867', // 已撤回、已拒收
  pendingColor: '#FEA826', // 待缴费、已驳回、待签收、待验收、待退货、待退库、待出库、待推送
  authColor: '#00b3a7', // 待确认、审核中、待接收、待入账、出库中、计划中、处理中、付款中
  dealColor: '#FEA826', // 待执行、待审核、待记账、待处理 、已提交、待付款、待接收
  finishColor: '#70B603', // 已确认、已执行、已签收、已通过、已记账、已处理、已接收、已退库、已入账、已出库、已计划、已付款
  closeColor: '#cccccc'
};

// const {
//   bandColor,
//   pendingColor,
//   authColor,
//   dealColor,
//   finishColor,
//   invalidColor
// } = statusColor;

// 订单状态
export const orderStatusObj = {
  toBePush: { value: 1, label: '待推送', color: statusColor.pendingColor },
  toReceive: { value: 2, label: '待接收', color: statusColor.authColor },
  toDismissed: { value: 5, label: '已作废', color: statusColor.invalidColor },
  toHasSend: { value: 4, label: '送货中', color: statusColor.finishColor },
  toBeSend: { value: 3, label: '待送货', color: statusColor.pendingColor },
  toBereturned: { value: 6, label: '已退货', color: statusColor.invalidColor },
  toRejected: { value: 7, label: '已拒收', color: statusColor.invalidColor },
  toBeenStored: { value: 8, label: '已入库', color: statusColor.finishColor },
  toStored: { value: 9, label: '待入库', color: statusColor.pendingColor }
};

//归档状态
export const ArchiveStatusEumObj = {
  PENDING: { value: 'PENDING', label: '待归档', color: statusColor.pendingColor, type: 'warning' },
  EXCEPTION: {
    value: 'EXCEPTION',
    label: '归档异常',
    color: statusColor.authColor,
    type: 'danger'
  },
  DONE: { value: 'DONE', label: '已归档', color: statusColor.invalidColor, type: 'success' },
  WRITE_OFFS: { value: 'WRITE_OFFS', label: '已核销', color: statusColor.finishColor, type: '' }
};
// 审核状态
export const RechargeStatus = [
  {
    value: '1',
    label: '提交审核',
    color: statusColor.authColor
  },
  {
    value: '2',
    label: '审核驳回',
    color: statusColor.invalidColor
  },
  {
    value: '3',
    label: '重新提交审核',
    color: statusColor.pendingColor
  },
  {
    value: '4',
    label: '审核通过',
    color: statusColor.finishColor
  }
];

// 审核状态reimbursementApproval
export const reimbursementStatus = [
  {
    value: '1',
    label: '提交审核',
    color: statusColor.authColor
  },
  {
    value: '2',
    label: '审核驳回',
    color: statusColor.invalidColor
  },
  {
    value: '3',
    label: '重新提交审核',
    color: statusColor.pendingColor
  },
  {
    value: '4',
    label: '审核同意',
    color: statusColor.finishColor
  },
  {
    value: '4',
    label: '关闭报销单',
    color: statusColor.closeColor
  }
];

/**
 * 根据某个字段去重
 * @param {*} arr
 * @param {*} val
 * @returns
 */
export const unique = (arr, val) => {
  const res = new Map();
  return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1));
};

/**
 * 根据某个字段去重
 * @param {*} dividend
 * @param {*} divisor
 * @returns
 */
export const hasRemainder = (dividend, divisor) => {
  return dividend % divisor !== 0;
};

export const getTodayPeriod = () => {
  // 获取当前日期和时间
  const currentDateTime = new Date();

  // 设置当前时间为午夜 00:00:00
  const startTime = new Date(currentDateTime);
  startTime.setHours(0, 0, 0, 0);

  // 格式化时间为 'yyyy-MM-dd HH:mm:ss' 格式
  const formatTime = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(currentDateTime);
  return [formattedStartTime, formattedEndTime];
};

/**
 * 获取当月初到月末时间
 * @param {*} isStr
 * @returns
 */
export const getCurrentMonth = (isStr = false) => {
  const formatDate = date => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day} 00:00:00`;
  };

  const now = new Date();
  const startDate = formatDate(new Date(now.getFullYear(), now.getMonth(), 1));
  const endDate = formatDate(new Date(now.getFullYear(), now.getMonth() + 1, 0));

  if (isStr) {
    return {
      startDate,
      endDate
    };
  } else {
    return [startDate, endDate];
  }
};

// /**
//  * 获取当前前一个月时间
//  * @param {*} isStr
//  * @returns
//  */
export const getBeforeOneMonthDay = () => {
  Date.prototype.format = function (format) {
    var args = {
      'M+': this.getMonth() + 1,
      'd+': this.getDate(),
      'h+': this.getHours(),
      'm+': this.getMinutes(),
      's+': this.getSeconds(),
      'q+': Math.floor((this.getMonth() + 3) / 3), //quarter

      S: this.getMilliseconds()
    };
    if (/(y+)/.test(format))
      format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var i in args) {
      var n = args[i];

      if (new RegExp('(' + i + ')').test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1 ? n : ('00' + n).substr(('' + n).length)
        );
    }
    return format;
  };
  var curDate = new Date();
  var stringDate = new Date(curDate.getTime() - 24 * 60 * 60 * 1000 * 30).format(
    'yyyy-MM-dd hh:mm:ss'
  );
  return stringDate;
};

/* 获取当前时间凌晨到现在 */
// 2023-09-26 00:00:00  - 2023-09-26 09:43:37
export const getCurrentTime = () => {
  Date.prototype.format = function (format) {
    var args = {
      'M+': this.getMonth() + 1,
      'd+': this.getDate(),
      'h+': this.getHours(),
      'm+': this.getMinutes(),
      's+': this.getSeconds(),
      'q+': Math.floor((this.getMonth() + 3) / 3), //quarter

      S: this.getMilliseconds()
    };
    if (/(y+)/.test(format))
      format = format.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var i in args) {
      var n = args[i];

      if (new RegExp('(' + i + ')').test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1 ? n : ('00' + n).substr(('' + n).length)
        );
    }
    return format;
  };
  var curDate = new Date();
  var CurrentTime = new Date(curDate.getTime()).format('yyyy-MM-dd hh:mm:ss');
  return CurrentTime;
};

// 判断obj是否为空

export const isObjectEmpty = obj => {
  if (obj === null || typeof obj !== 'object' || Array.isArray(obj)) {
    return false; // 或者根据你的实际需求抛出错误
  }
  return Object.keys(obj).length === 0;
};

//删除vue响应式object里面指定为空的字段（不传key，默认删除object里面为空的字段）

export const delProxyObjectEmpty = (proxyObj, keys) => {
  if (isObjectEmpty(proxyObj)) {
    console.log('请传入有效的对象');
    return;
  }

  // 如果keys存在且其长度不为0，则删除指定的keys
  if (Array.isArray(keys) && keys.length) {
    keys.forEach(key => {
      if (Object.prototype.hasOwnProperty.call(proxyObj, key)) {
        Vue.delete(proxyObj, key); // 使用 Vue.delete 保持响应式
      }
    });
  } else {
    // 否则，遍历proxyObj并删除所有为空的字段
    for (let key in proxyObj) {
      if (
        Object.prototype.hasOwnProperty.call(proxyObj, key) &&
        (proxyObj[key] === '' || proxyObj[key] === undefined || proxyObj[key] === null)
      ) {
        Vue.delete(proxyObj, key); // 使用 Vue.delete 保持响应式
      }
    }
  }
};

//删除普通object地址引用的里面指定为空的字段（不删后端枚举报错）
export const delObjectHasEmpty = (keys, originObj) => {
  if (!keys || !keys.length) {
    message.warning('请传入要删除的值');
    return {};
  }

  keys.forEach(key => {
    if (Object.prototype.hasOwnProperty.call(originObj, key)) {
      delete originObj[key];
    }
  });
};

//直接删除object里面为空的字段，创建返回一个新的对象（不删后端枚举报错）
export const delObjectEmpty = originObj => {
  const cleanedObj = {};
  for (const [key, value] of Object.entries(originObj)) {
    if (value !== '' && value !== undefined && value !== null) {
      cleanedObj[key] = value;
    }
  }
  return cleanedObj;
};

// export const isObjectEmpty = obj => {
//   return JSON.stringify(obj) === '{}';
// };

/**
 * 全部订单时间类型
 */
export const timeTypeOptions = [
  { label: '下单时间', value: 'gmtOrderTime', tag: '' },
  { label: '分单时间', value: 'splitTime', tag: '' },
  { label: '领单时间', value: 'handlerTime', tag: '' },
  { label: '派单时间', value: 'dispatchTime', tag: '' },
  { label: '接单时间', value: 'receiveTime', tag: '' },
  { label: '开工时间', value: 'startTime', tag: '' },
  { label: '施工时间', value: 'processTime', tag: '' },
  { label: '审核时间', value: 'auditTime', tag: '' },
  { label: '完成时间', value: 'finishTime', tag: '' }
];

export const timeTypeOrderOptions = [
  { label: '下单时间', value: 'gmtOrderTime', tag: '' },
  { label: '领单时间', value: 'handlerTime', tag: '' },
  { label: '派单时间', value: 'dispatchTime', tag: '' },
  { label: '接单时间', value: 'receiveTime', tag: '' },
  { label: '开工时间', value: 'startTime', tag: '' },
  { label: '施工完成时间', value: 'processTime', tag: '' },
  { label: '质检通过时间', value: 'auditTime', tag: '' },
  { label: '完成时间', value: 'finishTime', tag: '' },
  { label: '移交时间', value: 'transferDate', tag: '' },
  { label: '资料移交时间', value: 'transferTime', tag: '' },
  { label: '归档时间', value: 'archiveTime', tag: '' }
];

// 处理拼接开始结束时间
export const formatTime = ([start, end], defaultTimes = [' 00:00:00', ' 23:59:59']) => {
  return {
    start: start ? start + (defaultTimes?.length > 0 ? defaultTimes[0] : '') : '',
    end: end ? end + (defaultTimes?.length > 0 ? defaultTimes[1] : '') : ''
  };
};

export const orderStatusCountObj = {
  allOrder: { label: '全部', key: 'allOrder', tag: 'allOrderNum', num: 0, tooltip: '' },
  // pendingClaimNum: { label: '待领单', key: 'pendingClaim', tag: 'pendingClaimNum', num: 0, tooltip: '' },
  pendingDispatchNum: {
    label: '待派单',
    key: 'pendingDispatch',
    tag: 'pendingDispatchNum',
    num: 0,
    tooltip: ''
  },
  pendingTransferNum: {
    label: '待调度移交资料',
    key: 'pendingTransfer',
    tag: 'pendingTransferNum',
    num: 0,
    tooltip: ''
  },
  urgeNum: { label: '催单信息', key: 'urge', tag: 'urgeNum', num: 0, tooltip: '' },
  overdueNum: {
    label: '超时订单',
    key: 'overdue',
    tag: 'overdueNum',
    num: 0,
    tooltip:
      '面签下单后2小时未完结，装G (含维修、拆机)待调度移交资料催单信息下单后2小时未完结，抵押 (含解押) 下单后12小时未完结，且只统计当前日期前10天范围内订单。'
  },
  archiveTaskNum: {
    label: '超时归档订单',
    key: 'overArchiveTask',
    tag: 'archiveTaskNum',
    num: 0,
    tooltip: '订单完成后超过7天未归档'
  },
  // 已挂单
  orderPlacementNum: {
    label: '已挂单',
    key: 'orderPlacementNum',
    tag: 'orderPlacementNum',
    num: 0,
    tooltip: ''
  }
};
export const orderStatusCount = Object.values(orderStatusCountObj);

/** 获取城市树形的节点
 * 此处为了 紧密耦合 维护性问题不建议传this进来
 * 而是把获取的实力传进来
 * @param {*} val当前值
 * @param {*} getCheckedNodes ref实例
 * @returns
 */
export const getCascaderRefChange = (val, getCheckedNodes) => {
  if (val?.length > 0) {
    // 获取选中的节点
    const checkedNode = getCheckedNodes();
    // 获取选中的第一个节点的 label
    const selectedLabel = checkedNode[0].pathLabels;
    const [provinceName, cityName, areaName] = selectedLabel;
    const [provinceCode, cityCode, areaCode] = val;
    return {
      provinceName,
      cityName,
      areaName,
      provinceCode,
      cityCode,
      areaCode
    };
  } else {
    return {};
  }
};

//分钟转换为小时制、天
export const minuteToFormatter = (minute, turnType = 'day') => {
  const minuteNum = Number(minute);
  // 如果分钟数为0，则根据转换类型返回对应的严格格式展示 天：01 天 05 时 39 分 ，时：01 时 00分
  if (!minuteNum) return turnType === 'day' ? '00 天 00 时 00 分' : '00 时 00 分';
  // eslint-disable-next-line no-extra-boolean-cast
  // if (!minuteNum) return '-';

  let totalMinutes = parseInt(minuteNum, 10); // 确保minute是整数
  let h = Math.floor(totalMinutes / 60);
  let m = totalMinutes % 60;
  let day = Math.floor(h / 24);
  h %= 24;

  // 使用padStart来确保数字前面有足够的0
  let dayStr = String(day).padStart(2, '0');
  let hourStr = String(h).padStart(2, '0');
  let minuteStr = String(m).padStart(2, '0');

  let result = '';

  if (turnType === 'day') {
    result = `${dayStr} 天 ${hourStr} 时 ${minuteStr} 分`;
  } else {
    result = `${hourStr} 时 ${minuteStr} 分`;
  }

  return result;
};

export const BUSINESS_ZGC = 'ZGC_BANK_MORTGAGE_INFO';

// 文件类型映射,如果业务只允许传某一个类时候,需要争对该类进行映射
export const fileTypeMap = {
  IMAGE: ['.png', '.jpg', '.jpeg', '.gif'],
  VIDEO: ['.mp4', '.avi', '.mov', '.wmv', '.flv'],
  FILE: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.txt'],
  PDF: ['.pdf'],
  DOC: ['.doc', '.docx'],
  MP4: ['.mp4']
  // '.xls', '.xlsx', '.ppt', '.pptx', '.txt'
};

// 通用性检测文件类型
export const getFileType = fileType => {
  const lowerCaseFileType = fileType.toLowerCase();

  // 遍历 fileTypeMap 中的类型，找到匹配的文件类型
  for (const [type, extensions] of Object.entries(fileTypeMap)) {
    if (extensions.includes(lowerCaseFileType)) {
      return type;
    }
  }

  // 如果文件后缀不匹配任何已知类型，返回 UNKNOWN
  return 'UNKNOWN';
};

export const getDateRange = (code, customStartDate, customEndDate) => {
  const now = new Date();
  let startDate, endDate;

  switch (code) {
    case 'day': // 今天
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      break;

    case 'yesterday': // 昨天
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
      endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
      break;

    case 'week': // 近七天
      {
        startDate = new Date();
        startDate.setDate(now.getDate() - 6); // 当前日期减去6天，得到七天前
        startDate.setHours(0, 0, 0, 0); // 设置开始时间为当天的00:00:00
        endDate = new Date(now); // 当前时间为结束时间
        endDate.setHours(23, 59, 59, 999); // 设置结束时间为当天的23:59:59
      }
      break;

    case 'month': // 本月
      startDate = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0); // 本月1号
      endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59); // 本月最后一天
      break;

    case 'year': // 本年
      startDate = new Date(now.getFullYear(), 0, 1, 0, 0, 0); // 本年1月1号
      endDate = new Date(now.getFullYear(), 11, 31, 23, 59, 59); // 本年12月31号
      break;

    case 'custom': // 自定义日期
      if (customStartDate && customEndDate) {
        startDate = new Date(customStartDate);
        endDate = new Date(customEndDate);
      } else {
        throw new Error('请提供自定义开始日期和结束日期');
      }
      break;

    default:
      throw new Error('无效的时间范围选项');
  }

  return {
    startTime: formatDateTime(startDate),
    endTime: formatDateTime(endDate)
  };
};

// 日期格式化函数，支持不同的时间格式
function formatDateTime(date, formatType = 'YYYY-MM-DD HH:mm:ss') {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 补齐两位
  const day = date.getDate().toString().padStart(2, '0'); // 补齐两位
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // 根据传入的格式类型返回对应格式
  switch (formatType) {
    case 'YYYY-MM-DD': // 返回日期部分
      return `${year}-${month}-${day}`;

    case 'YYYY-MM-DD HH:mm:ss': // 返回日期和时间部分
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    case 'MM-DD-YYYY': // 返回月/日/年
      return `${month}-${day}-${year}`;

    case 'DD-MM-YYYY': // 返回日/月/年
      return `${day}-${month}-${year}`;

    default:
      throw new Error('Unsupported format type');
  }
}

/**
 *  生成日期数组
 * @param {*} type
 * @param {*} startTime
 * @param {*} endTime
 */

export const generateDateArray = (type = 'DAY', startTime, endTime, len = 1) => {
  console.log(type, startTime, endTime);

  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  const result = [];

  if (type === 'DAY') {
    // 按天生成日期数组
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      if (len === 1) {
        result.push(day);
      } else {
        result.push(`${month}-${day}`);
      }
      currentDate.setDate(currentDate.getDate() + 1); // 日期加1
    }
  } else if (type === 'MONTH') {
    // 按月生成日期数组
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      if (len === 1) {
        result.push(month);
      } else {
        result.push(`${year}-${month}`);
      }
      currentDate.setMonth(currentDate.getMonth() + 1); // 月份加1
    }
  } else {
    throw new Error('Invalid type. Type must be DAY or MONTH.');
  }

  return result;
};

// console.log(getDateRange(option4, '2023-09-01 00:00:00', '2023-09-09 23:59:59')); // 自定义日期范围

export const TimeFormatEnum = {
  BASETIME: [
    {
      name: '今天',
      code: 'day',
      timeFormat: 'DAY'
    },
    {
      name: '昨天',
      code: 'yesterday',
      timeFormat: 'DAY'
    },
    {
      name: '本月',
      code: 'month',
      timeFormat: 'DAY'
    }
    // {
    //   name: '自定义',
    //   code: 'custom',
    //   timeFormat: 'DAY'
    // }
  ],
  UINTIME: [
    {
      name: '近七天',
      code: 'week',
      timeFormat: 'DAY'
    },
    {
      name: '本月',
      code: 'month',
      timeFormat: 'DAY'
    },
    {
      name: '本年',
      code: 'year',
      timeFormat: 'MONTH'
    }
  ]
};

export const statisticalTypeEnum = [
  {
    key: 'ORDER_COUNT',
    label: '进件数'
  },
  {
    key: 'ORDER_AMOUNT',
    label: '进件金额'
  },
  {
    key: 'DEAL_COUNT',
    label: '成交数'
  },
  {
    key: 'DEAL_AMOUNT',
    label: '成交金额'
  }
];


export function cleanObject(obj) {
  Object.entries(obj).forEach(([key, value]) => {
    // 递归处理嵌套的对象
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      cleanObject(value);
      // 删除空对象
      if (Object.keys(value).length === 0) {
        delete obj[key];
      }
    }
    // 删除空数组
    else if (Array.isArray(value) && value.length === 0) {
      delete obj[key];
    }
    // 删除空字符串、undefined、null
    else if (value === '' || value === undefined || value === null) {
      delete obj[key];
    }
  });

  return obj;
}