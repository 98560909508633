export default {
  path: '/afterFusion',
  name: 'AfterFusion',
  meta: {
    role: '1f3f8299-74a3-4032-8357-31b3c9d0497c',
    title: '融后管理',
    icon: 'icon el-icon-collection',
    hidden: false,
    tag: true
  },
  component: () => import(/* webpackChunkName:"Customer" */ '@/layout/Main'),
  children: [
    {
      name: 'AfterFinancingOrders',
      path: 'afterFinancingOrders',
      meta: {
        role: '5a5a41a4-d0d6-493f-9591-16dad73f03af',
        icon: 'icon',
        title: '融后订单',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/afterFinancingOrders')
    },
    {
      name: 'AfterFinancingOrdersDetail',
      path: 'afterFinancingOrdersDetail',
      meta: {
        role: 'f7663731-5541-4516-9d53-d492a46afa85',
        icon: 'icon',
        title: '融后订单详情',
        hidden: true,
        tag: true
      },
      component: () => import('@/views/afterFusion/afterFinancingOrdersDetail')
    },
    {
      name: 'OverdueOrder',
      path: 'overdueOrder',
      meta: {
        role: '5719c999-a624-42f5-9306-0295c4164f69',
        icon: 'icon',
        title: '逾期订单',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/overdueOrder')
    },
    {
      name: 'BalanceManagement',
      path: 'balanceManagement',
      meta: {
        role: '8bc29443-f271-429f-a660-55c80d95d2b7',
        icon: 'icon',
        title: '余额管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/balanceManagement')
    },
    {
      name: 'DerateManage',
      path: 'derateManage',
      meta: {
        role: '8bc29443-f271-429f-a660-55c80d95d2b1',
        icon: 'icon',
        title: '减免管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/derateManage')
    },
    {
      name: 'RepaymentManage',
      path: 'repaymentManage',
      meta: {
        role: '8bc29443-f271-429f-a660-55c80d95d2b2',
        icon: 'icon',
        title: '还款管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/repaymentManage')
    },
    {
      name: 'SettleManage',
      path: 'settleManage',
      meta: {
        role: '8bc29443-f271-429f-a660-55c80d95d2b3',
        icon: 'icon',
        title: '结清管理',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/settleManage')
    },
    {
      name: 'DeductSearch',
      path: 'deductSearch',
      meta: {
        role: 'c04acf75-b2d6-4adf-8cdd-b878e1a82f80',
        icon: 'icon',
        title: '划扣查询',
        hidden: false,
        tag: true
      },
      component: () => import('@/views/afterFusion/deductSearch/index')
    }
  ]
};
