import Vue from 'vue';

const comObj = {};
const files = require.context('./', true, /\.vue$/);
const componentsList = files.keys();
componentsList.forEach(res => {
  const getDefault = files(res)?.default;
  if (getDefault) {
    const { name } = getDefault;
    if (name) {
      comObj[name] = getDefault;
    }
  }
});
const install = () => {
  // 判断是否安装
  if (install.installed) return;
  // 遍历注册全局组件
  Object.keys(comObj).forEach(res => {
    Vue.component(res, comObj[res]);
  });
};
// 判断是否是直接引入文件
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
export default {
  install
};
