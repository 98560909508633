<template>
  <Dialog
    :visible="visible"
    :titleName="titleName"
    width="880px"
    append-to-body
    modal-append-to-body
    @handlerClose="handlerClose"
    @handlerSubmit="handlerSubmit"
  >
    <CustomForm ref="customFormRef" v-model="formData" :rules.sync="rules" :form-column="formColumn">
      <template #descSlot>
        <CustomUeditorWrap v-model="formData.description"></CustomUeditorWrap>
      </template>
    </CustomForm>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog';
import CustomUeditorWrap from '@/components/CustomUeditorWrap';
export default {
  name: 'AddDescDialog',
  model: {
    prop: 'modelValue',
    event: 'handlerChange',
  },
  components: {
    Dialog,
    CustomUeditorWrap,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          desc: '',
        };
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: '新增描述',
    },
  },
  computed: {
    formData: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      },
    },
  },
  data() {
    return {
      rules: {},
      formColumn: [
        {
          componentsProps: {
            placeholder: '请输入描述项',
            size: 'small',
          },
          col: {
            span: 24,
          },
          required: true, // 是否必填
          label: '描述项', // form label
          type: 'el-input',
          prop: 'requireName', // form prop
        },
        {
          componentsProps: {
            placeholder: '请输入描述项',
            size: 'small',
          },
          col: {
            span: 24,
          },
          required: true, // 是否必填
          slotName: 'descSlot',
          label: '描述内容', // form label
          type: 'bb',
          prop: 'description', // form prop
        },
      ],
    };
  },
  methods: {
    handlerClose() {
      this.$emit('update:visible', false);
    },
    handlerSubmit() {
      this.$refs.customFormRef.getFormRef().validate((valid) => {
        if (valid) {
          if (this.titleName == '编辑描述') {
            this.$emit('handlerUpdate', this.formData);
          } else {
            this.$emit('handlerSubmit', this.formData);
          }
          this.handlerClose();
          this.msg({
            type: 'success',
            content: '操作成功',
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
