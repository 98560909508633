<template>
  <div class="flex flex-col relative h_304 m_b_12">
    <div class="absolute" style="top: -40px; right: 5px">
      <el-button class="custom-button" type="primary" size="small" @click="addProductionDesc">+添加产品描述</el-button>
    </div>
    <!--    <RTable :column="tableColumn" :tableData="tableData"></RTable>-->
    <el-table :data="tableData" style="width: 100%" height="280px">
      <el-table-column prop="requireName" show-overflow-tooltip label="描述项" width="140"> </el-table-column>
      <el-table-column prop="description" show-overflow-tooltip label="描述内容">
        <template #default="{ row }">
          <span v-html="row.description ? row.description : '-'"></span>
        </template>
      </el-table-column>
      <el-table-column width="130" prop="" label="操作" fixed="right">
        <template #default="{ row, $index }">
          <el-link type="primary" class="m_r_4" @click="onOperate(row, 'edit', $index)">编辑</el-link>
          <el-link @click="onOperate(row, 'del', $index)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <AddDescDialog
      v-if="visible"
      :visible.sync="visible"
      :title-name="titleName"
      v-model="rowData"
      @handlerSubmit="handlerSubmit"
      @handlerUpdate="handlerUpdate"
    ></AddDescDialog>
  </div>
</template>

<script>
import AddDescDialog from './AddDescDialog';
import { isObjectEmpty } from '@/utils/variable';
export default {
  name: 'ProductionDescEdit',
  components: {
    TableActions: () => import('@/components/TableActions.vue'),
    AddDescDialog
  },
  model: {
    prop: 'modelValue',
    event: 'handlerChange'
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [
        // {
        //   name: '描述项',
        //   desc: '描述类容'
        // }
      ]
    }
  },
  computed: {
    tableData: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('handlerChange', v);
      }
    }
  },
  data() {
    return {
      visible: false,
      titleName: '新增描述',
      rowData: {},
      rowIndex: -1
    };
  },
  methods: {
    onOperate(item, type, index) {
      if (type == 'edit') {
        this.titleName = '编辑描述';
        this.visible = true;
        this.rowData = { ...item };
        this.rowIndex = index;
      } else if (type == 'del') {
        this.$confirm('你确定要删除该数据吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.tableData.splice(index, 1);
          })
          .catch(() => {});
      }
    },
    addProductionDesc() {
      this.rowData = {};
      this.titleName = '新增描述';
      this.visible = true;
    },
    handlerSubmit(data) {
      if (data && !isObjectEmpty(data)) {
        if (this.tableData && this.tableData.length) {
          this.tableData.push(data);
        } else {
          this.tableData = [data];
        }
      }
    },
    handlerUpdate(row) {
      this.$nextTick(() => {
        if (this.rowIndex != -1) {
          this.$set(this.tableData, this.rowIndex, row);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-button {
  /deep/ .el-button {
    height: 32px;
  }
}
</style>
